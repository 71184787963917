import { Cell, Model, Range, Reference, Sheet, Workbook } from '@grid-is/apiary';
import { chain, sum } from '@grid-is/iterators';
import { assert } from '@grid-is/validation';

import { gridElements } from '@/grid/elements';

import { cellsAreComparable, containsDate, containsNumber, getCellsInRange, numberInRange, trimBlanksFromRange } from './smartSelection/cells';
import { inferStructure, List, SingleValue, Structure, Table } from './smartSelection/structure';
import { EditableElementTypes } from './types/elements';
import { ElementData } from './types/elements/shared';

// XXX: Attempt to use Range instead
type Selection = [[number, number], [number, number]];

interface ForSelectionArgs {
  // when type is chart the function will suggest the chart that fits the data the best
  type?: EditableElementTypes,
  model: Model,
  workbookName?: string | null,
  sheetName?: string | null,
  selection?: Selection | null,
  range?: Range | null,
}

export function getElementOptionsForSelection (
  { type, model, workbookName = null, sheetName = null, selection = null, range = null }: ForSelectionArgs,
): ElementData {
  const sheet = model.resolveSheet(sheetName, workbookName);
  if (!sheet) {
    return {};
  }
  const workbook = model.getWorkbookByKey(sheet.workbookKey);

  const hasMultipleWorkbooks = model.getWorkbooks().length > 1;
  assert(workbook);
  const maybeAddWorkbookName = getWorkbookNameAddingFunction(hasMultipleWorkbooks, workbookName || workbook.name);
  const rangeToRef = (range: Range): string => {
    const ref = new Reference(range, { sheetName: sheet.name });
    return `=${maybeAddWorkbookName(ref)}`;
  };

  let selectedRange: Range;
  if (selection != null) {
    selectedRange = selectionToRange(selection);
  }
  else {
    assert(range != null, 'Either selection or range must be provided');
    selectedRange = range;
  }
  assert(selectedRange != null, 'Either selection or range must be provided');
  selectedRange = trimBlanksFromRange(selectedRange, sheet);
  const structure = inferStructure(sheet, selectedRange);

  if (!type) {
    type = getSuggestedType(workbook, sheet, structure);
  }

  let elementOptions: ElementData = { type };

  const element = gridElements.find(e => e.value === type);
  if (element && element.defaultProps) {
    elementOptions = {
      ...element.defaultProps,
      ...elementOptions,
    };
  }
  if (isChart(type)) {
    elementOptions = setChartOptions(elementOptions, undefined, workbook, sheet, hasMultipleWorkbooks, selectedRange, maybeAddWorkbookName, structure);
  }
  else if (type === 'table') {
    elementOptions = setTableOptions(elementOptions, structure, rangeToRef);
  }
  else if (type === 'radio' || type === 'dropdown') {
    elementOptions = setSelectionElementOptions(elementOptions, sheet, structure, rangeToRef);
  }
  else {
    const qualifiedRef = rangeToRef(structure.data.collapseToTopLeft());
    if (type === 'image') {
      elementOptions.url = qualifiedRef;
    }
    else {
      elementOptions.expr = qualifiedRef;
    }
    if (structure.label) {
      elementOptions.title = rangeToRef(structure.label);
    }
  }

  return elementOptions;
}

interface PopulateExistingElementArgs {
  data: ElementData,
  model: Model,
  workbookName?: string | null,
  sheetName?: string | null,
}

export function prePopulateElementOptions (
  { data, model, workbookName = null, sheetName = null }: PopulateExistingElementArgs,
): ElementData {
  const workbook = model.getWorkbook(workbookName);
  const sheet = workbook?.getSheet(sheetName);
  if (!sheet) {
    return data;
  }

  const hasMultipleWorkbooks = model.getWorkbooks().length > 1;
  assert(workbook);
  const maybeAddWorkbookName = getWorkbookNameAddingFunction(hasMultipleWorkbooks, workbookName || workbook.name);

  if (data.type && isChart(data.type)) {
    const modifiedOptions = setChartOptions({}, data, workbook, sheet, hasMultipleWorkbooks, null, maybeAddWorkbookName);
    return Object.assign({}, data, modifiedOptions);
  }
  return Object.assign({}, data);
}

function getWorkbookNameAddingFunction (hasMultipleWorkbooks: boolean, workbookName: string | null): (ref: Reference) => Reference {
  return (ref: Reference) => {
    // The purpose of this function is to only add workbook names when we need them for disambiguation purposes.
    // Omitting them when there's only one workbook results in shorter and more readable references
    // (e.g. Sheet1!A1 instead of [Sales report.xlsx]Sheet1!A1).
    if (hasMultipleWorkbooks && workbookName) {
      return ref.withPrefix({ workbookName });
    }
    return ref;
  };
}

function selectionToRange (selection: Selection): Range {
  const [ [ left, top ], [ right, bottom ] ] = selection;
  return new Range({ top, left, bottom, right });
}

function getSuggestedType (workbook: Workbook, sheet: Sheet, structure: Structure): Extract<EditableElementTypes, 'slider' | 'input' | 'text' | 'table' | 'line' | 'column'> {
  if (structure.data.size <= 1) {
    if (workbook.isDependencyRoot(sheet.index, structure.data.top, structure.data.left)) {
      const cell = sheet.getCellByCoords(structure.data.top, structure.data.left);
      if (cell && typeof cell.v === 'number') {
        return 'slider';
      }
      return 'input';
    }
    return 'text';
  }
  if (!numberInRange(sheet, structure.data)) {
    return 'table';
  }
  return getSuggestedChartType(structure);
}

function getSuggestedChartType (structure: Structure): Extract<EditableElementTypes, 'line' | 'column'> {
  if (structure.data.width > 12 || structure.data.height > 12) {
    return 'line';
  }
  return 'column';
}

function isChart (type: string): boolean {
  const chartTypes = [ 'area', 'bar', 'column', 'line', 'pie', 'scatter' ];
  return chartTypes.includes(type);
}

function setChartOptions (
  options: ElementData,
  originalOptions: ElementData | undefined,
  workbook: Workbook,
  sheet: Sheet,
  hasMultipleWorkbooks: boolean,
  selectedRange: Range | null,
  maybeAddWorkbookName: (r: Reference) => Reference,
  structure: Structure | null = null,
): ElementData {
  options.expr = '=';
  const type = originalOptions?.type || options.type || '';
  const previousDataRef = getPreviousDataRef(originalOptions, workbook, sheet, hasMultipleWorkbooks);
  // XXX: rely more on inferred structure, rather than selected range
  let avoidLinearSequences = true;
  let dataRef = findNextRangeOfNumbers(workbook, sheet, selectedRange, previousDataRef, avoidLinearSequences);
  if (selectedRange && dataRef == null) {
    // We didn't find anything. Try again with relaxed constraints.
    avoidLinearSequences = false;
    dataRef = findNextRangeOfNumbers(workbook, sheet, selectedRange, previousDataRef, avoidLinearSequences);
  }
  if (dataRef) {
    options.expr += maybeAddWorkbookName(dataRef).toString();
    const legendRange = findLegend(dataRef.range, sheet, selectedRange);
    if (legendRange) {
      options.legend = `=${maybeAddWorkbookName(legendRange)}`;
    }
    const labelRange = findLabels(dataRef.range, sheet, selectedRange);
    if (labelRange && type !== 'pie') {
      options.labels = `=${maybeAddWorkbookName(labelRange)}`;
    }
  }
  else if (selectedRange) {
    options.expr = `=${maybeAddWorkbookName(new Reference(selectedRange, { sheetName: sheet.name }))}`;
  }
  else if (previousDataRef) {
    return originalOptions || {}; // Just use the same as before
  }
  if (supportsStacking(type)) {
    options.stacked = shouldStack(dataRef, sheet) ? 'true' : 'false';
  }
  if (structure && structure.label) {
    options.title = `=${maybeAddWorkbookName(new Reference(structure.label, { sheetName: sheet.name }))}`;
  }
  return options;
}

function getPreviousDataRef (data: ElementData | undefined, workbook: Workbook, sheet: Sheet, hasMultipleWorkbooks: boolean): Reference | null {
  if (data?.expr && data?.expr.length > 0 && data?.expr.charAt(0) === '=') {
    const ref = Reference.from(data.expr.slice(1));
    if (ref &&
        ref.sheetName === sheet.name &&
        (ref.workbookName === workbook.name || (!hasMultipleWorkbooks && ref.workbookName === ''))) {
      return ref;
    }
  }
  return null;
}

function findNextRangeOfNumbers (workbook: Workbook, sheet: Sheet, selectedRange: Range | null, previousDataRef: Reference | null, avoidLinearSequences: boolean): Reference | null {
  const [ sheetWidth, sheetHeight ] = sheet.getSize();
  if (sheetWidth > 0 && sheetHeight > 0) {
    const rangesToSkip: Range[] = [];
    if (previousDataRef) {
      rangesToSkip.push(previousDataRef.range);
    }
    const searchRange = selectedRange ?? new Range({ top: 0, left: 0, bottom: sheetHeight - 1, right: sheetWidth - 1 });
    const ignoreNumberFormatting = selectedRange != null;
    const previousDataRefInSearchRange = previousDataRef && coordinatesInRange(previousDataRef.range.top, previousDataRef.range.left, searchRange);
    let startingDiagonal = 0;
    if (previousDataRefInSearchRange) {
      // Start the search from the next diagonal, after the one which intersects the top-left corner of the previous data
      // reference.
      startingDiagonal = previousDataRef.range.left + previousDataRef.range.top + 1;
    }
    for (const [ row, column ] of scanDiagonally(searchRange, startingDiagonal)) {
      if (!shouldSkip(row, column, rangesToSkip)) {
        const cell = sheet.getCellByCoords(row, column);
        if (cell && containsNumber(cell as Cell) && cell.s?.bold !== true &&
            (!previousDataRefInSearchRange || isTopLeftCornerOfNumberRange(sheet, row, column, ignoreNumberFormatting))) {
          // We found a number that's not bold.
          // Attempt to grow a range from its location.
          const wholeRange = growRange(sheet, cell, row, column, searchRange, ignoreNumberFormatting);
          let croppedRange: Range | null = wholeRange;
          if (avoidLinearSequences) {
            // Remove linear sequences
            croppedRange = cropLinearSequencesFromFirstRowAndColumn(wholeRange, sheet);

            // Ensure we don't scan within linear sequences in future iterations
            const linearRanges = rangeDiff(croppedRange, wholeRange);
            linearRanges.forEach(r => rangesToSkip.push(r));
          }

          // OK, can we use this?
          if (croppedRange != null && croppedRange.size > 1 &&
              (selectedRange != null || !rangeOnlyContainsRoots(workbook, sheet, croppedRange))) {
            // All conditions satisfied
            return new Reference(croppedRange, { sheetName: sheet.name });
          }
        }
      }
    }
  }
  return null;
}

function* scanDiagonally (searchRange: Range, startingDiagonal: number = 0): Generator<[number, number]> {
  /*
   Diagonally scan within the search range.
   A 3x3 grid would be scanned in the following order.
   1 2 4
   3 5 7
   6 8 9
   */
  let diagonal = searchRange.left;
  const diagonalLimit = searchRange.bottom + searchRange.right + 1;
  while (diagonal < diagonalLimit) {
    let row = searchRange.top;
    let column = (diagonal + startingDiagonal) % diagonalLimit;
    while (row <= searchRange.bottom && column >= searchRange.left) {
      if (coordinatesInRange(row, column, searchRange)) {
        yield [ row, column ];
      }
      row += 1;
      column -= 1;
    }
    diagonal += 1;
  }
}

function shouldSkip (row: number, column: number, rangesToSkip: Range[]): boolean {
  return rangesToSkip.some(r => coordinatesInRange(row, column, r));
}

function coordinatesInRange (row: number, column: number, range: Range): boolean {
  return range.contains({ top: row, left: column });
}

function growRange (sheet, cell, startRow: number, startCol: number, searchRange: Range, ignoreNumberFormatting: boolean): Range {
  let endRow = startRow;
  let endCol = startCol;

  // grow right
  let nextCell = null;
  let stop = false;
  while (!stop && endCol < searchRange.right) {
    nextCell = sheet.getCellByCoords(startRow, endCol + 1);
    if (cellsAreComparable(cell, nextCell, ignoreNumberFormatting)) {
      endCol += 1;
    }
    else {
      stop = true;
    }
  }

  // grow down
  stop = false;
  while (!stop && endRow < searchRange.bottom) {
    let rowIsComparable = true;
    for (let column = startCol; column <= endCol; column += 1) {
      nextCell = sheet.getCellByCoords(endRow + 1, column);
      if (!cellsAreComparable(cell, nextCell, ignoreNumberFormatting)) {
        rowIsComparable = false;
        break;
      }
    }
    if (rowIsComparable) {
      endRow += 1;
    }
    else {
      stop = true;
    }
  }

  return new Range({ top: startRow, left: startCol, bottom: endRow, right: endCol });
}

function isTopLeftCornerOfNumberRange (sheet, row: number, column: number, ignoreNumberFormatting: boolean): boolean {
  const cellOfInterest = sheet.getCellByCoords(row, column);
  if (row > 0) {
    const cellAbove = sheet.getCellByCoords(row - 1, column);
    if (cellsAreComparable(cellOfInterest, cellAbove, ignoreNumberFormatting)) {
      return false;
    }
  }
  if (column > 0) {
    const cellToTheLeft = sheet.getCellByCoords(row, column - 1);
    if (cellsAreComparable(cellOfInterest, cellToTheLeft, ignoreNumberFormatting)) {
      return false;
    }
  }
  return true;
}

function cropLinearSequencesFromFirstRowAndColumn (range: Range, sheet: Sheet): Range | null {
  let result: Range | null = range;
  // Check the first column
  if (isLinearSequence(result.collapseToColumn(), sheet)) {
    if (range.width > 1) {
      result = new Range({ top: result.top, left: result.left + 1, bottom: result.bottom, right: result.right });
    }
    else {
      result = null;
    }
  }
  // Check the first row
  if (result != null && isLinearSequence(result.collapseToRow(), sheet)) {
    if (range.height > 1) {
      result = new Range({ top: result.top + 1, left: result.left, bottom: result.bottom, right: result.right });
    }
    else {
      result = null;
    }
  }
  return result;
}

function isLinearSequence (rangeOrCells: Range | Cell[], sheet: Sheet): boolean {
  let result = false;
  let cells: Cell[];
  if (rangeOrCells instanceof Range) {
    cells = [ ...getCellsInRange(rangeOrCells, sheet) ];
  }
  else {
    cells = rangeOrCells;
  }
  if (cells.length >= 3) {
    const numbers = gatherNumbersFromCells(cells);
    if (numbers.length === cells.length) {
      const firstPairDelta = numbers[0] - numbers[1];
      const sameDeltaPredicate = ([ a, b ]: [number, number]) => a - b === firstPairDelta;
      result = chain(numbers)
        .skip(1)
        .adjacentPairs()
        .every(sameDeltaPredicate);
    }
  }
  return result;
}

function gatherNumbersFromCells (cells: Cell[]): number[] {
  const numbers: number[] = [];
  for (const cell of cells) {
    if (typeof cell.v === 'number') {
      numbers.push(cell.v);
    }
  }
  return numbers;
}

function rangeDiff (minuend: Range | null, subtrahend: Range): Range[] {
  if (minuend === null) {
    return [ subtrahend ];
  }
  return Array.from(minuend.except(subtrahend));
}

function rangeOnlyContainsRoots (workbook, sheet, range: Range): boolean {
  const ignoreRangeDependencies = true;
  for (const { row, column } of range.iterCoordinates()) {
    if (!workbook.isDependencyRoot(sheet.index, row, column, ignoreRangeDependencies)) {
      return false;
    }
  }
  return true;
}

function findLegend (numberRange: Range, sheet, selectedRange: Range | null): Reference | null {
  // look at the dimensions of the number range to figure out the scan direction
  const scanDirection = numberRange.width > numberRange.height ? 'left' : 'up';
  return scanForLabels(scanDirection, numberRange, sheet, selectedRange);
}

function findLabels (numberRange: Range, sheet, selectedRange: Range | null): Reference | null {
  // look at the dimensions of the number range to figure out the scan direction
  const scanDirection = numberRange.width <= numberRange.height ? 'left' : 'up';
  return scanForLabels(scanDirection, numberRange, sheet, selectedRange);
}

function scanForLabels (scanDirection: 'left' | 'up', numberRange: Range, sheet, selectedRange: Range | null): Reference | null {
  // Scan in the chosen direction, but no further than 3 rows/cols away from the number range
  let offset = -1;
  const origin = scanDirection === 'left' ? numberRange.left : numberRange.top;
  let limit = 0;
  if (selectedRange) {
    limit = scanDirection === 'left' ? selectedRange.left : selectedRange.top;
  }
  while (offset >= -3 && origin + offset >= limit) {
    const candidateRange = scanDirection === 'left'
      ? new Range({ left: origin + offset, top: numberRange.top, right: origin + offset, bottom: numberRange.bottom })
      : new Range({ left: numberRange.left, top: origin + offset, right: numberRange.right, bottom: origin + offset });

    // If an eligable range is found, return it
    // An eligable range is one that is fully populated and has one of the following properties
    //  - all values are strings OR
    //  - all values are dates OR
    //  - all cells are bold-faced OR
    //  - the cells form a liner sequence (e.g. week numbers)
    if (isEligibleLabelRange(candidateRange, sheet)) {
      return new Reference(candidateRange, { sheetName: sheet.name });
    }
    offset -= 1;
  }

  return null;
}

function isEligibleLabelRange (candidateRange: Range, sheet): boolean {
  const cells = [ ...getCellsInRange(candidateRange, sheet) ];
  return cells.every(c => c != null) && (
    cells.every(c => c.v && typeof c.v === 'string') ||
    cells.every(c => c.s?.bold) ||
    cells.every(containsDate) ||
    isLinearSequence(cells, sheet)
  );
}

// XXX: this function should query elements for option support instead of having own source of truth
export function supportsStacking (type: string): boolean {
  const stackableCharts = [ 'area', 'bar', 'column' ];
  return stackableCharts.includes(type);
}

function shouldStack (dataRef: Reference | null, sheet): boolean {
  if (dataRef == null || dataRef.is1D()) {
    return false;
  }
  let seenPositive = false;
  let seenNegative = false;
  for (const cell of getCellsInRange(dataRef.range, sheet)) {
    if (!seenPositive && typeof cell.v === 'number' && cell.v > 0) {
      seenPositive = true;
    }
    if (!seenNegative && typeof cell.v === 'number' && cell.v < 0) {
      seenNegative = true;
    }
    if (seenPositive && seenNegative) {
      return false;
    }
  }
  return true;
}

function setTableOptions (
  options: ElementData,
  structure: Structure,
  rangeToRef: (r: Range) => string,
): ElementData {
  options.expr = rangeToRef(structure.data);
  if (structure.label) {
    options.title = rangeToRef(structure.label);
  }
  if (structure instanceof Table) {
    if (structure.columnHeaders) {
      options.legend = rangeToRef(structure.columnHeaders);
    }
    if (structure.rowHeaders) {
      options.labels = rangeToRef(structure.rowHeaders);
    }
  }

  return options;
}

function setSelectionElementOptions (
  options: ElementData,
  sheet,
  structure: Structure,
  rangeToRef: (r: Range) => string): ElementData {
  if (structure.label) {
    options.title = rangeToRef(structure.label);
  }
  if (structure instanceof List || structure instanceof SingleValue) {
    options.options = rangeToRef(structure.data);
  }
  else if (structure instanceof Table && structure.data.width >= 2 && structure.data.height >= 2) {
    const getSeries = (i: number): {range: Range, cells: Cell[] } => {
      const r = structure.orientation === 'vertical' ? structure.data.collapseToColumn(i) : structure.data.collapseToRow(i);
      return { range: r, cells: [ ...getCellsInRange(r, sheet) ] };
    };
    const firstSeries = getSeries(0);
    const secondSeries = getSeries(1);
    let labelsAreFirst = true;
    const firstAllStrings = firstSeries.cells.every(c => typeof c.v === 'string');
    const secondAllStrings = secondSeries.cells.every(c => typeof c.v === 'string');
    if (firstAllStrings && !secondAllStrings) {
      labelsAreFirst = true;
    }
    else if (secondAllStrings && !firstAllStrings) {
      labelsAreFirst = false;
    }
    else {
      labelsAreFirst = getAverageStringLength(firstSeries.cells) >= getAverageStringLength(secondSeries.cells);
    }
    if (labelsAreFirst) {
      options.labels = rangeToRef(firstSeries.range);
      options.options = rangeToRef(secondSeries.range);
    }
    else {
      options.labels = rangeToRef(secondSeries.range);
      options.options = rangeToRef(firstSeries.range);
    }
  }
  return options;
}

function getAverageStringLength (cells: Cell[]): number {
  let averageLength = 0;
  if (cells.length > 0) {
    const totalLength = sum(cells.map(c => (c.v || '').toString().length));
    averageLength = totalLength / cells.length;
  }
  return averageLength;
}
