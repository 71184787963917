import { query, sessionStorage } from '@grid-is/browser-utils';

import { integrations } from '@/api/integrations';
import { getExtraOauthParameters } from '@/api/oauth';

const { serialize } = query;

export const SESSION_STORAGE_KEY_SOURCE_ID_BEING_REPLACED = 'sourceIdBeingReplaced';
export const SESSION_STORAGE_KEY_ADD_DRIVE_LOCATION = 'AddDriveLocation';
export const SESSION_STORAGE_KEY_ADD_DRIVE_DATA_OPTION_SELECTED = 'AddDriveDataOptionSelected';

const providerUrls = {
  google: ({ redirectUri, clientId }) => {
    return 'https://accounts.google.com/o/oauth2/v2/auth?' + serialize({
      client_id: clientId,
      redirect_uri: redirectUri + '/google',
      response_type: 'code',
      scope: 'openid email https://www.googleapis.com/auth/drive.file',
      include_granted_scopes: 'false',
      prompt: 'consent select_account',
      access_type: 'offline',
    });
  },
  dropbox: ({ redirectUri, clientId }) => {
    return 'https://www.dropbox.com/oauth2/authorize?' + serialize({
      client_id: clientId,
      redirect_uri: redirectUri + '/dropbox',
      response_type: 'code',
      token_access_type: 'offline',
    });
  },
  onedrive: ({ redirectUri, clientId }) => {
    return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${serialize({
      client_id: clientId,
      redirect_uri: redirectUri + '/onedrive',
      response_type: 'code',
      scope: 'openid profile offline_access Files.Read.All',
      prompt: 'select_account',
    })}`;
  },
  notion: ({ redirectUri, clientId }) => {
    return `https://api.notion.com/v1/oauth/authorize?${serialize({
      client_id: clientId,
      redirect_uri: redirectUri + '/notion',
      response_type: 'code',
      owner: 'user',
    })}`;
  },
  airtable: ({ redirectUri, clientId, state, code_challenge }) => {
    return `https://airtable.com/oauth2/v1/authorize?${serialize({
      client_id: clientId,
      redirect_uri: redirectUri + '/airtable',
      response_type: 'code',
      scope: 'data.records:read schema.bases:read webhook:manage',
      state: state,
      code_challenge: code_challenge,
      code_challenge_method: 'S256',
    })}`;
  },
  smartsheet: ({ redirectUri, clientId, state }) => {
    return `https://app.smartsheet.com/b/authorize?${serialize({
      client_id: clientId,
      redirect_uri: redirectUri + '/smartsheet',
      response_type: 'code',
      scope: 'READ_SHEETS ADMIN_WEBHOOKS', // http://smartsheet-platform.github.io/smartsheet-java-sdk/com/smartsheet/api/oauth/AccessScope.html
      state: state,
    })}`;
  },
};

export const connect = (serviceName, destination, workbookIdBeingReplaced, dataOptionSelected) => {
  if (typeof location !== 'undefined') {
    sessionStorage.setItem(SESSION_STORAGE_KEY_ADD_DRIVE_LOCATION, destination || 'settings');
    if (workbookIdBeingReplaced) {
      sessionStorage.setItem(SESSION_STORAGE_KEY_SOURCE_ID_BEING_REPLACED, workbookIdBeingReplaced);
    }
    if (dataOptionSelected) {
      sessionStorage.setItem(SESSION_STORAGE_KEY_ADD_DRIVE_DATA_OPTION_SELECTED, dataOptionSelected);
    }

    return Promise.all([ integrations(), getExtraOauthParameters(serviceName) ]).then(([ oauthIntegrations, extraParams ]) => {
      const redirectUri = `${location.protocol}//${location.host}/remote/authorized`;
      const clientId = oauthIntegrations[serviceName] && oauthIntegrations[serviceName].client_id;
      location.href = providerUrls[serviceName]({ redirectUri, clientId, ...extraParams });
    });
  }
  return Promise.resolve();
};
