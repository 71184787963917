import getID from '@/grid/utils/uid';

const COMPOSITE_ANCHOR_ID_SEPARATOR = '+';

// anchorId can be composite ids (elementId/commentId)
// and later monolithic (when comments will be based on selections)
// matchesAnchor checks if a given elementId is associated with the given anchorId
// we'll use prefix match an the composite ids will start with the elementId
// and "s".startsWith("s") will be true when we move to selection based comments
export function matchesAnchor (anchorId, elementId) {
  return elementId != null && anchorId != null && anchorId.startsWith(elementId);
}

export function splitAnchor (anchorId) {
  if (anchorId == null) {
    return {
      elementId: null,
      commentId: null,
    };
  }
  const [ elementId, commentId ] = anchorId.split(COMPOSITE_ANCHOR_ID_SEPARATOR);
  return {
    elementId,
    commentId,
  };
}

export function makeAnchor (elementId) {
  return `${elementId}${COMPOSITE_ANCHOR_ID_SEPARATOR}${getID()}`;
}
