import { useRef, useState } from 'react';
import Link from 'next/link';

import { tracking } from '@grid-is/tracking';

import { Button } from '@/grid-ui/Button';
import { IconButton } from '@/grid-ui/IconButton';
import { Modal } from '@/grid-ui/Modal';
import { useDeps } from '@/bootstrapping/dependencies';
import { ButtonMenu } from '@/components/Menu';
import { isMobile } from '@/utils';

import styles from './documentMenu.module.scss';

const exportToPDFTooltip = isDraft => {
  if (isDraft) {
    return 'Publish or discard changes to export to PDF';
  }
  return null;
};

type Props = {
  docId?: string,
  canExportToPDF?: boolean,
  canEdit?: boolean,
  duplicateDocument?: () => void,
  onExportToPDFClick?: () => void,
  documentIsDuplicatable?: boolean,
  onDocumentSettingsClick?: () => void,
  onDocumentStatisticsClick?: () => void,
  onFullscreenButtonClick?: () => void,
  onRestoreDefaults?: () => void,
  onDiscardDraft?: () => void,
  onDeleteDocButtonClick?: () => void,
  isViewMode?: boolean,
  isDraft?: boolean,
}

export function DocumentMenu ({
  docId,
  canExportToPDF,
  canEdit,
  documentIsDuplicatable = false,
  duplicateDocument = () => {},
  onDocumentSettingsClick = () => {},
  onFullscreenButtonClick = () => {},
  onExportToPDFClick = () => {},
  onRestoreDefaults = () => {},
  onDiscardDraft = () => {},
  onDeleteDocButtonClick = () => {},
  onDocumentStatisticsClick = () => {},
  isViewMode = false,
  isDraft = false,
}: Props) {
  const { userEvents } = useDeps();

  const [ showPDFUpgradeModal, setShowPDFUpgradeModal ] = useState(false);

  const action = useRef();

  function setAction (newAction) {
    action.current = newAction;
  }

  function triggerAction (newAction) {
    action.current = undefined;
    tracking.logEvent('Document Menu Accessed', {
      document_id: docId,
      action: newAction || 'No action',
    });
    switch (newAction) {
      case 'Present': return onFullscreenButtonClick();
      case 'Restore defaults': return onRestoreDefaults();
      case 'Settings': return onDocumentSettingsClick();
      case 'Duplicate': return duplicateDocument();
      case 'Discard draft': return onDiscardDraft();
      case 'Export to pdf': return onExportToPDFClick();
      case 'Delete document': return onDeleteDocButtonClick();
      case 'Statistics': return onDocumentStatisticsClick();
    }
  }

  return (
    <>
      <ButtonMenu
        id="document-menu"
        testId="documentMenu"
        icon="ellipsis-vertical"
        className={styles.documentMenu}
        buttonClassName={styles.button}
        tooltip="Document menu"
        onClose={() => triggerAction(action.current)}
        actions={[
          canEdit && isMobile() && ({
            label: 'Statistics',
            onClick: () => setAction('Statistics'),
            icon: 'document-statistics',
          }),
          (canEdit || documentIsDuplicatable) && !isMobile() && ({
            label: 'Make a copy',
            onClick: () => setAction('Duplicate'),
            icon: 'duplicate',
            className: styles.hideOnSmallScreens,
          }),
          canEdit && isDraft && !isViewMode && ({
            label: 'Discard draft',
            onClick: () => setAction('Discard draft'),
            icon: 'trash',
            tooltip: isViewMode ? 'Switch to edit mode to discard drafts' : !isDraft && 'No draft to discard',
          }),
          ((canEdit || canExportToPDF)) && ({
            label: 'Export to PDF',
            disabled: isDraft && canEdit,
            onClick: () => {
              canExportToPDF ? setAction('Export to pdf') : setShowPDFUpgradeModal(true);
            },
            icon: canExportToPDF ? 'pdf-file' : 'pdf-file-pro',
            tooltip: canEdit && exportToPDFTooltip(isDraft),
          }),
          canEdit && ({
            label: 'Delete project',
            onClick: () => {
              setAction('Delete document');
            },
            icon: 'trash',
          }),
        ].filter(Boolean)}
        />
      {showPDFUpgradeModal &&
        <Modal size="small" onClose={() => setShowPDFUpgradeModal(false)} closeButton={false} open>
          <div className={styles.exportToPDFUpgradeModal}>
            <h1>Level up for PDF exports ✈️</h1>
            <p>Upgrade your plan to gain access to PDF exports and all other exclusive features available in our subscriptions.</p>
            <div className={styles.buttons}>
              <Link href="/settings/billing/" passHref>
                <Button buttonType="primary" buttonSize="large" onClick={() => userEvents.upgradeInterestIndicated('export to pdf', 'product')}>Upgrade now</Button>
              </Link>
            </div>
            <div className={styles.closeModal}>
              <IconButton onClick={() => setShowPDFUpgradeModal(false)} iconName="window-close" ariaLabel="close" buttonType="grayScale" />
            </div>
          </div>
        </Modal>}
    </>
  );
}
