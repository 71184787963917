import { Range } from '@grid-is/apiary';
import { isImageUrl } from '@grid-is/browser-utils';
import { Icon } from '@grid-is/icon';
import { assert } from '@grid-is/validation';

import { EventStream } from '@/editor/EditorEventStream';
import { numberInRange } from '@/editor/smartSelection/cells';
import { inferStructure, Structure } from '@/editor/smartSelection/structure';
import { EditableElementTypes } from '@/editor/types/elements';
import { gridElements } from '@/grid/elements';
import { MenuItem } from '@/WorkbookEditor/components/Menu';
import { CellsProxy } from '@/WorkbookEditor/Sheet/utils/CellsProxy';
import { Selection } from '@/WorkbookEditor/utils/Selection';

export function getInsertFromSpreadsheetMenuItems (selection: Selection, cellsProxy: CellsProxy): MenuItem[] {
  const elementTypes = getAppropriateElementTypesForSelection(selection, cellsProxy);
  return generateMenuItemsFor(elementTypes, selection, cellsProxy);
}

export function getAppropriateElementTypesForSelection (selection: Selection, cellsProxy: CellsProxy): EditableElementTypes[] {
  const range = new Range({ top: selection.top || 0, left: selection.left || 0, right: selection.right || 0, bottom: selection.bottom || 0 });
  const structure = inferStructure(cellsProxy.sheet, range);
  if (structure.data.width === 1 && structure.data.height === 1) {
    return getElementTypesForSingleCell(cellsProxy, structure);
  }
  else {
    return getElementTypesForMultipleCells(cellsProxy, structure);
  }
}

function getElementTypesForSingleCell (cellsProxy: CellsProxy, structure: Structure): EditableElementTypes[] {
  const elTypes: EditableElementTypes[] = [];
  const cell = cellsProxy.getByIndex(structure.data.left, structure.data.top);
  if (!cell?.hasFormula) {
    elTypes.push('input');
    if (!cell || cell.isNumber) {
      elTypes.push('slider', 'tangle');
    }
    else if (typeof cell.v === 'boolean') {
      elTypes.push('checkbox');
    }
    if (isImageUrl(cell?.printValue || '')) {
      elTypes.push('image');
    }
    elTypes.push('button');
  }
  if (cell?.isNumber) {
    elTypes.push('kpi');
  }
  elTypes.push('text');
  return elTypes;
}

function getElementTypesForMultipleCells (cellsProxy: CellsProxy, structure: Structure): EditableElementTypes[] {
  const elTypes: EditableElementTypes[] = [];
  if (numberInRange(cellsProxy.sheet, structure.data)) {
    elTypes.push(
      'column',
      'bar',
      'line',
      'pie',
      'area',
      'scatter',
    );
  }
  elTypes.push('table');
  if (Math.min(structure.data.width, structure.data.height) <= 2) {
    elTypes.push('dropdown', 'radio');
  }
  return elTypes;
}

function generateMenuItemsFor (elementTypes: EditableElementTypes[], selection: Selection, cellsProxy: CellsProxy): MenuItem[] {
  return elementTypes.map(et => {
    const elementInfo = getElement(et);
    const iconName: any = `element-${elementInfo.icon}`;
    return {
      label: elementInfo.label,
      action: buildClickHandler(et, selection, cellsProxy),
      icon: <Icon name={iconName} size={16} />,
    };
  });
}

function getElement (type: string) {
  const element = gridElements.find(e => e.value === type);
  assert(element != null, `Unrecognized element type: ${type} `);
  return element;
}

function buildClickHandler (type: EditableElementTypes, selection: Selection, cellsProxy: CellsProxy) {
  return () => {
    EventStream.emit(EventStream.ADD_ELEMENT_FROM_SPREADSHEET_PANEL,
      {
        selection: [ [ selection.left, selection.top ], [ selection.right, selection.bottom ] ],
        sheetName: cellsProxy.sheet.name,
        workbookName: cellsProxy.workbookName,
        type,
      });
  };
}
