import React, { Component, CSSProperties } from 'react';
import csx from 'classnames';
import debounce from 'lodash.debounce';
import { NextRouter, withRouter } from 'next/router';
import { Descendant } from 'slate';

import { loadLazy as loadApiaryFunctions } from '@grid-is/apiary';
import { isFullscreenAvailable, normalizeReferrer, query, utm } from '@grid-is/browser-utils';
import { isSameColor } from '@grid-is/color-utils';
import { tracking } from '@grid-is/tracking';
import { assert } from '@grid-is/validation';

import { acquireUpdateLock, countView, type Document as TDocument, erase as deleteDoc } from '@/api/document';
import type { UserType } from '@/api/user';
import { type History, HistoryContextProvider } from '@/editor/contexts/HistoryContext';
import { EventStream } from '@/editor/EditorEventStream';
import { Doc } from '@/grid/Doc';
import { DOCUMENT_UPDATED, documentMonitor } from '@/grid/Doc/DocumentMonitor';
import { baseTheme } from '@/grid/Theme';
import { ThemesProvider } from '@/grid/ThemesContext';
import { applyStateFromURL, parseHref } from '@/grid/utils/urlState';
import { Button } from '@/grid-ui/Button';
import { confirmation } from '@/grid-ui/Confirmation';
import { ClipBoard } from '@/grid-ui/CopyToClipboard';
import { Modal } from '@/grid-ui/Modal';
import { Toast } from '@/grid-ui/Toast';
import { type Dependencies, withDeps } from '@/bootstrapping/dependencies';
import { BasePage } from '@/components/BasePage';
import { DocumentNav } from '@/components/Document/DocumentNav';
import { DocumentMenu } from '@/components/Document/DocumentNav/DocumentMenu/DocumentMenu';
import { DraftNotice } from '@/components/Document/DraftNotice';
import { Fullscreen } from '@/components/Document/Fullscreen';
import { DISCARDDONE, DISCARDWAIT, PUBLISHDONE, PUBLISHWAIT, SAVEDONE, SAVEFAIL, SAVEPENDING, SAVESTALE, type SaveState, SAVEWAIT } from '@/components/Document/states';
import { getPdfExportUrl } from '@/components/Document/utils';
import { DocumentStatistics } from '@/components/DocumentStatistics';
import { type OnChangeAccessArgs, Sharing } from '@/components/Sharing';
import { EDIT, LOCK_ERROR, SPREADSHEET_RIBBON_HEIGHT, VIEW } from '@/constants';
import { isMobile } from '@/utils';
import { loadFonts } from '@/utils/fonts';
import { startFsKey } from '@/utils/keys';
import { type ModelStateArray, parse as parseModelState, parseFromQuery, print as printModelState } from '@/utils/modelState';
import { scrollingEditor } from '@/utils/offset';
import { resizeEditor } from '@/utils/resize';
import { clearSelectionMemory } from '@/WorkbookEditor/EditingManager/SelectionMemory';

import { BrowserWarning } from '../BrowserWarning';
import { DocumentActions } from '../DocumentActions';
import { Scenarios } from '../DocumentActions/Scenarios';
import { populateDocumentViewSourceTracking } from './documentTrackingUtils';
import { EmbedWatermark } from './EmbedWatermark';
import { ExternalCSS } from './ExternalCSS';
import { Document } from './index';

import styles from './documentview.module.scss';

const SAVE_RETRY_INTERVAL = 10; // in seconds
const AUTOSAVE_INTERVAL = 0.5; // in seconds

export type ToggleEditModeProps = {
  eventFromClick?: boolean,
}

type DocumentViewProps = {
  // used to detect document loaded on embed route
  onDocLoad?: (doc: Doc) => void,
  router: NextRouter,
  mode: 'view' | 'edit',
  embed?: boolean,
  notionEmbed?: boolean,
  embedReferrer?: string,
  shouldServerSideRender?: boolean,
  documentFromServer?: {
    title: string,
    description: string,
    path: string,
    thumbnailUrl: string,
    document?: TDocument,
    shouldServerSideRender?: boolean,
  },
  user?: UserType,
  additionalAnalytics?: Record<string, any>,
  setAdditionalAnalytics?: (analytics: Record<string, any>) => void,
  deps: Dependencies,
}

type DocumentViewState = {
  editorHistory: {
    history: History,
    updateHistory: (history: History) => void,
  },
  doc?: Doc,
  fullscreen: boolean,
  ready: boolean,
  statisticsOpen: boolean,
  restoreNotice?: number | null,
  initialModelState: ModelStateArray | null,
  forceHideChrome: boolean,
  isDevicePreview?: boolean,
  modelDirty: boolean,
  duplicatedUrl: string | null,
  mode: 'view' | 'edit',
  lockError?: boolean,
  nativeSaveError?: boolean,
  unauthenticatedError?: boolean,
  saveState: SaveState,
  deleteDocError?: string,
  modeBeforePrint?: 'edit' | null,
  error?: unknown,
}

class DocumentViewInternal extends Component<DocumentViewProps, DocumentViewState> {
  // eslint-disable-next-line react/sort-comp
  clipboardRef = React.createRef<ClipBoard>();
  updateHistory: (history: History) => void;
  saveDocumentLater: () => void;
  _timerRetry: ReturnType<typeof setTimeout> | undefined;
  pendingEmbedResponses: string[] = [];

  constructor (props: DocumentViewProps) {
    super(props);

    // XXX: See if we can keep this state within the HistoryContext rather than here
    this.updateHistory = history => {
      this.setState(prevState => {
        return { editorHistory: { ...prevState.editorHistory, history } };
      });
    };
    const queryParams = typeof window !== 'undefined' ? query.parse(window.location.search) : {};

    const { documentFromServer } = this.props;
    let doc: Doc | undefined;
    if (documentFromServer?.document) {
      doc = new Doc(documentFromServer.document);
    }

    this.state = {
      doc,
      fullscreen: false,
      saveState: SAVEDONE,
      ready: false,
      statisticsOpen: queryParams.stats === '1' && this.props.user?.username === doc?.author,
      restoreNotice: null,
      initialModelState: parseFromQuery(queryParams),
      forceHideChrome: queryParams.chrome === 'off',
      isDevicePreview: !!queryParams.isDevicePreview,
      modelDirty: false,
      duplicatedUrl: null,
      mode: this.props.mode,
      editorHistory: {
        history: { undos: [], redos: [] },
        updateHistory: this.updateHistory,
      },
      lockError: undefined,
      unauthenticatedError: undefined,
    };

    this.saveDocumentLater = debounce(this.saveDocument, AUTOSAVE_INTERVAL * 1000);
  }

  componentDidMount () {
    // We do not want to persist the last cell selected when a user navigates away from the document
    // or on hard refresh.
    clearSelectionMemory();
    window.addEventListener('beforeprint', this.beforePrint);
    window.addEventListener('afterprint', this.afterPrint);
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('message', this.handleMessageEvent, false);
    EventStream.on(EventStream.DOCUMENT_TITLE_UPDATE, this.onTitleUpdate);
    EventStream.on(EventStream.DOCUMENT_META_UPDATE, this.onSettingsChange);
    EventStream.on(EventStream.DOCUMENT_RESET_APPEARANCE, this.onResetAppearance);
    EventStream.on(EventStream.DOCUMENT_STATISTICS_CLICKED, this.onDocumentStatisticsClick);
    if (this.state.doc) {
      this.loadDocument(this.state.doc);
    }
    else {
      this.fetchDocument();
    }
    if (this.props.mode === VIEW && !this.props.embed && (this.state.doc?.author === this.props.user?.username)) {
      tracking.logAppcuesEvent('Document Opened In View Mode');
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { doc, isDevicePreview } = this.state;
    const { user, router, embed } = this.props;
    if (prevProps.router.query.documentId !== router.query.documentId) {
      this.fetchDocument();
    }
    if (router.query.s !== prevProps.router.query.s) {
      this.setInitialStateOnQueryParams(String(router.query.s || ''));
    }
    if (router.query.stats === '1' && router.query.stats !== prevProps.router.query.stats) {
      this.setStatisticsOpen(true);
    }

    if (doc) {
      // only attempt watching if Document has loaded
      this.enableDocumentWatching();

      const modelEnv = doc.model.env;
      modelEnv.set('isMobile', isMobile());
      modelEnv.set('username', user ? user.username : undefined);
      if (!modelEnv.has('isPrint')) {
        modelEnv.set('isPrint', false);
      }
    }

    if (user && doc) {
      if (!prevState.doc || (!prevProps.user || user)) {
        this.handleDocumentRedirects(doc)
          .catch(this.loadFail);
      }
    }

    if (doc && (prevState.doc !== doc || (prevState.doc && prevState.doc.view_access !== doc.view_access))) {
      const canEdit = doc.access === EDIT;
      if ((!embed || isDevicePreview) && doc.hasDraft && canEdit) {
        doc.useDraft();
      }
      else {
        doc.discardDraft();
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('beforeprint', this.beforePrint);
    window.removeEventListener('afterprint', this.afterPrint);
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('message', this.handleMessageEvent);
    EventStream.off(EventStream.DOCUMENT_META_UPDATE, this.onSettingsChange);
    EventStream.off(EventStream.DOCUMENT_RESET_APPEARANCE, this.onResetAppearance);
    EventStream.off(EventStream.DOCUMENT_TITLE_UPDATE, this.onTitleUpdate);
    EventStream.off(EventStream.DOCUMENT_STATISTICS_CLICKED, this.onDocumentStatisticsClick);
    const { doc } = this.state;
    if (doc) {
      documentMonitor.off(DOCUMENT_UPDATED, this.reloadDocument);
      documentMonitor.unwatch(doc.id);
    }
  }

  setReady = () => {
    return new Promise<void>(resolve => {
      this.setState(() => {
        return {
          ready: true,
        };
      }, resolve);
    });
  };

  setURL (path: string | null = null, force = false, queryParams: string | null = null) {
    const { router, embed } = this.props;
    // we've hit some strange behavior where this was wrongly triggering
    // a beforeunload event, so we don't touch the URL unless we have to
    const samePath = !path || path === router.asPath;
    if (force || (path && !samePath && !embed)) {
      // users can reset to that state by refreshing their browser
      // don't use Next.js's router.replace here, it will result in jarring UX while editing titles
      window.history.replaceState(window.history.state, '', `${path}${this.isEditMode ? '/edit' : ''}${queryParams && '?' + queryParams}`);
    }
  }

  setViewMode (mode: 'view' | 'edit') {
    this.setState({ mode });
  }

  setStatisticsOpen (statisticsOpen: boolean) {
    this.setState({ statisticsOpen });
  }

  setInitialStateOnQueryParams (serializedModelState: string) {
    this.setState({ initialModelState: parseModelState(serializedModelState) });
  }

  get isEditMode () {
    return this.state.mode === EDIT;
  }

  onKeyDown = e => {
    if (startFsKey(e) && isFullscreenAvailable()) {
      e.preventDefault();
      this.setState({ fullscreen: true }, Fullscreen.open);
    }
  };

  handleMessageEvent = event => {
    // only handle messages from dev/ingrid.is/grid.is
    if (event.origin !== 'http://localhost:8080' && event.origin !== 'https://ingrid.is' && event.origin !== 'https://grid.is' && !event.origin.endsWith('.calculatorstudio.co')) {
      return;
    }

    const data = event.data;
    if (!data.gridMessage) {
      // this is _not_ an internal message, discard
      return;
    }

    if (data.type === 'pdf') {
      this.onExportToPDFClick();
    }
    else if (data.type === 'present') {
      this.onClickFullscreenButton();
    }
    else if (data.type === 'copyurl') {
      this.clipboardRef.current?.copy(data.url);
    }
  };

  onUpdateDocument = (data: Descendant) => {
    const { saveState, doc } = this.state;
    // it is important to maintain SAVEWAIT if it is already the state
    // as otherwise we would try to post again creating a race. #3092
    const newState = saveState === SAVEWAIT ? SAVEWAIT : SAVEPENDING;
    if (doc) {
      doc.fromSlateDom(data);
    }
    this.setState({ saveState: newState }, this.saveDocumentLater);
  };

  onChangeAccess = ({ mode, isDuplicatable, isEmbeddable, allowedDomains, hasPassword, viewersCanSaveScenarios }: OnChangeAccessArgs) => {
    const doc = this.state.doc;
    if (doc) {
      // XXX: extract into function for improved re-use. There's another implementation in src/components/Profile/index.tsx that differs slightly.
      doc.view_access = mode || doc.view_access;
      doc.is_duplicatable = isDuplicatable ?? doc.is_duplicatable;
      doc.is_embeddable = isEmbeddable ?? doc.is_embeddable;
      doc.has_password = hasPassword ?? doc.has_password;
      doc.viewers_can_save_scenarios = viewersCanSaveScenarios ?? doc.viewers_can_save_scenarios;
      doc.allowed_domains = allowedDomains || doc.allowed_domains;
      this.setState({ doc: doc });
    }
  };

  onTitleUpdate = ({ title }) => {
    const { doc } = this.state;
    doc?.updateTitle(title)
      .catch(e => {
        if (e.error_code === 'document_edit_lock_held_by_another_user') {
          tracking.logEvent('Edit Lock Popup Displayed', {
            document_id: doc?.id,
            document_owner_id:  doc?.creator.id,
            is_document_owner:  doc?.author === this.props.user?.username,
            action: 'Update document title',
          });
          this.setState({ lockError: true });
        }
      });
  };

  onSettingsChange = async (event: { option: string, value: string | Record<string, any>[] }) => {
    const { doc } = this.state;
    // xxx: event.value will always be a string, the typing is incorrect
    if (!doc || typeof event.value !== 'string') {
      return;
    }
    doc.applySetting(event.option, event.value);

    // onSettingsChange will not be called unless there is a change,
    // so we should be safe to set a dirty state
    doc.isDirty = true;

    // track language changes
    if (event.option === 'language') {
      tracking.logEvent('Document Language Updated', {
        document_id: doc.id,
        language: event.value,
      });
    }

    // we must wait for fonts to load before we re-render because otherwise we
    // may be rendering the doc with a fallback font which can cause charts to
    // misalign (they measure text and won't have the correct metrics)
    if (event.value.endsWith('Font')) {
      await loadFonts([ event.value ]);
    }

    // update model to trigger re-render
    doc.model?.triggerUpdate();
    this.setState({ saveState: SAVEPENDING }, this.saveDocumentLater);
  };

  onResetAppearance = () => {
    const { doc } = this.state;
    assert(doc);
    doc.design.reset();
    doc.isDirty = true;
    this.setState({ saveState: SAVEPENDING }, this.saveDocumentLater);
  };

  onSaveButton = () => {
    const doc = this.state.doc;
    assert(doc);
    this.setState({ saveState: PUBLISHWAIT }, () => {
      doc.publishDraft()
        .then(() => {
          this.setState({ saveState: PUBLISHDONE });
        })
        .catch(e => {
          if (e.error_code === 'document_version_mismatch') {
            this.onDocumentVersionMismatch();
          }
          else {
            this.setState({ saveState: SAVEFAIL });
            // try again in a few sec
            this._timerRetry = setTimeout(() => {
              // state is the same?
              if (this.state.saveState === SAVEFAIL) {
                // retry...
                this.onSaveButton();
              }
            }, 1000 * SAVE_RETRY_INTERVAL);
          }
        });
    });
    tracking.logEvent('Draft Published', { document_id: doc.id });
  };

  onDiscardButton = () => {
    confirmation({
      title: 'Discard draft?',
      message: (<><p>This will delete changes made since you last published.</p><p>Discarding a draft can't be undone.</p></>),
      confirmLabel: 'Delete',
      onConfirm: () => {
        const doc = this.state.doc;
        assert(doc);
        this.setState({ saveState: DISCARDWAIT }, () => {
          doc.discardDraft(true)
            .then(() => {
              this.setState({ saveState: DISCARDDONE });
              EventStream.emit(EventStream.DRAFT_DISCARD, { dom: doc.toSlateDom() });
            })
            .catch(e => {
              if (e.error_code === 'document_version_mismatch') {
                this.onDocumentVersionMismatch();
              }
              else {
                this.setState({ saveState: SAVEFAIL });
                // try again in a few sec
                this._timerRetry = setTimeout(() => {
                  // state is the same?
                  if (this.state.saveState === SAVEFAIL) {
                    // retry...
                    this.onDiscardButton();
                  }
                }, 1000 * SAVE_RETRY_INTERVAL);
              }
            });
        });
        tracking.logEvent('Draft Discarded', { document_id: doc.id });
      },
    });
  };

  onDeleteDoc = () => {
    confirmation({
      title: 'Delete project?',
      message: (<><p>This will delete the project.</p><p>Deleting a project can't be undone.</p></>),
      confirmLabel: 'Delete',
      onConfirm: () => {
        const doc = this.state.doc;
        assert(doc?.id);
        deleteDoc(doc.id)
          .then(() => {
            this.props.router.push('/me');
          })
          .catch((e: unknown) => {
            if (e && typeof e === 'object' && 'message' in e && e.message === 'string') {
              this.setState({ deleteDocError: e.message });
            }
          });
      },
    });
  };

  onDocLoad = () => {
    const { doc } = this.state;
    const { embed, notionEmbed, router, additionalAnalytics = {}, setAdditionalAnalytics = () => {} } = this.props;

    this.enableDocumentWatching();

    assert(doc?.id);
    const theme = doc.theme;
    const backgroundApplied = !isSameColor(theme?.backgroundColor, baseTheme.backgroundColor);
    const accentColorApplied = !isSameColor(theme?.accentColor, baseTheme.accentColor);
    const titleFontApplied = theme?.titleFont !== baseTheme.titleFont;
    const bodyFontApplied = theme?.bodyFont !== baseTheme.bodyFont;

    const inIframe = window.location !== window.parent.location;
    const isEmbedded = inIframe || !!embed;

    const notificationAttributes = router.query.ref === 'notification'
      ? populateDocumentViewSourceTracking(
        String(router.query.notification_type),
        String(router.query.notification_channel),
      )
      : {};

    // this is used to track Document Viewed event on the server
    countView(doc.id, isEmbedded, document.referrer, utm.getAnalyticsContext({ properties: {
      ...additionalAnalytics,
      document_display_type: embed ? 'Embed' : 'GRID Document',
      display_full_width: inIframe ? router.query.width === 'full' : undefined,
      in_iframe: inIframe,
      is_partial_embed: embed && router.query.p !== undefined,
      is_notion_preview: notionEmbed,
      scale_to_fit: inIframe ? router.query.scale_to_fit === 'true' : undefined,
      footer_control_options: embed && router.query.showControls ? 'Displayed' : 'Not Displayed',
      background: backgroundApplied ? 'Custom' : 'Default',
      accent_color: accentColorApplied ? 'Custom' : 'Default',
      title_font: titleFontApplied ? 'Custom' : 'Default',
      title_font_used: titleFontApplied ? theme?.titleFont : baseTheme.titleFont,
      body_font: bodyFontApplied ? 'Custom' : 'Default',
      body_font_used: bodyFontApplied ? theme?.bodyFont : baseTheme.bodyFont,
      referrer: normalizeReferrer(document.referrer),
      referrer_raw: document.referrer,
      device_type: isMobile() ? 'Mobile' : 'Desktop',
      ...notificationAttributes,
    } })).then(data => {
      setAdditionalAnalytics({});
      return data;
    });
  };

  onDocSlugUpdate = () => {
    const { doc, mode } = this.state;
    assert(doc);
    this.forceUpdate();
    if (doc.getCanonicalUrl()) {
      const queryParams = query.parse(window.location.search);
      if (mode === EDIT && queryParams?.s) {
        delete queryParams.s;
      }

      this.setURL(doc.getCanonicalUrl(), false, query.serialize(queryParams));
    }
  };

  onClickFullscreenButton = () => {
    const { doc } = this.state;
    const { userEvents } = this.props.deps;
    this.setState({ fullscreen: true }, Fullscreen.open);
    if (doc?.id) {
      userEvents.fullscreenModeEntered(doc.id, doc.type);
    }
  };

  onDocumentStatisticsClick = () => {
    const { doc } = this.state;
    const canViewDocumentStatistics =  doc?.access === EDIT && doc.features.can_view_document_stats;
    if (canViewDocumentStatistics) {
      tracking.logEvent('Document Statistics Viewed', { document_id: doc.id });
    }
    this.setState({ statisticsOpen: true });
  };

  maybeApplyBackgroundFromParams = (doc: Doc) => {
    const { router, embed } = this.props;
    const { background } = router.query;
    if (doc && embed && typeof background === 'string') {
      doc.design.setProps({
        backgroundColorOverride: background,
      });
    }
    return doc;
  };

  handleDocumentRedirects = (doc: Doc) => {
    const { mode } = this.state;
    if (mode === EDIT && doc?.access === VIEW) {
      // if the editable route is being viewed by a non author then redirect the to
      // viewable document;
      this.redirectToViewableDocument();
      return Promise.reject('document_redirect_handled');
    }
    return Promise.resolve(doc);
  };

  handleDraftState = doc => {
    const { isDevicePreview } = this.state;
    const { embed }  = this.props;
    const canEdit = doc?.access === EDIT;
    // If the document has a draft and the user has edit rights, use the draft.
    // If the user is viewing in an embed then we shouldn't use the draft,
    // unless the embed route passes the parameter isDevicePreview, indicating that
    // it is being embedded in our mobile or embed preview mechanism.
    if ((!embed || isDevicePreview) && doc.hasDraft && canEdit) {
      doc.useDraft();
    }
    return doc;
  };

  handlePartialEmbed = doc => {
    const queryParams = typeof window !== 'undefined' ? query.parse(window.location.search) : {};
    const nodeFilter = this.parseNodeFilter(queryParams.p);
    if (nodeFilter.length) {
      doc.filterNodes(nodeFilter, queryParams.preserveLayout === '1');
    }
    return doc;
  };

  onModelChange = (modelState, cb) => {
    let modelDirty = !!modelState.length;
    const { initialModelState } = this.state;
    if (this.props.embed && initialModelState) {
      // in embeds we consider the model clean if the modelState is equal to initialModelState
      if (initialModelState.length !== modelState.length) {
        modelDirty = true;
      }
      else {
        // eslint-disable-next-line no-nested-ternary
        const byRefAsc = (a, b) => ((a[0] === b[0]) ? 0 : (a[0] > b[0]) ? 1 : -1);
        modelState.sort(byRefAsc);
        initialModelState.sort(byRefAsc);
        modelDirty = modelState.some((pair, i) => (
          pair[0] !== initialModelState[i][0] || pair[1] !== initialModelState[i][1]
        ));
      }
    }
    const state: Pick<DocumentViewState, 'restoreNotice' | 'modelDirty'> = { modelDirty };
    // because resetting triggers a model change, we only dismiss the notice after half a second
    const resetStateAge = this.state.restoreNotice ? Date.now() - this.state.restoreNotice : 0;
    if (resetStateAge > 500) {
      state.restoreNotice = null;
    }
    this.setState(state, cb);
  };

  onExportToPDFClick = () => {
    const { doc } = this.state;
    const docUrl = doc && doc.getCanonicalUrl(false);
    if (docUrl) {
      window.open(getPdfExportUrl(docUrl, printModelState(doc.modelState)), '_blank');
    }
  };

  onCloseDuplicateNotification = () => {
    this.setState({ duplicatedUrl: null });
  };

  onRestoreDefaults = () => {
    this.setState({
      modelDirty: false,
      restoreNotice: Date.now(),
    }, () => {
      const { doc } = this.state;
      if (doc) {
        doc.model.reset();
        doc.modelState = [];  // XXX: temporarily hack, this should belong in Doc.js
      }
    });
  };

  onLoadScenario = (scenario?: string) => {
    const { doc } = this.state;
    if (doc) {
      const link = parseHref(`?s=${scenario}`);
      applyStateFromURL(doc.model, link.url);
    }
  };

  onLockConflict = (action = 'Load document') => {
    const { doc } = this.state;
    tracking.logEvent('Edit Lock Popup Displayed', {
      document_id: doc?.id,
      document_owner_id:  doc?.creator.id,
      is_document_owner:  doc?.author === this.props.user?.username,
      action,
    });
    this.setState({ lockError: true });
  };

  onDocumentVersionMismatch = () => {
    this.setState({ saveState: SAVESTALE });
  };

  onNativeSaveFail = () => {
    this.setState({ nativeSaveError: true });
  };

  onUnauthenticatedError = () => {
    this.setState({ unauthenticatedError: true });
  };

  acquireLock = (doc: Doc) => {
    const { mode } = this.state;
    if (mode === VIEW) {
      // nothing to acquire in view mode
      return Promise.resolve(doc);
    }
    return acquireUpdateLock(doc.id!)
      .then(() => {
        return Promise.resolve(doc);
      })
      .catch(() => {
        return Promise.reject('document_lock_error');
      });
  };

  enableDocumentWatching () {
    const { doc, isDevicePreview } = this.state;
    const { embed, notionEmbed } = this.props;
    const canEdit = doc?.access === EDIT;
    if (doc?.id && (!embed || notionEmbed || (isDevicePreview && canEdit))) {
      // having this on for all embeds could cause thousands of
      // simultaneous requests if a popular embed gets updated
      documentMonitor.watch(doc.id);
      if (!documentMonitor.hasListeners(DOCUMENT_UPDATED)) {
        documentMonitor.on(DOCUMENT_UPDATED, this.reloadDocument);
      }
    }
  }

  parseNodeFilter = partialParameter => {
    return (partialParameter || '').split(',').filter(Boolean);
  };

  closeFullScreen = duration => {
    const { doc } = this.state;
    const { userEvents } = this.props.deps;
    this.setState({ fullscreen: false });
    if (doc?.id) {
      userEvents.fullscreenModeExited(doc.id, doc.type, duration);
    }
  };

  fetchDocument () {
    const { router, embed, embedReferrer } = this.props;
    const { documentId, auth_token } = router.query;

    if (!documentId || this.state.doc?.slug === documentId) {
      return;
    }

    this.loadDocument(Doc.fetch(
      documentId,
      auth_token,
      embed,
      embedReferrer,
    ));
  }

  beforePrint = () => {
    const { doc } = this.state;
    if (this.isEditMode && doc && !doc.isBlank()) {
      this.setState({ modeBeforePrint: EDIT });
      this.toggleEditMode({ eventFromClick: false });
    }
    if (doc) {
      doc.model.env.set('isPrint', true);
      doc.model.recalculate();
    }
  };

  afterPrint = () => {
    if (this.state.modeBeforePrint === EDIT) {
      this.setState({ modeBeforePrint: null });
      this.toggleEditMode({ eventFromClick: false });
    }
    const { doc } = this.state;
    if (doc) {
      doc.model.env.set('isPrint', false);
      doc.model.recalculate();
    }
  };

  loadDocument = (documentOrPromise: Doc | Promise<Doc>) => {
    return Promise.resolve(documentOrPromise)
      .then(this.handleDocumentRedirects)
      .then(this.acquireLock)
      .then(this.registerDoc)
      .then(this.maybeApplyBackgroundFromParams)
      .then(this.handleDraftState)
      .then(this.handlePartialEmbed)
      .then(doc => {
        doc.emit('slugchange');
        doc.emit('ready');
        if (this.props.onDocLoad) {
          this.props.onDocLoad(doc);
        }
        return doc;
      })
      .then(this.setReady)
      .catch(this.loadFail);
  };

  redirectToViewableDocument = (fullPageRedirect?: boolean) => {
    // TODO: temporary hack to handle edit route, while the document view component is used by both the //[documentid]/edit & /[documentid]
    const { router } = this.props;
    const { user, documentId } = router.query;
    if (user && documentId) {
      if (fullPageRedirect) {
        window.location.href = `/${user}/${documentId}`;
      }
      else {
        router.replace(`/${user}/${documentId}`);
      }
    }
  };

  loadFail = (err: unknown) => {
    const { doc } = this.state;
    const { user } = this.props;
    if (this.isEditMode && err && typeof err === 'object' && 'status' in err && err.status === 403) {
      this.redirectToViewableDocument();
    }
    else if (err === 'document_lock_error') {
      tracking.logEvent('Edit Lock Popup Displayed', {
        document_id: doc?.id,
        document_owner_id:  doc?.creator.id,
        is_document_owner:  doc?.author === user?.username,
        action: 'Load document',
      });
      this.setState({ lockError: true });
    }
    else if (err && err !== 'document_redirect_handled') {
      this.setState({ error: err });
    }
  };

  registerDoc = (doc: Doc) => {
    // hooray for hacks!! 💃
    // make sure that a "bootstrap change" won't cause a save to happen
    const tempDom = doc.toSlateDom();
    doc.fromSlateDom(tempDom);

    doc.on('ready', this.onDocLoad);
    doc.on('slugchange', this.onDocSlugUpdate);
    doc.on('lock-conflict', this.onLockConflict);
    doc.on('document-version-mismatch', this.onDocumentVersionMismatch);
    doc.on('native-save-fail', this.onNativeSaveFail);
    doc.on('unauthenticated-user', this.onUnauthenticatedError);

    return new Promise<Doc>(resolve => {
      this.setState({
        doc: doc,
      }, () => resolve(doc));
    });
  };

  saveDocument = () => {
    // XXX: The saveState mechanism should be revised now that document writes are queued within the Doc class. A separate DocumentPersister may be called for.

    const { doc, mode } = this.state;
    // never try to save the document unless we have edit access
    // if there is no document, we don't need to save
    if (!doc || doc?.access !== EDIT || mode !== EDIT) {
      return;
    }

    // shortcut: if we're not going to save, there is no need to proceed
    if (!doc.isDirty) {
      this.setState({ saveState: SAVEDONE });
      return;
    }

    // clear any potential save retry timer
    clearTimeout(this._timerRetry);

    // are we already performing a save?
    if (this.state.saveState === SAVEWAIT) {
      // Yep, document is already being saved. But we know that
      // we have more changes so schedule another save...
      this._timerRetry = setTimeout(this.saveDocument, 1000 * 1);
      return;
    }

    this.setState({ saveState: SAVEWAIT }, () => {
      doc.save()
        .then(() => {
          this.setState({ saveState: SAVEDONE });
        })
        .catch(e => {
          if (e.error_code === 'document_version_mismatch') {
            this.onDocumentVersionMismatch();
          }
          else if (e.error_code === 'document_edit_lock_held_by_another_user') {
            this.onLockConflict('Save document');
          }
          else {
            this.setState({ saveState: SAVEFAIL });
            // try again in a few sec
            this._timerRetry = setTimeout(() => {
              // state is the same?
              if (this.state.saveState === SAVEFAIL) {
                // retry...
                this.saveDocument();
              }
            }, 1000 * SAVE_RETRY_INTERVAL);
          }
        });
    });
  };

  toggleEditMode = (props?: ToggleEditModeProps) => {
    const { eventFromClick = true } = props || {};
    const { doc, mode } = this.state;
    assert(doc?.id);
    const { user } = this.props;

    const nextMode = mode === VIEW ? EDIT : VIEW;
    if (nextMode === EDIT) {
      loadApiaryFunctions();
    }

    if (eventFromClick) {
      const { userEvents } = this.props.deps;
      const isOwner = doc.author === user?.username;
      if (nextMode === EDIT) {
        userEvents.editModeEntered(doc.id, doc.type, doc.creator.id, isOwner);
      }
      else {
        userEvents.viewModeEntered(doc.id, doc.type, doc.creator.id, isOwner);
      }
    }

    if (nextMode === VIEW) {
      // Next.js router only support silently replacing the URL for a URL that matches the
      // page being viewed. Using window.history.replaceState to toggle the /edit from the URL
      window.history.replaceState(window.history.state, '', doc.getCanonicalUrl());
    }
    else {
      window.history.replaceState(window.history.state, '', `${doc.getCanonicalUrl()}/edit`);
    }

    const scrollElement = scrollingEditor();
    if (mode === EDIT && scrollElement) {
      const shouldScroll = scrollElement.scrollTop > 0;
      const newSize = doc.model.getWorkbooks().length > 0 ? SPREADSHEET_RIBBON_HEIGHT : 0;
      resizeEditor(0, newSize, shouldScroll, true);
    }

    this.setViewMode(nextMode);
  };

  duplicateDocument = (initiated_from: string, title?: string) => {
    const { doc } = this.state;
    const { api } = this.props.deps;
    if (doc?.id) {
      api.documents.duplicate(doc.id, title, { properties: { initiated_from } })
        .then(newDoc => {
          this.setState({
            duplicatedUrl: `/@${newDoc.creator.username}/${newDoc.slug || newDoc.id}`,
          });
        });
    }
  };

  createDocumentFromTemplate = () => {
    this.duplicateDocument('Copy Template Button');
  };

  createDocumentCopy = (initiated_from: string) => {
    this.duplicateDocument(initiated_from);
  };

  reloadDocument = async () => {
    const { router, embedReferrer } = this.props;
    let { documentId, auth_token } = router.query;
    const { mode, doc } = this.state;
    if (doc && mode === VIEW) {
      // router.query can in principle have multiple instances of these. Require at least a documentId.
      if (Array.isArray(documentId)) {
        documentId = documentId[0];
      }
      assert(typeof documentId === 'string');
      if (Array.isArray(auth_token)) {
        // undefined is OK, but string[] is not (shouldn't have 2+ auth_tokens)
        auth_token = auth_token[0];
      }
      await doc.refresh(documentId, auth_token, embedReferrer);
      this.handleDraftState(doc);
      this.handlePartialEmbed(doc);
      doc.emit('slugchange');
      doc.emit('refresh');
      // break cache for all components
      doc.model.lastWrite++;
      doc.emit('modelrecalc');
    }
  };

  renderDuplicateNotification = () => {
    const { duplicatedUrl } = this.state;
    if (!duplicatedUrl) {
      return;
    }

    return (
      <Toast
        duration={10000}
        open
        onClose={this.onCloseDuplicateNotification}
        onClick={this.onCloseDuplicateNotification}
        callToAction={{
          label: 'Open',
          href: duplicatedUrl,
        }}
        message={
          <span>
            Document copied!
          </span>}
        />
    );
  };

  renderUnauthenticatedErrorModal = () => {
    const { unauthenticatedError } = this.state;
    const onClearError = () => {
      this.setState({ unauthenticatedError: undefined });
    };
    return (
      <Modal
        id="unauthenticated-error-modal"
        open={!!unauthenticatedError}
        closeButton={false}
        header="You’ve been signed out"
        size="small"
        footer={
          <div className={styles.modalButton}>
            <Button
              buttonType="primary"
              onClick={onClearError}
              >I understand
            </Button>
          </div>}
        >
        <p>
          This can happen when you sign out in a different browser tab, clear cookies, or when cookies expire.
        </p>
        <p>
          To continue editing your document, please log in again in a new tab. Closing this window and signing in in the current tab may cause the loss of any changes made since your document was last saved.
        </p>
      </Modal>
    );
  };

  renderLockErrorModal = () => {
    const { lockError } = this.state;
    const onCloseLockErrorModal = () => {
      this.setState({ lockError: undefined });
      this.redirectToViewableDocument(true);
    };
    return (
      <Modal
        id="error-spreadsheet-modal"
        open={!!lockError}
        closeButton={false}
        header={LOCK_ERROR.title}
        size="small"
        footer={
          <div className={styles.modalButton}>
            <Button
              buttonType="primary"
              onClick={onCloseLockErrorModal}
              >I understand
            </Button>
          </div>}
        >
        <div>
          {LOCK_ERROR.body}
        </div>
      </Modal>
    );
  };

  renderNativeSaveError = () => {
    const { nativeSaveError } = this.state;
    const onCloseNativeSaveErrorModal = () => {
      this.setState({ nativeSaveError: undefined });
      this.redirectToViewableDocument(true);  // XXX: this may be a bit harsh?
    };
    return (
      <Modal
        id="error-native-save-modal"
        open={!!nativeSaveError}
        closeButton={false}
        header="Failed to save"
        size="small"
        footer={
          <div className={styles.modalButton}>
            <Button
              buttonType="primary"
              onClick={onCloseNativeSaveErrorModal}
              >I understand
            </Button>
          </div>}
        >
        <div>
          The project sheet you’ve been editing has either been removed, or you may no longer have editing rights. To prevent data loss, this doc will be switched to <b>View mode</b>.
        </div>
      </Modal>
    );
  };

  renderDeleteDocError = () => {
    const { deleteDocError } = this.state;
    const onCloseDeleteDocErrorModal = () => {
      this.setState({ deleteDocError: undefined });
    };
    return (
      <Modal
        id="error-native-save-modal"
        open={!!deleteDocError}
        closeButton={false}
        header="Failed to delete project"
        size="small"
        footer={
          <div className={styles.modalButton}>
            <Button
              buttonType="primary"
              onClick={onCloseDeleteDocErrorModal}
              >I understand
            </Button>
          </div>}
        >
        <div>
          Something went wrong when trying to delete this document. Please try again later.
        </div>
      </Modal>
    );
  };

  renderRestoreNotice = () => {
    const { doc, restoreNotice } = this.state;
    if (!restoreNotice || !doc || this.props.user?.username === doc.author) {
      return;
    }
    const dismiss = () => this.setState({ restoreNotice: null });
    return (
      <Toast
        icon="info-circle"
        open={!!restoreNotice}
        onClose={dismiss}
        onClick={dismiss}
        className={this.props.embed ? undefined : styles.restoreNotice}
        duration={15000}
        message={
          <span>The document values have been reset to their original state, as set by its author.</span>
        }
        />
    );
  };

  render () {
    const { doc, mode, ready, error, forceHideChrome, statisticsOpen, modelDirty, initialModelState, fullscreen, saveState } = this.state;
    const { shouldServerSideRender, embed, user, router, notionEmbed } = this.props;
    const isOwner = doc?.creator.id === user?.id;
    const canViewDocumentStatistics =  doc?.access === EDIT && doc.features.can_view_document_stats;
    const useEmbedWatermark = embed && doc && !doc.features.remove_grid_branding_from_embeds;
    const useFullWidth = router.query.width === 'full';
    const alignCenter = router.query.align === 'center';
    const customPadding = router.query.padding;
    const utmParams = doc ? utm.parse({ source: 'grid-doc', medium: 'referral', grid_doc: doc.id }) : '';
    const canExportToPDF = doc?.features.can_enable_pdf_export && !!user?.id;
    const canEdit = isOwner || (doc?.access === EDIT && doc?.features.can_use_collaborative_editing);
    if (error) {
      throw error;
    }
    const shouldHideChrome = (
      doc?.features?.can_use_clean_landing_page &&
      doc?.showViewChrome === 'none'
    );
    let chromeless = false;
    if (embed || (shouldHideChrome && !canEdit) || (shouldHideChrome && forceHideChrome)) {
      chromeless = true;
    }
    const bgClr = doc?.theme.backgroundColor;
    const fallbackColors = {
      '--fallback-primary-btn-bg': doc?.theme.vars['--text-color'],
      '--fallback-primary-btn-bg-hover': `hsla(${doc?.theme.vars['--text-color-hsl']}, 0.9)`,
      '--fallback-primary-btn-text': doc?.theme.vars['--background-color'],
      '--fallback-secondary-btn-bg': `hsla(${doc?.theme.vars['--text-color-hsl']}, 0.15)`,
      '--fallback-secondary-btn-text': doc?.theme.vars['--text-color'],
    } as CSSProperties;
    const shouldUseFallbackColors = !this.isEditMode && bgClr && bgClr !== '#fff' && bgClr !== '#ffffff';

    return (
      <ThemesProvider
        theme={doc?.theme}
        chartTheme={doc?.chartTheme}
        updateBackground
        >
        {doc &&
          <DocumentStatistics
            documentId={doc.id}
            documentTitle={doc.title}
            shouldOpen={statisticsOpen}
            onClose={() => this.setState({ statisticsOpen: false })}
            canViewDocumentStatistics={canViewDocumentStatistics}
            />
        }
        {doc?.features?.can_apply_custom_styles ? (
          <ExternalCSS source={doc ? doc.externalCss : ''} />
        ) : null}
        {this.renderDuplicateNotification()}
        {this.renderRestoreNotice()}
        {this.renderLockErrorModal()}
        {this.renderNativeSaveError()}
        {this.renderUnauthenticatedErrorModal()}
        {this.renderDeleteDocError()}
        <BasePage
          utmParams={utmParams}
          ssr={shouldServerSideRender}
          title={doc ? doc.title || 'Untitled' : 'Loading...'}
          layout="full"
          hideNavigation
          isEmbed={embed}
          disableContentAutoOverflow={mode === EDIT}
          contentBgColor={mode === EDIT ? '#fafafa' : undefined}
          >
          {doc?.id && !chromeless &&
            <DocumentNav
              editModeEnabled={mode === EDIT}
              toggleEditMode={this.toggleEditMode}
              docTitle={doc.title}
              docSlug={doc.slug}
              hasDraft={doc.hasDraft}
              saveState={this.state.saveState}
              onSave={this.onSaveButton}
              onDiscard={this.onDiscardButton}
              noChromePreview={canEdit && shouldHideChrome}
              isOwner={isOwner}
              canEdit={canEdit}
              documentId={doc.id}
              documentAuthor={doc.creator}
              userId={user?.id}
              fallbackColors={shouldUseFallbackColors ? fallbackColors : undefined}
              sharingMenu={
                <Sharing
                  statefulUrl={doc.getStatefulUrl(false)}
                  currentUserId={user?.id}
                  allowedDomains={doc.allowed_domains}
                  documentId={doc.id}
                  documentOwnerId={doc.creator.id}
                  isDocumentOwner={isOwner}
                  getEmbedUrl={shareInOriginalState => doc.getEmbedUrl(shareInOriginalState)}
                  hasAlteredState={modelDirty}
                  isDocDuplicable={doc.is_duplicatable}
                  viewersCanSaveScenarios={doc.viewers_can_save_scenarios}
                  isEmbeddable={doc.is_embeddable}
                  isVerified={!!user?.email_verified}
                  onChangeDocumentAccess={this.onChangeAccess}
                  emailDomain={user?.can_share_with_domain ? user?.email_domain : undefined}
                  userEmail={user?.email}
                  viewAccess={doc.view_access || 'private'}
                  documentTitle={doc.title}
                  suggestedTitle={doc.getTitleSuggestion()}
                  onTitleChange={title => this.onTitleUpdate({ title })}
                  enabledFeatures={user?.account.features || {}}
                  canEdit={canEdit}
                  />
              }
              documentMenu={
                // XXX Sending this like this in is causing re-rendering, find out other way to send this in.
                <DocumentMenu
                  docId={doc?.id}
                  documentIsDuplicatable={doc?.is_duplicatable}
                  duplicateDocument={() => this.createDocumentCopy('Document Menu')}
                  onExportToPDFClick={this.onExportToPDFClick}
                  onDocumentSettingsClick={() => {
                    tracking.logEvent('Document Settings Opened', {
                      document_id: doc.id,
                      opened_from: 'Document Menu',
                    });
                    EventStream.emit(EventStream.ON_DOCUMENT_SETTINGS_CLICK, { data: doc });
                  }}
                  canExportToPDF={canExportToPDF}
                  onFullscreenButtonClick={this.onClickFullscreenButton}
                  onDeleteDocButtonClick={this.onDeleteDoc}
                  onDocumentStatisticsClick={this.onDocumentStatisticsClick}
                  onRestoreDefaults={this.onRestoreDefaults}
                  isViewMode={mode === VIEW}
                  isDraft={doc?.hasDraft}
                  onDiscardDraft={this.onDiscardButton}
                  canEdit={doc?.access === EDIT}
                  />}
              />}
          {mode === VIEW && isMobile() && doc?.hasDraft && isOwner && <DraftNotice isMobile />}
          <div className={csx(styles.docWrap, embed && styles.embed)} id="doc-wrap">
            {useEmbedWatermark && notionEmbed && <EmbedWatermark docCreatorId={doc.creator.id} isNotionEmbed={notionEmbed} />}
            <div id="doc-banner-anchor" className={styles.docBannerAnchor} />
            <ClipBoard ref={this.clipboardRef} />
            <HistoryContextProvider
              value={this.state.editorHistory}
              >
              {doc &&
                <Document
                  doc={doc}
                  shouldServerSideRender={!!shouldServerSideRender}
                  ready={(shouldServerSideRender && !!doc) || ready}
                  mode={mode}
                  initialModelState={initialModelState}
                  hasAlteredState={modelDirty}
                  onClickFullscreenButton={this.onClickFullscreenButton}
                  onModelChange={this.onModelChange}
                  onUpdateDocument={this.onUpdateDocument}
                  closeFullScreen={this.closeFullScreen}
                  fullscreen={fullscreen}
                  embed={!!embed}
                  chromeless={chromeless}
                  saveState={saveState}
                  isOwner={isOwner}
                  useFullWidth={useFullWidth}
                  alignCenter={alignCenter}
                  customPadding={customPadding ? customPadding as string : undefined}
                  />
              }
              {(!chromeless && doc?.id) &&
                <Scenarios
                  statefulUrl={doc.getStatefulUrl(false)}
                  onRestoreDefaults={this.onRestoreDefaults}
                  documentId={doc.id}
                  viewAccess={doc.view_access}
                  isOwner={isOwner}
                  hasAlteredState={modelDirty}
                  onLoadScenario={this.onLoadScenario}
                  canEdit={canEdit}
                  viewersCanSaveScenarios={doc.viewers_can_save_scenarios}
                  />
              }
              {!chromeless && doc?.id && mode === 'view' && (
                <DocumentActions
                  mode={mode}
                  hasAlteredState={modelDirty}
                  canViewStatistics={isOwner}
                  onDocumentDesignClick={() => {
                    tracking.logEvent('Document Settings Opened', {
                      document_id: doc.id,
                      opened_from: 'Document Actions',
                    });
                    EventStream.emit(EventStream.ON_DOCUMENT_SETTINGS_CLICK, { data: doc });
                  }}
                  onFullscreenClick={this.onClickFullscreenButton}
                  fallbackColors={shouldUseFallbackColors ? fallbackColors : undefined}
                  />
              )}
            </HistoryContextProvider>
            {mode === EDIT && <BrowserWarning />}
          </div>
        </BasePage>
      </ThemesProvider>
    );
  }
}
export const DocumentView = withDeps(withRouter(DocumentViewInternal));
