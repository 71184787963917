export function startFsKey (e) {
  // COMMAND-Return (PowerPoint on MacOS + GSlides)
  if (e.metaKey && e.keyCode === 13) {
    return true;
  }
  // F5 (PowerPoint on Windows)
  else if (e.keyCode === 116) {
    return true;
  }

  return false;
}
