import { ReactNode, useCallback, useState } from 'react';
import csx from 'classnames';

import { Icon } from '@grid-is/icon';
import { tracking } from '@grid-is/tracking';

import { acquireUpdateLock, returnUpdateLock } from '@/api/document';
import { Button } from '@/grid-ui/Button';
import { Modal } from '@/grid-ui/Modal';
import { LOCK_ERROR } from '@/constants';

import styles from './EditToggle.module.scss';

export type EditToggleProps = {
  editModeEnabled?: boolean,
  disabled?: boolean,
  toggleEditMode?: () => void,
  docId: string,
  documentOwnerId: string,
  isOwner: boolean,
}

export const EditToggle = ({ editModeEnabled, docId, documentOwnerId, disabled, toggleEditMode = () => {}, isOwner }: EditToggleProps) => {
  const [ error, setError ] = useState<ReactNode | undefined>();
  const [ errorHeader, setErrorHeader ] = useState<string | undefined>();

  const handleClick = useCallback(() => {
    if (editModeEnabled) {
      // we are in EDIT mode, going back to VIEW mode
      returnUpdateLock(docId).then(toggleEditMode)
        .catch(error => {
          setError('Could not return update lock: ' + error.error_message);
        });
    }
    else {
      // we are in VIEW mode, going back to EDIT mode
      acquireUpdateLock(docId).then(lockAcquired => {
        if (lockAcquired) {
          toggleEditMode();
        }
      })
        .catch(error => {
          if (error.error_code === 'document_edit_lock_held_by_another_user') {
            setError(LOCK_ERROR.body);
            setErrorHeader(LOCK_ERROR.title);

            tracking.logEvent('Edit Lock Popup Displayed', {
              document_id: docId,
              document_owner_id:  documentOwnerId,
              is_document_owner:  isOwner,
              action: 'Edit toggle',
            });
          }
          else {
            setError(error.error_message);
          }
        });
    }
  }, [ editModeEnabled, toggleEditMode, docId, documentOwnerId, isOwner ]);

  function onClearError () {
    setError(undefined);
    setErrorHeader(undefined);
  }

  return (
    <>
      <button
        type="button"
        className={csx(
          styles.editToggle,
          disabled && styles.disabled,
        )}
        onClick={disabled ? () => {} : handleClick}
        data-testid="edit-toggle"
        data-obid="edit-toggle"
        >
        <Icon name={editModeEnabled ? 'eye' : 'pencil'} size={16} />
        {editModeEnabled ? 'View' : 'Edit'}
      </button>

      <Modal
        id="error-spreadsheet-modal"
        open={!!error}
        closeButton={false}
        header={errorHeader ?? 'Uh oh'}
        size="small"
        footer={
          <div className={styles.modalButton}>
            <Button
              buttonType="primary"
              onClick={onClearError}
              >I understand
            </Button>
          </div>}
        >
        <div data-testid="edit-toggle-error">
          {error ?? ''}
        </div>
      </Modal>
    </>
  );
};
