import { useEffect } from 'react';

const STYLE_ELM_ID = 'ext-style';

// The element is free to live on, we'll re-use it if possible.
function cleanup () {
  const styleElm = document.getElementById(STYLE_ELM_ID);
  if (styleElm) {
    styleElm.innerHTML = '';
  }
}

export function ExternalCSS ({ source }: { source: string }) {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      let styleElm = document.getElementById(STYLE_ELM_ID);
      if (!styleElm) {
        styleElm = document.head.appendChild(document.createElement('style'));
        styleElm.id = STYLE_ELM_ID;
      }
      if (source !== styleElm.textContent) {
        styleElm.innerHTML = source;
      }
    }
    return cleanup;
  }, [ source ]);
  return null;
}
