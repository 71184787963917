import React from 'react';
import PropTypes from 'prop-types';

import { ModelError } from '@grid-is/apiary';
import { uuid } from '@grid-is/uuid';

import { Accordion } from '@/grid-ui/Accordion';
import { Alert } from '@/components/Alert';
import { SOURCE_ERRORS } from '@/constants';

import styles from './workbookInfoErrors.module.scss';

/* eslint-disable-next-line react/jsx-no-target-blank */
const NOTION_DOCUMENTATION_LINK = <a href="https://grid.is/@grid/known-issues-when-connecting-notion-databases-kZeiUfl7RemhAMDgAGR0tQ" target="_blank">Read more</a>;

const desc = {
  'spill': (
    <p>
      This spreadsheet contains <strong>the spilled range operator (#)</strong>, which we don't yet support.
      Read more on {}
      <a
        href="https://support.microsoft.com/en-us/office/spilled-range-operator-3dd5899f-bca2-4b9d-a172-3eae9ac22efd"
        target="_blank"
        rel="noreferrer"
        >
        the spilled range operator
      </a>.
    </p>
  ),
  'fn': (
    <p>
      This spreadsheet contains a function we don't yet support.
      Please review {}
      <a href="/help/coverage" target="_blank" rel="noreferrer">our list of currently supported
        functions
      </a> to learn more.

    </p>
  ),
  'fn-unsup': (
    <p>
      This spreadsheet contains a function that is not supported by the GRID engine.
      Calculations that depend on this function won't work properly in your project.
    </p>
  ),
  'tbl-unsup': (
    <p>
      This spreadsheet contains structured references for Excel tables, which we don't yet support.
      Read more on {}
      <a
        href="https://support.microsoft.com/en-us/office/using-structured-references-with-excel-tables-f5ed2452-2337-4f71-bed3-c8ae6d2b276e"
        target="_blank"
        rel="noreferrer"
        >
        structured references and Excel tables
      </a>.
    </p>
  ),
  'missing-name': (
    <p>
      The spreadsheet contains a reference to a missing named range.
    </p>
  ),
  'formula-parse': (
    <p>
      This spreadsheet contains a formula with syntax errors, or formula
      language features that we don't support.
    </p>
  ),
  'notion-incomplete-rollup': (
    <p>This database has rollup values that might contain incorrect data. {NOTION_DOCUMENTATION_LINK}</p>
  ),
  'notion-incomplete-relation': (
    <p>This database has relation values that might contain incorrect data. {NOTION_DOCUMENTATION_LINK}</p>
  ),
  'notion-unresolved-relation': (
    <p>This database has columns with relations to another database which could not be resolved. {NOTION_DOCUMENTATION_LINK}</p>
  ),
  'notion-unknown-user': (
    <p>This database lists users that could not be resolved. {NOTION_DOCUMENTATION_LINK}</p>
  ),
  'notion-missing-data': (
    <p>This database has columns with inaccessible data. {NOTION_DOCUMENTATION_LINK}</p>
  ),
  'unknown': (
    <p>
      An unknown kind of error occurred in this spreadsheet.
    </p>
  ),
};

// add descriptions for defect errors which may be emitted from the model
Object.keys(SOURCE_ERRORS).forEach(key => {
  desc[key] = <p>{SOURCE_ERRORS[key]?.body}</p>;
});

export function WorkbookInfoErrors ({ modelErrors }) {
  function getErrorByType (type) {
    const errorLevel = {
      error: ModelError.ERROR,
      warning: ModelError.WARNING,
      notice: ModelError.NOTICE,
    }[type];

    return modelErrors.filter(error => error.level === errorLevel);
  }

  function getLabel (errors) {
    if (errors.length === 1) {
      return errors[0].message;
    }

    const postfix = errors.length === 1 ? 'issue found' : 'issues found';
    return (
      <span className={styles.multipleErrors}>
        {errors.length} {postfix}
      </span>
    );
  }

  function getMessageBody (error) {
    if (error.references?.size) {
      const references = Array.from(error.references);
      const MAX_REFERENCES = 10;
      return (
        <>
          {desc[error.type]}
          <p>Problem affects:</p>
          <ul>
            {references.slice(0, MAX_REFERENCES).map(ref => <li key={ref + uuid()}>{ref}</li>)}
            {references.length > MAX_REFERENCES ? <li key={'ellipsis' + uuid()}>...</li> : null}
          </ul>
        </>
      );
    }
    return desc[error.type];
  }

  function getBody (errors) {
    if (errors.length === 1) {
      return getMessageBody(errors[0]);
    }

    return (
      <div className={styles.errors}>
        {errors.map(error => {
          const id = error.type + uuid();
          return (
            <Accordion
              lightTheme
              title={error.message}
              key={id}
              id={id}
              startExpanded={error.type === 'error'}
              >
              {getMessageBody(error)}
            </Accordion>
          );
        })
        }
      </div>
    );
  }

  function renderAlert (type) {
    const error = getErrorByType(type);
    if (error.length) {
      return (
        <Alert
          type={type}
          label={getLabel(error)}
          body={getBody(error)}
          className={styles.alert}
          />
      );
    }
  }

  return (
    <div className={styles.container}>
      {renderAlert('error')}
      {renderAlert('warning')}
      {renderAlert('notice')}
    </div>
  );
}

WorkbookInfoErrors.propTypes = {
  modelErrors: PropTypes.array,
};
