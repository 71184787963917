import { CSSProperties, ReactElement, useContext, useMemo, useRef, useState } from 'react';
import csx from 'classnames';

import { utm } from '@grid-is/browser-utils';

import { User } from '@/api/user';
import { ThemesContext } from '@/grid/ThemesContext';
import { SaveStatus } from '@/components/SaveStatus';
import { isMobile as useMobile } from '@/utils';

import { UserGroups } from '../../UserGroups/UserGroups';
import { ToggleEditModeProps } from '../DocumentView';
import type { SaveState } from '../states';
import { LeftNav } from './LeftNav';
import { MiddleNav } from './MiddleNav';
import { RightNav } from './RightNav';

import styles from './documentNav.module.scss';

const isWhite = color => color === '#ffffff' || color === '#fff';

export type DocumentNavProps = {
  documentId: string,
  docTitle: string,
  docSlug: string,
  documentMenu: ReactElement,
  sharingMenu: ReactElement,
  editModeEnabled?: boolean,
  noChromePreview?: boolean,
  hasDraft?: boolean,
  isOwner: boolean,
  canEdit?: boolean,
  onSave?: () => void,
  onDiscard?: () => void,
  saveState: SaveState,
  toggleEditMode?: (props?: ToggleEditModeProps) => void,
  userId?: string,
  documentAuthor: User,
  fallbackColors?: CSSProperties,
}

export const DocumentNav = ({
  documentId,
  documentMenu,
  editModeEnabled,
  toggleEditMode,
  docTitle,
  docSlug,
  saveState,
  userId,
  noChromePreview,
  hasDraft,
  onSave,
  onDiscard,
  isOwner,
  documentAuthor,
  sharingMenu,
  canEdit,
  fallbackColors,
}: DocumentNavProps) => {
  const leftNavRef = useRef();
  const middleNavRef = useRef();
  const rightNavRef = useRef();
  const [ openUserGroupsModal, setOpenUserGroupsModal ] = useState(false);
  const isMobile = useMobile();

  const { theme } = useContext(ThemesContext);
  const vars = theme.vars;
  const utmParams =  useMemo(() => utm.parse({ source: 'grid-doc', medium: 'referral', grid_doc: documentId }), [ documentId ]);

  const themeColors = useMemo(() => {
    // basic edit mode colors
    const colors = {
      '--nav-background': 'hsla(0 0% 98% / 0.2)', // #fafafa
      '--nav-button-fill': '#ffffff',
      '--nav-button-stroke': '#5900ff',
      '--text-color': '#282b3e',
    };
    if (!editModeEnabled && !isWhite(vars['--background-color'])) {
      Object.assign(colors, {
        '--nav-background': `hsla(${vars['--background-color-hsl']}, 0.5)`,
        '--nav-button-fill': vars['--background-color'],
        '--nav-button-stroke': vars['--text-color'],
        '--text-color': vars['--text-color'],
        '--nav-user-background': vars['--color-90'],
      });
    }
    if (isMobile) {
      Object.assign(colors, {
        '--nav-background': 'white',
        '--nav-button-stroke': '#5900ff',
        '--nav-button-fill': 'white',
        '--text-color': 'black',
      });
    }
    return colors;
  }, [ vars, isMobile, editModeEnabled ]) as CSSProperties;

  return (
    <div
      className={csx(
        styles.documentNav,
        editModeEnabled && styles.editmode,
        canEdit && styles.canEdit,
        isMobile && styles.isMobile,
      )}
      data-testid="document-nav"
      style={{ ...themeColors, ...fallbackColors }}
      >
      <div className={csx(styles.navWrap, styles.wrapLeft)}>
        <LeftNav
          utmParams={utmParams}
          documentMenu={documentMenu}
          editModeEnabled={editModeEnabled}
          docId={documentId}
          documentOwnerId={documentAuthor.id}
          docTitle={docTitle}
          hasDraft={hasDraft}
          leftNavRef={leftNavRef}
          canEdit={canEdit}
          isOwner={isOwner}
          noChromePreview={noChromePreview}
          userId={userId}
          />
      </div>
      {!isMobile && !editModeEnabled && canEdit && (
        <div className={csx(styles.navWrap, styles.wrapMiddle)}>
          <MiddleNav
            middleNavRef={middleNavRef}
            />
        </div>
      )}
      <div className={csx(styles.navWrap, styles.wrapRight)}>
        <RightNav
          documentId={documentId}
          documentAuthor={documentAuthor}
          userId={userId}
          utmParams={utmParams}
          sharingMenu={sharingMenu}
          rightNavRef={rightNavRef}
          toggleEditMode={toggleEditMode}
          editModeEnabled={editModeEnabled}
          isOwner={isOwner}
          canEdit={canEdit}
          docId={documentId}
          docSlug={docSlug}
          documentOwnerId={documentAuthor.id}
          />
      </div>
      {canEdit && !isMobile && editModeEnabled && (
        <SaveStatus
          hasDraft={hasDraft}
          saveState={saveState}
          onSave={onSave}
          onDiscard={onDiscard}
          />
      )}
      {userId && (<UserGroups currentUserId={userId} openModal={openUserGroupsModal} setOpenModal={setOpenUserGroupsModal} />)}
    </div>
  );
};
