export const SAVEPENDING = 'PEND'; // saving is pending...
export const SAVEWAIT = 'WAIT'; // saving is being done
export const SAVEDONE = 'DONE'; // all changes are saved
export const SAVEFAIL = 'FAIL'; // error
export const SAVESTALE = 'SAVESTALE'; // server rejects saving an old version
export const PUBLISHWAIT = 'PUBLISHING';
export const PUBLISHDONE = 'PUBLISHED';
export const DISCARDWAIT = 'DISCARDING';
export const DISCARDDONE = 'DISCARDED';

export type SaveState = typeof SAVEDONE | typeof SAVEWAIT | typeof SAVEFAIL | typeof SAVEPENDING | typeof SAVESTALE |
  typeof PUBLISHDONE | typeof PUBLISHWAIT | typeof DISCARDDONE | typeof DISCARDWAIT;
