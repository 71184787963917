import { createContext } from 'react';

type MarginManagerContextProps = {
  /**
     * @param id - the identifier of the component requesting space
     * @param margin - the amount of space needed in pixels
     * @param onDone - invoked once the space has been allocated
     */
  requestMargin: (id: string, margin: number, onDone?: () => void) => void,
};

export const MarginManagerContext = createContext<MarginManagerContextProps>({
  requestMargin: () => {},
});

