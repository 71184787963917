import { ReactElement } from 'react';
import csx from 'classnames';

import { DraftNotice } from '@/components/Document/DraftNotice';
import { PreviewChromeless } from '@/components/Document/PreviewChromeless';
import { HomeLogo } from '@/components/Navigation/HomeLogo';
import { UNDO_ACTION_PORTAL_ID } from '@/constants';
import { isMobile } from '@/utils';

import { DocumentTitle } from './DocumentTitle';

import styles from './documentNav.module.scss';

type LeftNavProps = {
  leftNavRef: any,
  docTitle: string,
  docId: string,
  documentOwnerId: string,
  documentMenu: ReactElement,
  utmParams?: string | URLSearchParams,
  editModeEnabled?: boolean,
  noChromePreview?: boolean,
  hasDraft?: boolean,
  canEdit?: boolean,
  style?: {},
  isOwner: boolean,
  userId?: string,
}

export const LeftNav = ({
  leftNavRef,
  docTitle,
  docId,
  documentOwnerId,
  documentMenu,
  utmParams,
  editModeEnabled,
  noChromePreview,
  hasDraft,
  style,
  canEdit,
  isOwner,
  userId,
}: LeftNavProps) => {
  const isEditAllowed = canEdit && !isMobile();
  return (
    <div className={csx(styles.navbar, styles.left)} style={style} ref={leftNavRef}>
      <HomeLogo utmParams={utmParams} size={32} />
      {docTitle !== undefined && (
        <DocumentTitle
          title={docTitle || ''}
          canEdit={canEdit}
          docId={docId}
          documentOwnerId={documentOwnerId}
          isOwner={isOwner}
          editModeEnabled={editModeEnabled}
          />
      )}
      <div className={styles.documentMenu}>
        {userId && documentMenu}
      </div>
      <div id={UNDO_ACTION_PORTAL_ID} className={styles.undoActionPortal} />
      {editModeEnabled && isEditAllowed && hasDraft && (
        <DraftNotice />
      )}
      {isEditAllowed && editModeEnabled && noChromePreview && (
        <PreviewChromeless />
      )}
    </div>
  );
};
