import { LegacyRef, useRef } from 'react';

import { DISCARDDONE, DISCARDWAIT, PUBLISHDONE, PUBLISHWAIT, SAVEDONE, SAVEFAIL, SAVEPENDING, SAVESTALE, type SaveState, SAVEWAIT } from '@/components/Document/states';

import styles from './saveStatus.module.scss';

type SaveStatusProps = {
  saveState: SaveState,
  hasDraft?: boolean,
  onSave?: () => void,
  onDiscard?: () => void,
}

export const SaveStatus = ({ saveState, hasDraft, onSave, onDiscard }: SaveStatusProps) => {
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const ref = useRef<HTMLDivElement>();
  const isUnpublishedDraft = (hasDraft && saveState === SAVEDONE) || (saveState === PUBLISHWAIT || saveState === DISCARDWAIT);

  const status = useRef<{message: string, isUnpublishedDraft: boolean}>({
    message: getMessage(),
    isUnpublishedDraft,
  });

  function getMessage (): string {
    switch (saveState) {
      case SAVEWAIT: return `Saving ${hasDraft ? 'draft' : 'changes'}...`;
      case SAVEPENDING: return '';
      case SAVEDONE: return `${hasDraft ? 'Draft' : 'Project'} saved!`;
      case SAVEFAIL: return 'Could not save, retrying...';
      case SAVESTALE: return 'Could not save, server has newer version';
      case PUBLISHDONE: return 'Success! Your changes are now public.';
      case DISCARDDONE: return 'Changes undone.';
      case PUBLISHWAIT:
      case DISCARDWAIT: return 'Draft saved!';
    }
  }

  function showStatus (status: { message?: string, isUnpublishedDraft?: boolean }) {
    if (status.message && ref.current && status.message !== ref.current.innerText) {
      // Apply styling to show
      ref.current.style.opacity = '1';
      ref.current.style.transition = 'none';
      ref.current.style.pointerEvents = 'auto';
      // Clear and set timeout
      timer.current && clearTimeout(timer.current);
      if (!status.isUnpublishedDraft) {
        timer.current = setTimeout(() => {
          if (!hasDraft && ref.current) {
            // Apply styling to hide
            ref.current.style.opacity = '0';
            ref.current.style.transition = 'opacity 600ms';
            ref.current.style.pointerEvents = 'none';
          }
        }, 3000);
      }
    }
  }

  status.current = {
    message: getMessage(),
    isUnpublishedDraft,
  };

  showStatus(status.current);

  return (
    <div className={styles.saveStatus} ref={ref as LegacyRef<HTMLDivElement>} data-testid="save-status">
      <div className={styles.message}>
        <span>{status.current.message}</span>
        {status.current.isUnpublishedDraft &&
          <>
            <button type="button" onClick={onSave}>Publish</button>
            <span> or</span>
            <button type="button" onClick={onDiscard}>Discard</button>
          </>}
      </div>
    </div>
  );
};
