import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import csx from 'classnames';

import { getElementTop, getViewport } from '@grid-is/browser-utils';
import { OutsideClickHandler } from '@grid-is/outside-click-handler';

import { ActionType, EventStream } from '@/editor/EditorEventStream';
import { AnimateHeight } from '@/grid-ui/AnimateHeight';
import { IconButton } from '@/grid-ui/IconButton';
import { useDeps } from '@/bootstrapping/dependencies';
import { SPREADSHEET_RIBBON_HEIGHT } from '@/constants';

import { FlagToggler } from '../FlagToggler';
import { ActionButton } from './ActionButton';

import styles from './documentActions.module.scss';

// Minimum padding between panels in pixels.
const MIN_PADDING_BETWEEN_PANELS = 16;
const HEADER_NAV_HEIGHT = 64;

const getTopPos = () => Math.max(HEADER_NAV_HEIGHT, getElementTop('doc-wrap')) + 'px';

export const portals = {
  ELEMENTS: 'doc-actions-portal-elements',
  LAYOUT: 'doc-actions-portal-layout',
  SCENARIOS: 'doc-actions-portal-scenarios',
  HELP: 'doc-actions-portal-help',
};

type DocumentActionsProps = {
  mode?: 'edit' | 'view',
  canViewStatistics?: boolean,
  hasAlteredState?: boolean,
  onDocumentDesignClick?: () => void,
  onFullscreenClick?: () => void,
  fallbackColors?: CSSProperties,
}

export const DocumentActions = ({ mode, hasAlteredState, canViewStatistics, onDocumentDesignClick = () => {}, onFullscreenClick = () => {}, fallbackColors }: DocumentActionsProps) => {
  const { userEvents } = useDeps();
  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [ showAction, setShowAction ] = useState<ActionType | undefined>();
  const [ hideLabels, setHideLabels ] = useState(false);
  const [ transformOrigin, setTransformOrigin ] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [ documentActionsEnd, setDocumentActionsEnd ] = useState(0);

  const closeDocumentActions = useCallback(() => {
    if (showAction) {
      setShowAction(undefined);
      EventStream.emit(EventStream.DOCUMENT_ACTION_CLOSED);
    }
  }, [ showAction ]);

  function openFormBuilder () {
    EventStream.emit(EventStream.OPEN_FORM_BUILDER, { addedFrom: 'Document Actions' });
  }

  function openStatistics () {
    EventStream.emit(EventStream.DOCUMENT_STATISTICS_CLICKED);
  }

  function openScenarios (action: ActionType) {
    EventStream.emit(EventStream.SCENARIOS_OPENED);
    handleAction(action);
  }

  const openElementMenu = useCallback((isOpen?: Boolean) => {
    EventStream.emit(EventStream.ON_ELEMENT_MENU_TOGGLED, { isOpen: !!isOpen });
    handleAction('Elements');
  }, []);

  function openHelpCenter () {
    setShowAction('Help center');
  }

  function openFlagToggler () {
    EventStream.emit(EventStream.OPEN_FLAG_TOGGLER);
  }

  function handleAction (action: ActionType) {
    setShowAction(action);
  }

  function positionContainer (action: ActionType) {
    const target = document.getElementById(`action-button-${action.toLowerCase().replace(/ /g, '-')}`);
    const rect = target?.getBoundingClientRect();
    // Set the transform origin to the top left corner of the action button that was clicked.
    setTransformOrigin({ x: rect?.left || 0, y: rect?.top || 0 });
  }

  function onActionButtonClick (action: ActionType) {
    userEvents.documentActionClicked(action);
    EventStream.emit(EventStream.DOCUMENT_ACTION_CLOSED);
    positionContainer(action);
    if (action === showAction) {
      return closeDocumentActions();
    }
    switch (action) {
      case 'Forms': return openFormBuilder();
      case 'Design': return onDocumentDesignClick();
      case 'Elements': return openElementMenu(action === showAction);
      case 'Statistics': return openStatistics();
      case 'Full screen': return onFullscreenClick();
      case 'Scenarios': return openScenarios(action);
      case 'Help center': return openHelpCenter();
      case 'Feature flags': return openFlagToggler();
      default: return handleAction(action);
    }
  }

  useEffect(() => {
    EventStream.on(EventStream.ON_OPEN_ELEMENTS, openElementMenu);
    return () => {
      EventStream.off(EventStream.ON_OPEN_ELEMENTS, openElementMenu);
    };
  }, [ openElementMenu ]);

  // TODO: Figure out why this doesn't work on the first render.
  const checkForOverlap = useCallback(rect => {
    const { left: gridDocBodyStart } = rect;
    // If the document actions are overlapping the grid doc body, hide the labels, 16px is minimum padding between the two.
    if (gridDocBodyStart < documentActionsEnd + MIN_PADDING_BETWEEN_PANELS) {
      setHideLabels(true);
    }
    else {
      setHideLabels(false);
    }
  }, [ documentActionsEnd ]);

  useEffect(() => {
    if (ref.current) {
      setDocumentActionsEnd(ref.current.getBoundingClientRect().right);
      ref.current.style.top = getTopPos();
    }
  }, []);

  useEffect(() => {
    function onEditorWidthDidResize ({ rect }: {rect: DOMRect}) {
      if (documentActionsEnd > 0) {
        // We need to ensure we have calculated the document actions end before checking for overlap.
        // otherwise calculations on initial render will be incorrect as the document actions
        // will already be hidden when we poll for changes
        checkForOverlap(rect);
      }
    }

    EventStream.on(EventStream.EDITOR_WIDTH_DID_RESIZE, onEditorWidthDidResize);
    return () => {
      EventStream.off(EventStream.EDITOR_WIDTH_DID_RESIZE, onEditorWidthDidResize);
    };
  }, [ checkForOverlap, documentActionsEnd ]);

  useEffect(() => {
    EventStream.on(EventStream.CLOSE_DOCUMENT_ACTIONS, closeDocumentActions);
    EventStream.on(EventStream.OPEN_HELP_CENTER, openHelpCenter);
    return () => {
      EventStream.off(EventStream.CLOSE_DOCUMENT_ACTIONS, closeDocumentActions);
      EventStream.off(EventStream.OPEN_HELP_CENTER, openHelpCenter);
    };
  }, [ closeDocumentActions ]);

  useEffect(() => {
    function handleKeyDown (event: KeyboardEvent) {
      if (showAction && event.key === 'Escape') {
        closeDocumentActions();
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [ showAction, closeDocumentActions ]);

  // Viewport height - (top of doc actions + doc actions header + bottom padding)
  const maxContentHeight = getViewport().height - ((contentRef.current?.getBoundingClientRect().top || 0) + SPREADSHEET_RIBBON_HEIGHT + MIN_PADDING_BETWEEN_PANELS);

  // Hide labels if there is no space for them, or if an action is open.
  const hideLabel = !!showAction || hideLabels;

  const renderDocAction = (
    <>
      <div
        className={styles.documentActions}
        style={{ top: getTopPos(), ...fallbackColors }}
        ref={ref}
        >
        <div className={styles.menuItems}>
          {mode === 'edit' && (
            <>
              <ActionButton label="Elements" icon="plus-circle" iconVariant="solid" isSelected={showAction === 'Elements'} hideLabel={hideLabel} onClick={() => onActionButtonClick('Elements')} primary={!showAction} />
              <ActionButton label="Design" icon="paint-roller" isSelected={showAction === 'Design'} hideLabel={hideLabel} onClick={() => onActionButtonClick('Design')} />
              <ActionButton label="Layout" icon="grid-view" isSelected={showAction === 'Layout'} hideLabel={hideLabel} onClick={() => onActionButtonClick('Layout')} />
              <ActionButton label="Forms" icon="form" isSelected={showAction === 'Forms'} hideLabel={hideLabel} onClick={() => onActionButtonClick('Forms')} />
              <div className={styles.divider} />
            </>)}

          <ActionButton label="Scenarios" icon={hasAlteredState ? 'document-badge' : 'file'} isSelected={showAction === 'Scenarios'} hideLabel={hideLabel} onClick={() => onActionButtonClick('Scenarios')} useTheme={mode === 'view'} />
          {canViewStatistics && <ActionButton label="Statistics" icon="document-statistics" isSelected={showAction === 'Statistics'} hideLabel={hideLabel} onClick={() => onActionButtonClick('Statistics')} useTheme={mode === 'view'} />}
          {mode === 'view' && (
            <ActionButton label="Full screen" icon="resize" isSelected={showAction === 'Full screen'} hideLabel={hideLabel} onClick={() => onActionButtonClick('Full screen')} useTheme />

          )}
          <div className={styles.divider} />
          <ActionButton label="Help center" icon="question-mark" isSelected={showAction === 'Help center'} hideLabel={hideLabel} onClick={() => onActionButtonClick('Help center')} useTheme={mode === 'view'} />
          <FlagToggler location="doc-actions">
            <ActionButton label="Feature flags" icon="flag" isSelected={showAction === 'Feature flags'} hideLabel={hideLabel} onClick={() => onActionButtonClick('Feature flags')} useTheme={mode === 'view'} />
          </FlagToggler>
        </div>
      </div>
      <OutsideClickHandler
        id="document-action-panel"
        testId="document-action-panel"
        className={csx(styles.documentAction, showAction && styles.show)}
        style={{
          ...(mode === 'edit' && { top: getTopPos() }),
          transformOrigin: `-32px ${transformOrigin.y - 80}px`,
        }}
        onClickOutside={closeDocumentActions}
        >
        <div className={styles.header} data-testid="document-actions-heading">
          <span>{showAction}</span>
          <IconButton iconName="window-close" ariaLabel="close" buttonSize="small" buttonType="tertiary" onClick={closeDocumentActions} />
        </div>
        <AnimateHeight className={csx(styles.action, showAction === 'Help center' && styles.noPadding)} maxHeight={maxContentHeight} ref={contentRef}>
          <div className={csx(styles.content, showAction === 'Elements' && styles.show)} id={portals.ELEMENTS} />
          <div className={csx(styles.content, showAction === 'Layout' && styles.show)} id={portals.LAYOUT} />
          <div className={csx(styles.content, showAction === 'Scenarios' && styles.show)} id={portals.SCENARIOS} />
          <div className={csx(styles.content, showAction === 'Help center' && styles.show)} id={portals.HELP} />
        </AnimateHeight>
      </OutsideClickHandler>
    </>
  );

  return renderDocAction;
};
