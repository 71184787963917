import { memo, ReactElement } from 'react';
import csx from 'classnames';

import { User } from '@/api/user';
import { UserMenu } from '@/components/UserMenu/UserMenu';
import { isMobile as useMobile } from '@/utils';
import { useAuth } from '@/utils/auth';

import { ToggleEditModeProps } from '../DocumentView';
import { CollaboratorsList } from './CollaboratorsList';
import { DevicePreviewButton } from './DevicePreviewButton/DevicePreviewButton';
import { EditToggle } from './EditToggle';

import styles from './documentNav.module.scss';

type RightNavProps = {
  rightNavRef: any,
  sharingMenu: ReactElement,
  documentId: string,
  utmParams?: string | URLSearchParams,
  documentAuthor?: User,
  userId?: string,
  style?: {},
  canEdit?: boolean,
  isOwner?: boolean,
  toggleEditMode?: (props?: ToggleEditModeProps) => void,
  editModeEnabled?: boolean,
  docId?: string,
  docSlug?: string,
  documentOwnerId?: string,
}

export const RightNav = memo(({
  rightNavRef,
  sharingMenu,
  documentId,
  utmParams,
  documentAuthor,
  userId,
  style,
  toggleEditMode,
  editModeEnabled,
  canEdit,
  docId,
  docSlug,
  documentOwnerId,
  isOwner,
}: RightNavProps) => {
  const { isLoggedIn } = useAuth();
  const isMobile = useMobile();
  const isEditAllowed = canEdit && !isMobile;

  return (
    <div className={csx(styles.navbar, styles.right)} style={style} ref={rightNavRef}>
      {userId && <CollaboratorsList documentId={documentId} documentAuthor={documentAuthor} userId={userId} />}
      {isEditAllowed && <DevicePreviewButton documentSlug={docSlug} />}
      {isEditAllowed && (
        <EditToggle
          docId={docId as string}
          documentOwnerId={documentOwnerId as string}
          isOwner={!!isOwner}
          editModeEnabled={editModeEnabled}
          toggleEditMode={toggleEditMode}
          />
      )}
      {sharingMenu}
      {!isLoggedIn &&
        <div className={csx(isLoggedIn && !isMobile && styles.accountActions)}>
          <UserMenu analyticsPageName="In Document" utmParams={utmParams} documentId={documentId} isLoggedIn={isLoggedIn} />
        </div>}
    </div>
  );
});
