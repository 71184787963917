import { memo } from 'react';
import csx from 'classnames';

import styles from './documentNav.module.scss';

 type MiddleNavProps = {
   middleNavRef: any,
   style?: {},
 }

export const MiddleNav = memo(({
  middleNavRef,
  style,
}: MiddleNavProps) => {
  return (
    <div
      className={csx(styles.navbar, styles.middle)}
      ref={middleNavRef}
      style={style}
      />
  );
});
