import { memo, useEffect, useState } from 'react';
import csx from 'classnames';
import { useRouter } from 'next/router';
import useSWR from 'swr';

import { query } from '@grid-is/browser-utils';
import { OutsideClickHandler } from '@grid-is/outside-click-handler';
import { tracking } from '@grid-is/tracking';

import { Collaborators, getCollaborators } from '@/api/collaborators';
import { User } from '@/api/user';
import { EventStream } from '@/editor/EditorEventStream';
import { Avatar } from '@/grid-ui/Avatar';
import { Tooltip } from '@/grid-ui/Tooltip';
import { Separator } from '@/components/Separator';

import styles from './collaboratorsList.module.scss';

type UserAvatar = {
  key: string,
  name: string,
  username: string,
  avatar_url?: string,
}

type CollaboratorsListProps = {
  documentId: string,
  documentAuthor?: User,
  userId: string,
}

function reduceCollaborators (collaboratorsPayload: Collaborators, documentAuthor?: User, userId?: string) {
  const users: UserAvatar[] = [];
  if (collaboratorsPayload?.email_domain_access) {
    const domain = collaboratorsPayload.email_domain_access.domain;
    users.push({ key: domain, name: `Anyone @${domain}`, username: domain, avatar_url: `https://logo.clearbit.com/${domain}?size=32` });
  }
  // Group users
  collaboratorsPayload?.group_access.forEach(groups => {
    groups?.group?.users?.forEach(({ id, name, username, avatar_url }) => {
      users.push({ key: id, name, username, avatar_url });
    });
  });
  // User Access users
  collaboratorsPayload?.user_access.forEach(userAccess => {
    const { id, name, username, avatar_url } = userAccess.user;
    users.push({ key: id, name, username, avatar_url });
  });
  // Email Access users
  collaboratorsPayload?.email_access.forEach(({ email }) => {
    users.push({ key: email, name: email, username: email });
  });
  // Return and filter out possible duplicates and your own collaborator avatar
  const keys = users.map(user => user.key);
  return users.filter(({ key }, index) => !keys.includes(key, index + 1) && key !== userId && key !== documentAuthor?.id);
}

async function fetchCollaborators (key: string, documentId: string, documentAuthor?: User, userId?: string) {
  const collaboratorsPayload = await getCollaborators(documentId);
  return reduceCollaborators(collaboratorsPayload, documentAuthor, userId);
}

const DropdownListCollab = ({ users }) => (
  <div className={styles.dropdownContainer}>
    <div className={styles.dropdownWrapper}>
      <h1 className={styles.title}>More Collaborators</h1>
      <Separator />
      <ul>
        {users && users.map((user: UserAvatar) => {
          const { key, name, username, avatar_url } = user;
          return (
            <li
              key={'avatar' + key}
              >
              <Tooltip
                label={`${name} has access`}
                hideArrow
                gap={-12}
                >
                <div className={styles.userWrapper}>
                  <Avatar
                    size={32}
                    name={name}
                    username={username}
                    avatarUrl={avatar_url}
                    className={styles.withBorder}
                    />
                  <p className={styles.name}>
                    {username}
                  </p>
                </div>
              </Tooltip>
            </li>
          );
        })}
      </ul>
    </div>
  </div>
);

export const CollaboratorsList = memo(({ documentId, documentAuthor, userId }: CollaboratorsListProps) => {
  const { data: users = [], mutate: refreshCollaborators } = useSWR<UserAvatar[]>(
    [ 'api/collaborators', documentId, documentAuthor, userId ],
    fetchCollaborators,
    { errorRetryCount: 3, revalidateOnMount: true },
  );
  const router = useRouter();
  const { collaboratorAdded } = router.query;
  const [ showAllCollaborators, setShowAllCollaborators ] = useState(false);
  const [ collaboratorJustAdded, setCollaboratorJustAdded ] = useState<string>();

  useEffect(() => {
    if (typeof collaboratorAdded === 'string') {
      setTimeout(() => {
        setCollaboratorJustAdded(collaboratorAdded);
        const url = query.remove(router.asPath, 'collaboratorAdded');
        router.replace(url);
        setTimeout(() => {
          setCollaboratorJustAdded(undefined);
        }, 5000);
      }, 2000);
    }
  }, [ collaboratorAdded, router ]);

  useEffect(() => {
    EventStream.on(EventStream.COLLABORATORS_UPDATE, refreshCollaborators);
    return () => {
      EventStream.off(EventStream.COLLABORATORS_UPDATE, refreshCollaborators);
    };
  }, [ refreshCollaborators ]);

  const maxOfAvatars = 5;
  const renderedAvatars = users?.slice(0, maxOfAvatars);
  const numberOfHiddenCollaborators = Math.max((users?.length ?? 0) - maxOfAvatars, 0);
  const restAvatars = users?.slice(maxOfAvatars, users.length);
  if (!renderedAvatars.length) {
    return null;
  }
  return (
    <div
      className={csx(styles.collaborators, renderedAvatars.length === 0 && styles.hide)}
      data-testid="collaborators"
      style={{ width: `${38 + (38 * 0.7 * (renderedAvatars.length - (numberOfHiddenCollaborators !== 0 ? 0 : 1)))}px` }}
      >
      {numberOfHiddenCollaborators !== 0 && (
        <OutsideClickHandler
          onClickOutside={() => setShowAllCollaborators(false)}
          >
          <div className={csx(styles.collaborator, styles.numberOfHidden)}>
            <Tooltip
              label={`${numberOfHiddenCollaborators} more`}
              hideOnElementClick
              >
              <button
                type="button"
                className={csx(styles.collaboratorCounter, styles.withBorder)}
                onClick={() => {
                  setShowAllCollaborators(!showAllCollaborators);
                  tracking.logEvent('Collaborator List Expanded', { document_id: documentId, initiated_from: 'In Document' });
                }}
                >
                <span className={styles.counter}>+{numberOfHiddenCollaborators}</span>
              </button>
            </Tooltip>
            {showAllCollaborators && <DropdownListCollab users={restAvatars} />}
          </div>
        </OutsideClickHandler>
      )}
      {renderedAvatars && renderedAvatars.map((user, index) => {
        const { key, name, username, avatar_url } = user;
        return (
          <div
            className={styles.collaborator}
            key={key}
            style={{ transform: `translateX(-${((index + 1) - (numberOfHiddenCollaborators !== 0 ? 0 : 1)) * 70}%)` }}
            >
            <Tooltip
              label={collaboratorJustAdded === key ? `${name} now has access!` : `${name} has access`}
              show={collaboratorJustAdded === key}
              >
              <Avatar
                size={32}
                name={name}
                username={username}
                avatarUrl={avatar_url}
                className={styles.withBorder}
                />
            </Tooltip>
          </div>
        );
      })}

    </div>
  );
});
