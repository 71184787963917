// XXX Look and feel - use new colors
export const defaultColors = {
  border: '#b2b2b2',
  cell: '#fff',
};

export const SHEET_HEADER_GUIDING_LINE_PORTAL_ID = 'sheet-header-guiding-line-portal';
export const NATIVE_SHEET_MENU_PORTAL_ID = 'native-sheet-menu-portal';
export const FORMULA_BAR_PORTAL_ID = 'formula-bar-portal';

export const defaultFonts = {
  'excel': 'calibri',
  'google-sheets': 'calibri', // See https://app.clickup.com/t/2559602/CLIENT-4365 for context
  'native': 'nunito',
  'unknown': 'nunito',
};

export const defaultStyles = {
  'font-size': 11,
  'font-color': '#000',
  'italic': false,
  'bold': false,
  'underline': false,
};

export const MIN_ROWS = 100;
export const MIN_COLS = 50;

export const WORKBOOK_EDITOR_ID = 'workbook-editor-id';

export const EMU_PER_PX = 9525;
