import { useContext, useMemo } from 'react';
import csx from 'classnames';

import { Icon, IconTypes } from '@grid-is/icon';

import { ThemesContext } from '@/grid/ThemesContext';
import { Tooltip } from '@/grid-ui/Tooltip';

import styles from './actionButton.module.scss';

type ActionButtonType = {
  icon: IconTypes,
  iconVariant?: 'solid' | 'outline' | 'fullColor',
  label: string,
  hideLabel?: boolean,
  isSelected?: boolean,
  onClick: () => void,
  useTheme?: boolean,
  primary?: boolean,
  disabled?: boolean,
  disabledTooltip?: string,
}

function toID (label: string) {
  return `action-button-${label.toLowerCase().replace(/ /g, '-')}`;
}

export const ActionButton = ({ icon, iconVariant, label, hideLabel, isSelected, onClick, useTheme, primary, disabled, disabledTooltip }: ActionButtonType) => {
  const { theme } = useContext(ThemesContext);
  const vars = theme.vars;

  const themeStyles: any = useMemo(() => ({
    '--text-color': vars['--text-color'],
  }), [ vars ]);

  const id = toID(label);
  return (
    <Tooltip label={disabledTooltip} disabled={!disabled} placement="bottom">
      <button
        type="button"
        id={id}
        data-obid={id}
        data-testid={id}
        className={csx(styles.actionButton, isSelected && styles.isSelected, hideLabel && styles.hideLabel, useTheme && styles.useTheme, primary && styles.primary)}
        style={useTheme ? themeStyles : undefined}
        onClick={onClick}
        disabled={disabled}
        >
        <Tooltip disabled={!hideLabel} label={label} placement="right" gap={8} delay={0} hideOnElementClick>
          <div className={styles.icon}>
            <Icon name={icon} size={24} variant={iconVariant} />
          </div>
        </Tooltip>
        <span>{label}</span>
      </button>
    </Tooltip>
  );
};
