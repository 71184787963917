import FontFaceObserver from 'fontfaceobserver';

export function loadFonts (fonts: (string | undefined)[]) {
  return Promise.all(
    fonts
      .filter(Boolean)
      .map(font => {
        const fontFace = new FontFaceObserver(font);
        return fontFace.load();
      }),
  ).catch(e => {
    console.warn('Failed to load font:', e);
  });
}
