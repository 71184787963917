import { format } from 'numfmt';

import { DATE_FORMAT } from '@/constants';
import { ensureDate } from '@/utils';

type Props = { time: string };
export function DateTime ({ time }: Props) {
  return (
    <time dateTime={time}>
      {format(`${DATE_FORMAT} at HH:mm`, ensureDate(time))}
    </time>
  );
}
