import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import csx from 'classnames';

import { getIsIntegrationTests } from '@grid-is/browser-utils';
import { Icon } from '@grid-is/icon';
import { tracking } from '@grid-is/tracking';

import { EventStream } from '@/editor/EditorEventStream';
import { Button } from '@/grid-ui/Button';
import { Input } from '@/grid-ui/Input';
import { Toast } from '@/grid-ui/Toast';
import { portals } from '@/components/DocumentActions/DocumentActions';
import { ContactSupportModal } from '@/components/DocumentActionsOld/HelpActions/ContactSupportModal';
import { docs, getContextSpecificHelp, HelpDocsTitle, quickHelp } from '@/components/DocumentActionsOld/HelpActions/helpDocs';
import { SkeletonLoader } from '@/components/SkeletonLoader';
import { executionDelay } from '@/utils/executionDelay';

import styles from './helpCenter.module.scss';

type THelpPopOverActions = {
  documentId?: string,
  isOwner?: boolean,
  getFocusElement?: () => (string | undefined),
}

export const HelpCenter = ({ documentId, isOwner, getFocusElement = () => undefined }: THelpPopOverActions) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [ open, setOpen ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ selected, setSelected ] = useState<HelpDocsTitle>();
  const [ transitioning, setTransitioning ] = useState(false);
  const [ showContactSupport, setShowContactSupport ] = useState(false);
  const [ contextSpecificHelp, setContextSpecificHelp ] = useState<HelpDocsTitle | undefined>(undefined);
  const [ requestSubmit, setRequestSubmit ] = useState(false);
  const [ notification, setNotification ] = useState('');
  const [ search, setSearch ] = useState('');
  const showContextSpecificHelpInIntegrationTests = getIsIntegrationTests() && contextSpecificHelp;
  const url = selected ? `https://grid.is/embed/${docs[selected]}?width=full&scale_to_fit=true` : '';

  function openHelpCenter (props: { helpDoc?: HelpDocsTitle }) {
    const { helpDoc } = props;
    setContextSpecificHelp(helpDoc);
    setOpen(true);
  }

  function closeHelpCenter () {
    setSearch('');
    setSelected(undefined);
    setContextSpecificHelp(undefined);
    setOpen(false);
  }

  useEffect(() => {
    EventStream.on(EventStream.DOCUMENT_ACTION_CLOSED, closeHelpCenter);
    EventStream.on(EventStream.OPEN_HELP_CENTER, openHelpCenter);
    return () => {
      EventStream.off(EventStream.DOCUMENT_ACTION_CLOSED, closeHelpCenter);
      EventStream.on(EventStream.OPEN_HELP_CENTER, openHelpCenter);
    };
  }, []);

  function goBackToHome (initiatedFrom: 'back-button' | 'need-help-with-something-else?') {
    tracking.logEvent('Help Center Home Page Returned To', {
      return_initiated_from: initiatedFrom,
      help_guide_name_returned_from: selected,
      element_in_focus: getFocusElement() || 'none',
    });
    setSelected(undefined);
  }

  function setHelp (selection: HelpDocsTitle) {
    setTransitioning(true);
    setTimeout(() => {
      setSelected(selection);
      setLoading(true);
    }, 200);
    setTimeout(() => {
      setTransitioning(false);
    }, 400);
  }

  function onSearch (value) {
    setSearch(value);
  }

  function onSearchKeyDown (e) {
    if (e.key === 'Escape' && search !== '') {
      e.preventDefault();
      e.stopPropagation();
      setSearch('');
    }
  }

  function onContactSupport () {
    setShowContactSupport(true);
  }

  let links;
  const keys = Object.keys(docs);
  if (search.length > 1) {
    links = keys.filter(key => key.toLowerCase().includes(search.toLowerCase()));
  }
  else {
    links = keys;
  }

  useEffect(() => {
    if (search !== '') {
      executionDelay(() => {
        tracking.logEvent('Help Center Searched', {
          help_center_search_term: search,
          num_help_center_search_results: links.length,
        });
      }, 1000);
    }
  }, [ search, links.length ]);

  useEffect(() => {
    if (open) {
      const contextSpecificHelpDoc = contextSpecificHelp || getContextSpecificHelp(getFocusElement());
      tracking.logEvent('Help Accessed', {
        initiated_from: 'inDocument',
        element_in_focus: getFocusElement() || 'none',
        context_specific_help_guide_name: contextSpecificHelpDoc || 'none',
      });
      if (contextSpecificHelpDoc) {
        setHelp(contextSpecificHelpDoc);
      }
    }
  // NOTE: We don't want to run this effect when the focusElement changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ open, contextSpecificHelp ]);

  const cypressContent = (
    <div data-testId="context-specific-help-cypress">
      <div>{selected}</div>
      <div>{url}</div>
    </div>
  );

  const portalContainer = typeof document !== 'undefined' && document.getElementById(portals.HELP);

  if (portalContainer) {
    return (
      <>
        {documentId && isOwner && (
          <ContactSupportModal
            onRequestSubmit={() => setRequestSubmit(true)}
            open={showContactSupport}
            onClose={() => setShowContactSupport(false)}
            documentId={documentId}
            setNotification={message => setNotification(message)}
            />)}
        <Toast
          open={requestSubmit}
          duration={7000}
          onClose={() => setRequestSubmit(false)}
          onClick={() => setRequestSubmit(false)}
          message={<span>{notification}</span>}
          />
        {createPortal(
          showContextSpecificHelpInIntegrationTests
            ? (cypressContent)
            : (
              <div className={styles.helpCenter}>
                <div className={csx(styles.popover, selected && styles.wider)} ref={popoverRef}>
                  {!selected &&
                    <div className={csx(styles.main, transitioning && styles.swipeLeft)}>
                      <div className={styles.menu}>
                        <div className={styles.searchInput}>
                          <Input
                            name="Help search"
                            placeholder="Type to search for topic ..."
                            autoFocus
                            value={search}
                            onChange={onSearch}
                            onKeyDown={onSearchKeyDown}
                            icon={search ? <Icon name="window-close" size={16} title="Clear search" /> : undefined}
                            onIconClick={() => setSearch('')}
                            autoCorrect="off"
                            autoCapitalize="off"
                            autoComplete="off"
                            />
                        </div>
                        {!search
                          ? (
                            <>
                              <div className={styles.commonHelp}>
                                <p>Quick guides</p>
                                <ul>
                                  {quickHelp.map(key => (
                                    <li key={key}>
                                      <button
                                        type="button"
                                        aria-label={key}
                                        onClick={() => {
                                          tracking.logEvent('Help Center Link Clicked', {
                                            help_link_to_guide_initiated_from: 'Quick guides',
                                            help_guide_name: key,
                                          });
                                          setHelp(key as HelpDocsTitle);
                                        }}
                                        >
                                        <span>{key}</span>
                                        <Icon name="angle" size={16} direction="right" />
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                                <div className={styles.allHelpButton}>
                                  <Button
                                    buttonType="primary"
                                    onClick={() => {
                                      tracking.logEvent('Help Center Link Clicked', {
                                        help_link_to_guide_initiated_from: 'See all button',
                                        help_guide_name: 'All help',
                                        help_center_search_term: search,
                                      });
                                      setHelp('All help guides');
                                    }}
                                    >
                                    See all guides
                                  </Button>
                                </div>
                              </div>
                              <div className={styles.flex} />
                              {isOwner &&
                                <div className={styles.bottomButtons}>
                                  <Button buttonType="tertiary" onClick={onContactSupport}>Contact support</Button>
                                </div>}
                            </>
                          )
                          : (
                            <div className={styles.searchResults}>
                              {links.length > 0 && <p>Get help for ...</p>}
                              {links.length === 0 && <p className={styles.light}>We couldn't find any matches. Try browsing help guides.</p>}
                              <ul>
                                {links && links.map(key => (
                                  <li key={key}>
                                    <button
                                      type="button"
                                      aria-label={key}
                                      onClick={() => {
                                        tracking.logEvent('Help Center Link Clicked', {
                                          help_link_to_guide_initiated_from: 'Search',
                                          help_guide_name: key,
                                          help_center_search_term: search,
                                        });
                                        setHelp(key);
                                      }}
                                      >
                                      <span>{key}</span>
                                      <Icon name="angle" size={16} direction="right" />
                                    </button>
                                  </li>
                                ))}
                              </ul>
                              {links.length === 0 &&
                                <div className={styles.allHelpButton}>
                                  <Button
                                    buttonType="primary"
                                    onClick={() => {
                                      tracking.logEvent('Help Center Link Clicked', {
                                        help_link_to_guide_initiated_from: 'See all button',
                                        help_guide_name: 'All help',
                                        help_center_search_term: search,
                                      });
                                      setHelp('All help guides');
                                    }}
                                    >
                                    See all guides
                                  </Button>
                                </div>
                        }
                            </div>
                          )}
                      </div>
                    </div>}
                  {selected &&
                    <div className={styles.instruction}>
                      {selected &&
                        <div className={styles.iframe}>
                          <SkeletonLoader delay={0} isLoading={loading}>
                            <div className={styles.skeleton}>
                              <section style={{ width: '100%', height: '32px' }} />
                              <section style={{ width: '100%', height: '64px' }} />
                              <section style={{ width: '75%', height: '32px' }} />
                              <section style={{ width: '100%', height: '64px' }} />
                              <section style={{ width: '100%', height: '32px' }} />
                              <section style={{ width: '100%', height: '32px' }} />
                              <section style={{ width: '75%', height: '32px' }} />
                              <section style={{ width: '100%', height: '32px' }} />
                              <section style={{ width: '100%', height: '32px' }} />
                              <section style={{ width: '75%', height: '32px' }} />
                              <section style={{ width: '100%', height: '32px' }} />
                              <section style={{ width: '75%', height: '32px' }} />
                              <section style={{ width: '100%', height: '32px' }} />
                            </div>
                          </SkeletonLoader>
                          <iframe
                            src={url}
                            onLoad={() => setTimeout(() => {
                              setLoading(false);
                            }, 400)}
                            />
                        </div>}
                      <div className={styles.actions}>
                        <Button
                          onClick={() => goBackToHome('back-button')}
                          iconName="angle"
                          iconDirection="left"
                          buttonType="tertiary"
                          buttonSize="small"
                          >Back
                        </Button>
                        <Button
                          onClick={() => {
                            window.open(`https://grid.is/@grid/${docs[selected]}`);
                          }}
                          iconName="pop-out"
                          buttonType="tertiary"
                          buttonSize="small"
                          >Open in new tab
                        </Button>
                      </div>
                    </div>}
                </div>
              </div>)
          , portalContainer)}
      </>);
  }

  return null;
};
