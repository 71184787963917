import { FunctionComponent, LegacyRef, ReactNode, useEffect, useRef } from 'react';
import { useMeasure } from 'react-use';
import { useMachine } from '@xstate/react';
import csx from 'classnames';

import { Icon } from '@grid-is/icon';

import { accordionMachine } from './AccordionMachine';

import styles from './Accordion.module.scss';

export type AccordionProps = {
  disabled?: boolean,
  onToggle?: (newValue: boolean) => void,
  leftIcon?: ReactNode,
  rightIcon?: ReactNode,
  id: string,
  isReverse?: boolean,
  title: string,
  subTitle?: string,
  startExpanded?: boolean,
  lightTheme?: boolean,
  mountChildrenWhileCollapsed?: boolean,
  children: ReactNode,
  chevronSize?: number,
  className?: string,
  useMinPadding?: boolean,
  disableAnimation?: boolean,
}

const Accordion: FunctionComponent<AccordionProps> = ({
  disabled = false,
  onToggle = () => {},
  leftIcon,
  rightIcon,
  id,
  children,
  isReverse = false,
  title,
  subTitle,
  startExpanded = false,
  lightTheme = false,
  mountChildrenWhileCollapsed = false,
  chevronSize = 20,
  className,
  useMinPadding,
  disableAnimation,
}) => {
  const [ state, send ] = useMachine(accordionMachine, { context: { mountChildrenWhileCollapsed, hasAnimated: false, overflow: 'visible' } });
  const { hasAnimated, overflow } = state.context;
  const expanded = state.value === 'expanded' || state.value === 'expanding';
  const [ contentRef, { height: contentHeight } ] = useMeasure();
  const [ labelRef, { height: labelHeight } ] = useMeasure();
  const containerRef = useRef<HTMLDivElement>(null);

  const accordionHeight = expanded ? labelHeight + contentHeight + 16 : labelHeight;
  const expand = {
    height: accordionHeight > 0 ? `${accordionHeight}px` : 'initial',
    overflow,
  };

  useEffect(() => {
    if (startExpanded && !hasAnimated) {
      send('START_EXPANDED');
    }
  }, [ send, startExpanded, hasAnimated ]);

  function handleToggle () {
    if (!disabled) {
      onToggle(!expanded);
      send('TOGGLE');
    }
  }

  return (
    <div style={expand} className={csx(styles.accordion, expanded && styles.expanded, lightTheme && styles.lightTheme, disabled && styles.disabled, useMinPadding && styles.minPadding, ((startExpanded && !hasAnimated) || disableAnimation) && styles.disableAnimation, className)}>
      <div ref={containerRef} className={csx(styles.wrapper)} data-testid={`accordion-${id}`} data-obid={`accordion-${id}`} data-openstate={expanded}>
        <div
          ref={labelRef as LegacyRef<HTMLDivElement>}
          className={csx(styles.root, expanded && styles.expanded, subTitle && styles.withSubtitle)}
          onClick={handleToggle}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleToggle();
            }
          }}
          tabIndex={0}
          id={`accordion-root-${id}`}
          data-testid="accordion-root"
          >
          {!isReverse && <Icon name="angle" direction={expanded ? 'down' : 'right'} size={chevronSize} className={csx(styles.icon, disabled && styles.disabled)} />}
          {leftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}
          {subTitle
            ? (
              <div className={styles.titleGroup}>
                <h4 className={styles.title}>{title}</h4>
                <label className={styles.subTitle}>{subTitle}</label>
              </div>
            )
            : <div className={styles.title}>{title}</div>
          }
          {rightIcon && <div className={styles.rightIcon}>{rightIcon}</div>}
          {isReverse && <Icon name="angle" size={20} direction={expanded ? 'up' : 'down'} className={csx(styles.icon, styles.reversed, disabled && styles.disabled)} />}
        </div>
        <div ref={contentRef as LegacyRef<HTMLDivElement>} className={styles.content}>
          {(mountChildrenWhileCollapsed || state.value !== 'collapsed') && children}
        </div>
      </div>
    </div>
  );
};

export { Accordion };
