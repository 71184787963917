import EventEmitter from 'component-emitter';

import { sessionId } from '@/api/session';
import { changeNotificationClient } from '@/api/watching';

const COMMENT_CREATED = 'comment_created';
const COMMENT_DELETED = 'comment_deleted';
const COMMENT_RESOLVED = 'comment_resolved';
const DOCUMENT_UPDATED = 'document_updated';

class DocumentMonitor extends EventEmitter {
  constructor (client = changeNotificationClient) {
    super();
    this._watching = [];
    this.client = client;
    client.on('connect', this._restoreWatches);
    client.on(COMMENT_CREATED, data => this._relayEvent(COMMENT_CREATED, data));
    client.on(COMMENT_DELETED, data => this._relayEvent(COMMENT_DELETED, data));
    client.on(COMMENT_RESOLVED, data => this._relayEvent(COMMENT_RESOLVED, data));
    client.on(DOCUMENT_UPDATED, data => this._relayEvent(DOCUMENT_UPDATED, data));
  }

  isWatching (documentId) {
    return this._watching.includes(documentId);
  }

  watch (documentId) {
    if (!this.isWatching(documentId)) {
      this._watching.push(documentId);
      this.client.watchDocument(documentId);
    }
  }

  unwatch (documentId) {
    const index = this._watching.indexOf(documentId);
    if (index !== -1) {
      this._watching.splice(index, 1);
      this.client.unwatchDocument(documentId);
    }
  }

  unwatchAll () {
    const watchList = [ ...this._watching ];
    for (const documentId of watchList) {
      this.unwatch(documentId);
    }
  }

  _restoreWatches = () => {
    this._watching.forEach(documentId => {
      this.client.watchDocument(documentId);
    });
  };

  _relayEvent = (event, data) => {
    if (this.isWatching(data.document_id) && this._isFromOtherSession(data)) {
      this.emit(event, data);
    }
  };

  _isFromOtherSession = data => {
    return data.session_id !== sessionId;
  };
}

const documentMonitor = new DocumentMonitor();

export { COMMENT_CREATED, COMMENT_DELETED, COMMENT_RESOLVED, DOCUMENT_UPDATED, DocumentMonitor, documentMonitor };
