import { NotificationTypes } from '@/api/notifications';

const typeTrackingMap: Record<NotificationTypes, string> = {
  document_shared_with_you: 'Document Shared With You',
  document_shared_with_group: 'Document Shared With Group',
  document_shared_with_domain: 'Document Shared With Domain',
  document_milestone_reached: 'Document Milestone Reached',
  document_sharing_blocked: 'Document Sharing Blocked',
  commented_on_document: 'Commented On Your Document',
  replied_to_your_comment: 'Replied To Your Comment',
  replied_to_a_comment_on_your_document: 'Replied To A Comment On Your Document',
  you_were_added_to_group: 'You Were Added To Group',
  user_added_to_group: 'User Added To Group',
  email_invited_to_group: 'Email Invited To Group',
  document_liked: 'Document Liked',
  you_were_granted_edit_access: 'You Were Granted Edit Access',
  group_granted_edit_access: 'Group Granted Edit Access',
};

enum NotificationChannels {
  IN_APP = 'in_app',
  EMAIL = 'email',
  SLACK = 'slack',
}

const channelTrackingMap: Record<NotificationChannels, string> = {
  [NotificationChannels.IN_APP]: 'In-App',
  [NotificationChannels.EMAIL]: 'Email',
  [NotificationChannels.SLACK]: 'Slack',
};

export function populateDocumentViewSourceTracking (type: string, channel: string): {} | {
  view_source: string,
  view_source_notification_channel: 'Email' | 'In-App' | 'Slack',
  view_source_notification_type: string,
} {
  if (!(type in typeTrackingMap)) {
    return {};
  }
  if (!(channel in channelTrackingMap)) {
    return {};
  }
  return {
    view_source: 'Notification',
    view_source_notification_channel: channelTrackingMap[channel],
    view_source_notification_type: typeTrackingMap[type],
  };
}
