import React from 'react';

export type History = {
  undos: unknown[],
  redos: unknown[],
}

type HistoryValue = {
  history: History,
  updateHistory: (history: History) => void,
}

const Context = React.createContext<HistoryValue>({ history: { undos: [], redos: [] }, updateHistory: () => {} });

export const HistoryContextProvider = Context.Provider;
export const HistoryContext = Context;
