// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

export class Resizeable extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    elementType: PropTypes.string,
    onResize: PropTypes.func,
  };

  static defaultProps = {
    elementType: 'div',
  };

  componentDidMount () {
    if (typeof ResizeObserver !== 'undefined') {
      this.observer = new ResizeObserver(this.onResize);
      this.observer.observe(this.element);
    }
  }

  componentWillUnmount () {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  onResize = entries => {
    this.props.onResize(this.element, entries);
  };

  render () {
    // eslint-disable-next-line
    const { elementType, children, onResize, ...rprops } = this.props;
    rprops.ref = ref => {
      this.element = ref;
    };
    return React.createElement(elementType, rprops, children);
  }
}
