import { useState } from 'react';
import csx from 'classnames';

import { IconButton } from '@/grid-ui/IconButton';
import { Tooltip } from '@/grid-ui/Tooltip';

import styles from './draftNotice.module.scss';

type DraftNoticeProps = {
  isMobile?: boolean,
}

export const DraftNotice = ({ isMobile }: DraftNoticeProps) => {
  const [ showMobileBanner, setShowMobileBanner ] = useState(true);

  if (isMobile && showMobileBanner) {
    return (
      <div className={csx(styles.draftNotice, styles.mobile)}>
        <div className={styles.pill}>Draft</div>
        <p>You're viewing a draft. Switch to a computer to edit or discard your draft.</p>
        <IconButton iconName="window-close" buttonSize="medium" buttonType="grayScale" ariaLabel="close" onClick={() => setShowMobileBanner(false)} />
      </div>
    );
  }
  else if (!isMobile) {
    return (
      <div className={csx(styles.draftNotice, styles.desktop)} data-testid="draft-notice">
        <Tooltip label="Enable editing to publish or discard your draft." gap={4}>
          <div className={styles.pill}>Draft</div>
        </Tooltip>
      </div>
    );
  }
  else {
    return null;
  }
};
