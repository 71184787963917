import { useState } from 'react';
import csx from 'classnames';

import { Button } from '@/grid-ui/Button';

import styles from './tutorialPreviewMessage.module.scss';

type TutorialPreviewMessageProps = {
  onHidePreviewMessage: () => void,
}

export const TutorialPreviewMessage = ({ onHidePreviewMessage }: TutorialPreviewMessageProps) => {
  const [ fadeOut, setFadeOut ] = useState(false);

  function onGetStarted () {
    setFadeOut(true);
    setTimeout(() => {
      onHidePreviewMessage();
    }, 400);
  }

  return (
    <div className={csx(styles.tutorialPreviewMessage, fadeOut && styles.fadeOut)}>
      <div className={styles.content}>
        <h1>Hi 👋</h1>
        <p>Welcome to Calculator Studio 💜 Here you can create embeddable calculators, forms and other data visualization.</p>
        <p>Let's see how it works.</p>
        <Button onClick={onGetStarted} buttonType="primary" buttonSize="large" data-obid="ob-button">Get started</Button>
      </div>
    </div>
  );
};
