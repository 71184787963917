import { Icon } from '@grid-is/icon';

import { DevicePreviewTypes } from '@/editor/components/DevicePreviewToggle';
import { Tooltip } from '@/grid-ui/Tooltip';

import styles from './DevicePreviewButton.module.scss';

type DevicePreviewButtonProps = {
  documentSlug?: string,
};

export const DevicePreviewButton = ({ documentSlug }: DevicePreviewButtonProps) => {
  function openDevicePreview (mode: DevicePreviewTypes) {
    window.open(`/preview/${documentSlug}?mode=${mode}`, '_blank');
  }

  return (
    <div className={styles.devicePreviewButton}>
      <Tooltip label="Preview on mobile" placement="bottom" delay={0} gap={6}>
        <button type="button" className={styles.mode} onClick={() => openDevicePreview('mobile')}><Icon name="mobile" size={20} /></button>
      </Tooltip>
    </div>
  );
};
