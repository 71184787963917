import { useEffect, useRef, useState } from 'react';

import { getRunTimeConfigurations } from '@grid-is/browser-utils';
import { useMounted } from '@grid-is/custom-hooks';
import { Icon } from '@grid-is/icon';

import styles from './browserWarning.module.scss';

export const BrowserWarning = () => {
  const mounted = useMounted();
  const TIMEOUT = 3400;
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const [ showWarningText, setShowWarningText ] = useState(true);

  useEffect(() => {
    timer.current = setTimeout(() => {
      setShowWarningText(false);
    }, TIMEOUT);

    return () => {
      timer.current && clearTimeout(timer.current);
    };
  });
  if (!mounted) {
    return null;
  }

  const onHoverOrClick = () => {
    if (!showWarningText) {
      timer.current && clearTimeout(timer.current);
      setShowWarningText(true);
      timer.current = setTimeout(() => {
        setShowWarningText(false);
      }, TIMEOUT);
    }
  };

  if (getRunTimeConfigurations().browserWarning) {
    return (
      <div className={styles.browserWarning}>
        <button type="button" className={styles.warningButton} onClick={onHoverOrClick} onMouseEnter={onHoverOrClick}>
          <Icon name="exclamation-triangle" size={24} />
        </button>
        {showWarningText && <div className={styles.warningText}>Try switching to Google Chrome for fully-optimized editing.</div>}
      </div>
    );
  }
  return null;
};
