import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import csx from 'classnames';

import { getElementTop, getLoginUrl, getSignupUrl } from '@grid-is/browser-utils';
import { Icon } from '@grid-is/icon';
import { tracking } from '@grid-is/tracking';

import {
  deleteScenario,
  getScenarios,
  saveScenario,
  Scenario,
  updateScenarioAccess,
  ViewAccess,
} from '@/api/document';
import { EventStream } from '@/editor/EditorEventStream';
import { Avatar } from '@/grid-ui/Avatar';
import { Button } from '@/grid-ui/Button';
import { CopyToClipboard } from '@/grid-ui/CopyToClipboard';
import { IconButton } from '@/grid-ui/IconButton';
import { Input } from '@/grid-ui/Input';
import { Modal } from '@/grid-ui/Modal';
import { TextLink } from '@/grid-ui/TextLink';
import { Tooltip } from '@/grid-ui/Tooltip';
import { EDITOR_ID } from '@/constants';
import { isMobile } from '@/utils';
import { useAuth } from '@/utils/auth';

import { portals } from '../DocumentActions';

import styles from './Scenarios.module.scss';

function trackInputActionTaken (action, documentId) {
  tracking.logEvent('Your Input Panel Action Taken', { document_id: documentId, action });
}

export type ScenariosProps = {
  statefulUrl: string,
  onRestoreDefaults: () => void,
  documentId: string,
  viewAccess: ViewAccess,
  isOwner?: boolean,
  hasAlteredState: boolean,
  onLoadScenario: (scenario?: string) => void,
  canEdit?: boolean,
  viewersCanSaveScenarios?: boolean,
}

export function Scenarios (
  {
    statefulUrl,
    onRestoreDefaults,
    documentId,
    viewAccess,
    isOwner,
    hasAlteredState,
    onLoadScenario,
    canEdit,
    viewersCanSaveScenarios,
  }: ScenariosProps) {
  const { user } = useAuth();
  const [ scenarios, setScenarios ] = useState<Scenario[]>([]);
  const [ scenarioName, setScenarioName ] = useState('');
  const [ docState, setDocState ] = useState('');
  const [ selectedScenarioId, setSelectedScenarioId ] = useState('');
  const [ showSaveScenario, setShowSaveScenario ] = useState(false);
  const [ popoverOpen, setPopoverOpen ] = useState(false);
  const [ showMobileScenarios, setShowMobileScenarios ] = useState(false);
  const hasScenarios = scenarios.length > 0;

  const canSaveScenarios = viewersCanSaveScenarios || canEdit;

  const fetchScenarios = useCallback(() => {
    getScenarios(documentId).then(result => {
      setScenarios(result.scenarios ?? []);
    });
  }, [ documentId ]);

  useEffect(() => {
    fetchScenarios();
  }, [ fetchScenarios ]);

  function onClosePopover () {
    setPopoverOpen(false);
  }

  useEffect(() => {
    const onScenarioButtonClick = () => {
      setPopoverOpen(true);
      tracking.logEvent('Your Input Panel Accessed', { document_id: documentId });
    };

    EventStream.on(EventStream.SCENARIOS_OPENED, onScenarioButtonClick);
    EventStream.on(EventStream.DOCUMENT_ACTION_CLOSED, onClosePopover);

    return () => {
      EventStream.off(EventStream.SCENARIOS_OPENED, onScenarioButtonClick);
      EventStream.on(EventStream.DOCUMENT_ACTION_CLOSED, onClosePopover);
    };
  }, [ documentId ]);

  const numberOfScenarios = scenarios.length;
  const createPrefilledScenarioName = useCallback(() => {
    const post = numberOfScenarios + 1;
    return `Scenario ${post}`;
  }, [ numberOfScenarios ]);

  useEffect(() => {
    if (popoverOpen && statefulUrl) {
      const state = new URL(statefulUrl).searchParams.get('s');
      if (state) {
        setDocState(state);
      }
    }
  }, [ statefulUrl, popoverOpen ]);

  const storeScenario = useCallback(() => {
    let title = '';
    const prefilledScenarioName = createPrefilledScenarioName();
    if (!scenarioName) {
      title = prefilledScenarioName;
    }
    else {
      title = scenarioName;
    }
    if (docState) {
      saveScenario(documentId, title, docState).then(() => {
        fetchScenarios();
        tracking.logEvent('Scenario Saved', {
          scenario_is_renamed: prefilledScenarioName !== title,
          user_type: isOwner ? 'Author' : 'Authenticated Viewer',
          document_id: documentId,
        });
      });
    }
  }, [ docState, scenarioName, documentId, fetchScenarios, createPrefilledScenarioName, isOwner ]);

  const removeScenario = useCallback(scenarioId => {
    deleteScenario(documentId, scenarioId).then(() => {
      fetchScenarios();
    });
  }, [ documentId, fetchScenarios ]);

  const toggleScenarioAccess = useCallback((scenarioId, access) => {
    updateScenarioAccess(documentId, scenarioId, access).then(() => {
      fetchScenarios();
    });
  }, [ documentId, fetchScenarios ]);

  const changeScenarioName = useCallback(name => {
    setScenarioName(name);
  }, []);

  const onSaveScenario = () => {
    storeScenario();
    setShowSaveScenario(false);
    setDocState('');
  };

  const onResetYourInputsClick = () => {
    onRestoreDefaults();
    trackInputActionTaken('Reset your inputs', documentId);
    setSelectedScenarioId('');
    setShowSaveScenario(false);
    setDocState('');
  };

  const onCopyLinkToClipboardClick = () => {
    trackInputActionTaken('Copy link to this scenario', documentId);
    if (isOwner) {
      tracking.logEvent('Document Link Copied', {
        document_id: documentId,
        view_access: viewAccess,
        copy_link_initiated_from: 'Document Actions',
        document_input_state: 'With Input',
      });
    }
  };

  const onScenarioClick = (scenario: Scenario) => {
    setSelectedScenarioId(scenario.id);
    onLoadScenario(scenario.state);
    if (user) {
      tracking.logEvent('Scenario Viewed', {
        num_total_scenarios: scenarios.length,
        user_type: isOwner ? 'Author' : 'Authenticated Viewer',
        document_id: documentId,
      });
    }
  };

  const utmParams = {
    source: 'grid-doc',
    medium: 'referral',
    campaign: 'scenario',
    grid_doc: documentId,
  };

  function onMobileScenariosButtonClick () {
    setShowMobileScenarios(true);
    EventStream.emit(EventStream.SCENARIOS_OPENED);
  }

  function onMobileScenariosClose () {
    setShowMobileScenarios(false);
    onClosePopover();
  }

  const getWrapperStyles = () => {
    const docBody = typeof document !== 'undefined' ? document.getElementById(EDITOR_ID) : null;

    if (isMobile()) {
      return {
        // We only want to display max 5 scenarios on mobile before showing the scrollbar
        maxHeight: 380 + 'px',
      };
    }
    if (docBody) {
      const { height } = docBody.getBoundingClientRect();
      const gridDocBodyTop = getElementTop('grid-doc-body');
      const maxHeight = Math.min(height - gridDocBodyTop, 800);
      return {
        maxHeight: maxHeight + 'px',
      };
    }
    else {
      return {};
    }
  };

  const saveScenarioForm = (
    <div>
      {!showSaveScenario
        ? (
          <div className={csx(styles.scenarioButtonWrapper, hasScenarios && styles.hasScenarios)}>
            <Button
              iconName="plus"
              buttonType="tertiary"
              onClick={() => {
                setShowSaveScenario(true);
                setScenarioName(createPrefilledScenarioName());
              }}
              disabled={!docState}
              >
              Save this scenario
            </Button>
          </div>
        )
        : (
          <div className={styles.linkWrapper}>
            <form
              onSubmit={e => {
                e.preventDefault();
                onSaveScenario();
              }}
              className={styles.scenarioFormWrapper}
              >
              <Input
                name="scenarioName"
                testId="scenarioName"
                onChange={changeScenarioName}
                disabled={!docState}
                value={scenarioName}
                placeholder="Save scenario as"
                size="small"
                className={styles.scenarioInput}
                autoFocus
                autoComplete="off"
                />
              <Button
                iconName="plus"
                disabled={!docState}
                buttonType="primary"
                onClick={onSaveScenario}
                >
                Save
              </Button>
            </form>
          </div>
        )
    }
    </div>
  );

  function renderScenarioContent () {
    return (
      <section className={styles.wrapper} style={getWrapperStyles()}>
        <div className={styles.linkContainer}>
          <div>
            <div className={styles.linkWrapper}>
              <span>Reset all inputs</span>
              <Button
                iconName="restore-defaults"
                buttonSize="medium"
                onClick={onResetYourInputsClick}
                disabled={!hasAlteredState}
                >
                Reset
              </Button>
            </div>
            <div className={styles.linkWrapper}>
              <span>Copy link to this scenario</span>
              <CopyToClipboard
                disabled={!hasAlteredState}
                copiedText={statefulUrl}
                toastText="Link copied!"
                toastIcon="tick"
                onClick={onCopyLinkToClipboardClick}
                >
                <Button
                  data-testid="copy-stateful-url"
                  iconName="link"
                  >Copy
                </Button>
              </CopyToClipboard>
            </div>
            {user
              ? (canSaveScenarios && saveScenarioForm)
              : (
                <div className={styles.scenarioButtonWrapper}>
                  <div className={styles.loginSignupWrapper}>
                    <TextLink linkSize="small" href={getLoginUrl({}, utmParams)} className={styles.authenticateTextLink} isExternalLink>
                      Login
                    </TextLink> or {' '}
                    <TextLink linkSize="small" href={getSignupUrl({}, utmParams)} className={styles.authenticateTextLink} isExternalLink>
                      sign up
                    </TextLink> to save your scenario
                  </div>
                </div>)
            }
          </div>
        </div>
        {hasScenarios &&
          <div className={styles.scenarioWrapper}>
            <div className={styles.scenarioHeadingContainer}>
              <span className={styles.scenarioHeading}>Scenarios in this document</span>
              <Tooltip label="Everyone that has access to this document can view the saved scenarios">
                <Icon className={styles.tooltipIcon} name="help" size={12} />
              </Tooltip>
            </div>
            {scenarios.map(scenario => {
              return (
                <div key={scenario.id} className={styles.scenarioContainer}>
                  <button
                    type="button"
                    className={styles.scenarioButton}
                    onClick={() => onScenarioClick(scenario)}
                    >
                    <Avatar username={scenario.created_by.username} name={scenario.created_by.name} avatarUrl={scenario.created_by.avatar_url} size={24} className={styles.avatar} />
                    <div className={csx(styles.scenarioInstance, scenario.id === selectedScenarioId && styles.selected)} title={scenario.title}>
                      {scenario.title}
                    </div>
                  </button>
                  <div className={styles.scenarioAccessButton}>
                    {(canEdit || user?.id === scenario.created_by.id) &&
                      <IconButton
                        buttonType="grayScale"
                        buttonSize="small"
                        iconName={scenario.access === 'public' ? 'eye' : 'eye-hide'}
                        ariaLabel="Scenario Access"
                        onClick={() => {
                          toggleScenarioAccess(scenario.id, scenario.access === 'public' ? 'private' : 'public');
                        }}
                        />
                  }
                  </div>
                  <div className={styles.deleteScenarioButton}>
                    {(canEdit || user?.id === scenario.created_by.id) &&
                      <IconButton
                        buttonType="grayScale"
                        buttonSize="small"
                        iconName="trash"
                        ariaLabel="Delete Scenario"
                        onClick={() => {
                          removeScenario(scenario.id);
                        }}
                        />
                  }
                  </div>
                </div>
              );
            })}
          </div>
       }
      </section>
    );
  }

  const portalContainer = typeof document !== 'undefined' && document.getElementById(portals.SCENARIOS);
  if (portalContainer) {
    return (
      <>
        {createPortal(renderScenarioContent(), portalContainer)}
        <div className={csx(styles.mobileScenariosButton, (!hasAlteredState && !hasScenarios) && styles.hide)}>
          <IconButton iconName={hasAlteredState ? 'document-badge' : 'file'} ariaLabel="Scenarios" onClick={onMobileScenariosButtonClick} />
        </div>
        <Modal open={showMobileScenarios} header="Scenarios" onClose={onMobileScenariosClose}>{renderScenarioContent()}</Modal>
      </>
    );
  }

  return null;
}
