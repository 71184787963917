import { Model, Range, Reference, Sheet, Workbook } from '@grid-is/apiary';
import { WorkbookBody } from '@grid-is/apiary/lib/csf';
import { tracking } from '@grid-is/tracking';
import { uuid } from '@grid-is/uuid';
import { assert } from '@grid-is/validation';

import { nativeWorkbook, WorkbookInfo as WorkbookResponse } from '@/api/source';
import { ElementData } from '@/editor/types/elements/shared';
import { Doc } from '@/grid/Doc';
import { prepPastedCellData, prepWorkbookSavedata } from '@/grid/Doc/nativeSaveData';
import { UserEvents } from '@/instrumentation/UserEvents';

interface PartialCellData {
  v: string,
  f: string,
  isRC: boolean,
}

export type Row = PartialCellData[];
interface TableElementData extends ElementData {
  clipboardCells?: Row[] | null,
  shouldMerge?: boolean,
}

export interface TableElement {
  id: string,
  data: TableElementData,
}

/**
 * Constructs a reference to all the cells in a sheet provided.
 * @param sheet
 * @param workbookName
 */
function constructReferenceOfAllCellsInSheet (
  sheet: Sheet,
  workbookName: string,
): Reference {
  const [ width, height ] = sheet.getSize();
  const range = new Range({ top: 0, left: 0, right: Math.max(width - 1, 0), bottom: Math.max(height - 1, 0) });
  const reference = new Reference(range, { sheetName: sheet.name, workbookName: workbookName });
  return reference;
}

/**
 * Uploads a native workbook & waits until it's attached & loaded to the document
 * @param doc
 */
async function createAndAttachNativeWorkbook (doc: Doc, body: Pick<WorkbookBody, 'sheets' | 'styles'>): Promise<WorkbookResponse> {
  const res = await nativeWorkbook('Project sheet', {
    schema_version: '4.0',
    ...body,
  });
  await doc.attachSource(res.workbook);
  await doc.loadSource(res.id, true);
  await doc.reloadAllSources();
  return res.workbook;
}

function isSheetEmpty (sheet: Sheet): boolean {
  const [ numberOfCols ] = sheet.getSize();
  return numberOfCols === 0;
}

/**
 * creates a new sheet on the workbook & returns the sheet
 * @param wb
 */
function getOrCreateEmptySheet (wb: Workbook): Sheet {
  let sheet = wb.getSheets().slice(-1)[0];
  if (!sheet || !isSheetEmpty(sheet)) {
    wb.addSheet();
    sheet = wb.getSheets().slice(-1)[0];
  }
  return sheet;
}

/**
 * Given rows of cellData constructs a reference for each cell. starting at A1 & inserts the
 * cells into the sheet with the given sheetName provided
 * @param rows
 * @param wb
 * @param sheetName
 */
export function writeMultipleCellData (
  rows: Row[],
  wb: Workbook,
  sheetName: string,
) {
  rows?.forEach((cols, top) => {
    cols.forEach((clipboardCell, left) => {
      const range = new Range({ top, left });
      const cellData = prepPastedCellData(clipboardCell, range.toString());
      const reference = new Reference(range, { sheetName: sheetName });
      wb.writeCellData(reference, cellData);
    });
  });
}

/**
 * Accepts a native workbook & table element data containing tabular clipboard data. Inserts
 * the tabular data into an empty sheet in the project sheet. Returns the table element data
 * but removes the clipboardCells & populates the expr field with a references to all the cells
 * in the sheet containing the newly added data
 * @param sheet
 * @param nativeWorkbook
 * @param tableElement
 * @returns
 */
function insertTabularDataToGridSheet (sheet: Sheet, nativeWorkbook: Workbook, tableElement: TableElement) {
  if (tableElement.data.clipboardCells) {
    writeMultipleCellData(tableElement.data.clipboardCells, nativeWorkbook, sheet.name);
  }
  const ref = constructReferenceOfAllCellsInSheet(
    sheet,
    nativeWorkbook.name || '',
  );
  return {
    id: tableElement.id,
    data: {
      expr: `=${ref.toString()}`,
      clipboardCells: null,
      shouldMerge: true,
    },
  };
}

/**
 * returns true if workbook is of type "native" else false
 * @param wb
 */
export function isNativeWorkbook (wb: Workbook): boolean {
  return wb.type === 'native';
}

/**
 * Wrapper for inserting tabular data to a
 * Project sheet. The wrapper exists for the annoying case of
 * uploading new Project sheet in case there is no Project sheet attached already attached
 * to the document. This wrapper might no longer be required after Project sheets by default
 * is shipped. That is, if all GRID documents are required to have a Project sheet.
 */
export class UploadOrInsertTabularDataToGridSheets {
  uploader?: Promise<WorkbookResponse> | null;

  uploadNativeWorkbook = (doc: Doc, body: Pick<WorkbookBody, 'sheets' | 'styles'>) => {
    return new Promise<WorkbookResponse>(resolver => {
      createAndAttachNativeWorkbook(doc, body)
        .then(res => resolver(res));
    });
  };

  async addDataToNewGridSheet (doc: Doc, tableElements: TableElement[], isDocumentOwner: boolean): Promise<TableElement[]> {
    const model = new Model();
    const wb = model.addWorkbook({ id: uuid(), filename: 'Project sheet', sheets: [] });
    const updatedTableElements = tableElements.map(tableElement => {
      const sheetCountBefore = wb.getSheets().length;
      const sheet = getOrCreateEmptySheet(wb);
      if (wb.getSheets().length > sheetCountBefore) {
        tracking.logEvent('Sheet Added', {
          added_from: 'Paste to Document',
          no_of_grid_sheets_after_addition: wb.getSheets().length,
          workbook_id: wb.id,
          document_id: doc.id,
          sheet_id: sheet.name,
          document_owner_id: doc.creator.id,
          is_document_owner: isDocumentOwner,
        });
      }
      const updatedTableElement  = insertTabularDataToGridSheet(sheet, wb, tableElement);
      return updatedTableElement;
    });
    const body = prepWorkbookSavedata(wb, true);
    this.uploader = this.uploadNativeWorkbook(doc, body);
    const res = await this.uploader;
    if (res) {
      const hasOtherWorkbooksAttached = doc.model.getWorkbooks().length > 0;
      tracking.logEvent('GRID Workbook Added', {
        document_id: doc.id,
        workbook_id: res.id,
        has_other_workbooks_attached: hasOtherWorkbooksAttached,
        grid_workbook_added_from: 'Paste to Document',
        is_document_owner: isDocumentOwner,
        document_owner_id:  doc.creator.id,
      });
      doc.model.recalculate();
    }
    return updatedTableElements;
  }

  async updateExistingGridSheet (
    doc: Doc,
    tableElements: TableElement[],
    isDocumentOwner: boolean,
    userEvents: UserEvents,
  ): Promise<TableElement[]> {
    // so let's insert the tabular data into the existing Project sheet
    const wb = doc.model.getWorkbooks().find(isNativeWorkbook);
    if (!wb) {
      throw new Error('No Native Workbook attached to the document');
    }
    // Reset the model before updating the workbook
    doc.model.reset();
    const updatedTableElements = tableElements.map(tableElement => {
      const sheet = getOrCreateEmptySheet(wb);
      const updatedTableElement  = insertTabularDataToGridSheet(sheet, wb, tableElement);

      assert(doc.id != null, 'doc should have an id');
      userEvents.gridWorkbookUpdated(
        doc.id,
        doc.type,
        doc.creator.id,
        isDocumentOwner,
        wb.id || '',
        sheet.name,
        'Paste',
        'Paste to Document',
      );
      return updatedTableElement;
    });
    await doc.nativeWorkbookChange({
      workbookName: wb.name,
      workbookId: wb.id,
      lastWrite: doc.model.lastWrite,
      action: 'paste',
    });
    doc.model.recalculate();
    return updatedTableElements;
  }

  async insertTabularDataToGridSheets (
    doc: Doc,
    tables: TableElement[],
    isDocumentOwner: boolean,
    userEvents: UserEvents,
  ): Promise<TableElement[]> {
    if (this.uploader) {
      await this.uploader;
      this.uploader = null;
    }
    else if (!doc.model.getWorkbooks().some(isNativeWorkbook)) {
      return this.addDataToNewGridSheet(doc, tables, isDocumentOwner);
    }
    return this.updateExistingGridSheet(doc, tables, isDocumentOwner, userEvents);
  }
}
