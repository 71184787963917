import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from '@grid-is/icon';

import styles from './alert.module.scss';

const ERRORTYPES = {
  error: {
    label: 'Error:',
    icon: 'error-standard',
  },
  warning: {
    label: 'Warning:',
    icon: 'exclamation-triangle',
  },
  notice: {
    label: 'Notice:',
    icon: 'info-circle',
  },
};

export function Alert ({ type, label, body, className }) {
  const icon = ERRORTYPES[type].icon;
  const labelPrefix = ERRORTYPES[type].label;

  function getIcon () {
    return <Icon variant="solid" name={icon} size={22} />;
  }

  return (
    <div className={csx(styles.container, styles[type], className)}>
      <div className={styles.header}>
        {getIcon()}
        <span title={label} className={styles.label}>
          {labelPrefix}{' '}{label}
        </span>
      </div>
      <div className={styles.body}>
        {body}
      </div>
    </div>
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([ 'error', 'warning', 'notice' ]),
  label: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ]),
  body: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ]),
};
