import React from 'react';

import { BaseDocument } from '@/api/document';
import { getWorkbookDocuments, history, WorkbookUpdate } from '@/api/source';
import { Accordion } from '@/grid-ui/Accordion';
import { LoadingSpinner } from '@/grid-ui/LoadingSpinner';
import { TextLink } from '@/grid-ui/TextLink';
import { Alert } from '@/components/Alert';
import { DateTime } from '@/components/DateTime';
import { ErrorMessage } from '@/components/ErrorMessage';
import { providers, SOURCE_ERRORS } from '@/constants';

import { WorkbookInfoErrors } from './WorkbookInfoErrors';

import styles from './workbookinfo.module.scss';

function ucFirst (str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

type Props = {
  workbook: Workbook,
  documentId: string,
};

type State = {
  history: WorkbookUpdate[] | null | Error,
  workbookDocuments: BaseDocument[] | Error,
  workbookDocumentsLoading: boolean,
}

export class WorkbookInfo extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);
    this.state = {
      history: null,
      workbookDocuments: [],
      workbookDocumentsLoading: true,
    };
  }

  componentDidMount () {
    const { workbook, documentId } = this.props;
    if (!workbook) {
      return;
    }
    history(workbook.id)
      .then(d => this.setState({ history: d }))
      .catch(() => {
        this.setState({ history: new Error('Cannot fetch history.') });
      });
    getWorkbookDocuments(workbook.id)
      .then(d => {
        const workbookDocuments = d.items.filter(doc => doc.id !== documentId);
        this.setState({ workbookDocuments, workbookDocumentsLoading: false });
      })
      .catch(() => {
        this.setState({ workbookDocuments: new Error('Cannot fetch related documents.'), workbookDocumentsLoading: false });
      });
  }

  renderInfo () {
    const { workbook } = this.props;
    if (workbook.update_time === null) {
      return null;
    }
    if (workbook.cloud_connection) {
      const provider = workbook.cloud_connection.cloud_drive_provider;
      const providerName = providers[provider];
      if (provider === 'google') {
        return (
          <p className={styles.info}>
            Last updated from <TextLink href={workbook.cloud_connection.href} target="_blank" rel="noopener noreferrer">{providerName}</TextLink>
            {' '}on <DateTime time={workbook.update_time || ''} />
          </p>
        );
      }
      else if (provider === 'url') {
        return (
          <p className={styles.info}>
            Last updated from <TextLink href={workbook.cloud_connection.href} target="_blank" rel="noopener noreferrer">{workbook.cloud_connection.href}</TextLink>
            {' '}on <DateTime time={workbook.update_time || ''} />
          </p>
        );
      }

      return (
        <p className={styles.info}>
          Last updated from {providerName} on <DateTime time={workbook.update_time || ''} />
        </p>
      );
    }
    if (workbook.type === 'native') {
      return (
        <p className={styles.info}>
          Last updated on <DateTime time={workbook.update_time || ''} />
        </p>
      );
    }
    return (
      <p className={styles.info}>
        Uploaded on <DateTime time={workbook.update_time || ''} />
      </p>
    );
  }

  renderIssues () {
    const { errors } = this.props.workbook;
    const { state, defect } = this.props.workbook.state();
    if (state === 'invalid') {
      return (
        <Alert
          type="error"
          label="This spreadsheet has not updated correctly."
          className={styles.staleError}
          body={
            <p>
              {SOURCE_ERRORS[defect]?.body || null} {}
              Please contact us at <a href="mailto:support@calculatorstudio.co">support@calculatorstudio.co</a> if you need assistance.
            </p>
          }
          />
      );
    }
    if (errors && errors.length) {
      return <WorkbookInfoErrors modelErrors={errors} />;
    }
  }

  renderDocUse () {
    const { workbookDocuments, workbookDocumentsLoading } = this.state;

    if (workbookDocumentsLoading) {
      return <p>Loading...</p>;
    }
    if (workbookDocuments instanceof Error) {
      return <ErrorMessage message={workbookDocuments.message} />;
    }
    if (workbookDocuments.length) {
      return (
        <Accordion
          id="docUse"
          lightTheme
          title={`Other projects that use this spreadsheet (${workbookDocuments.length} ${workbookDocuments.length === 1 ? 'doc' : 'docs'} )`}
          >
          <ul className={styles.accordionContents}>
            {workbookDocuments.map(doc => (
              <li key={doc.id}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a target="_blank" href={`/@${doc.creator.username}/${doc.slug || doc.id}`}>
                  {doc.title}
                </a>
              </li>
            ))}
          </ul>
        </Accordion>
      );
    }
  }

  renderHistory () {
    const history = this.state.history;
    let heading = 'Version history';
    const isArr = Array.isArray(history);
    if (isArr) {
      heading += ` (${history.length} entries)`;
    }
    let message = '';
    if (history == null) {
      message = 'Loading...';
    }
    if (isArr && !history.length) {
      message = 'No history to show for this spreadsheet.';
    }
    return (
      <Accordion
        id="history"
        lightTheme
        title={heading}
        >
        <div className={styles.accordionContents}>
          {message && <p>{message}</p>}
          {history instanceof Error && <ErrorMessage message={history.message} />}
          {isArr && !!history.length && (
            <ul>
              {history.map(entry => (
                <li key={entry.id}>
                  <em>{ucFirst(entry.action)}</em> occurred at
                  <DateTime time={entry.occurred_at} />
                  {entry.details && entry.details.origin != null && ` (origin: ${entry.details.origin})`}
                </li>
              ))}
            </ul>
          )}
        </div>
      </Accordion>
    );
  }

  render () {
    const { workbook } = this.props;
    if (!workbook) {
      return <LoadingSpinner size={6} />;
    }

    return (
      <div className={styles.root}>
        {workbook instanceof Error && <ErrorMessage message={workbook.message} />}
        {this.renderInfo()}
        {this.renderIssues()}
        <div>
          {!!workbook.cloud_connection && this.renderDocUse()}
          {workbook.type !== 'native' && this.renderHistory()}
        </div>
      </div>
    );
  }
}
