import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@grid-is/icon';

import styles from './errormessage.module.scss';

export function ErrorMessage (props) {
  if (!props.message) {
    return null;
  }
  return (
    <div className={styles.root + (props.className ? ' ' + props.className : '')}>
      <Icon name="exclamation-triangle" variant="solid" className={styles.icon} size={24} />
      {props.message}
    </div>
  );
}

ErrorMessage.propTypes = {
  message: PropTypes.node,
  className: PropTypes.string,
};
