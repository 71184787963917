import Textbox from '@borgar/textbox';

import { assert } from '@grid-is/validation';

import { CellAxis } from '@/WorkbookEditor/utils/CellAxis';
import { defaultFonts } from '@/WorkbookEditor/utils/constants';

import { ProxiedCell } from './ProxiedCell';

const hPadding = 4;

export class CellsProxy {
  /**
   * @param {string} sheetName
   * @param {import('@grid-is/apiary').Workbook} workbook
   */
  constructor (sheetName, workbook) {
    const sheet = workbook.getSheet(sheetName);
    assert(sheet, `Sheet ${sheetName} not found in workbook ${workbook.name}`);
    this.sheet = sheet;
    this.workbookName = workbook.name;
    this.workbookStyles = workbook.styles;
    this.workbookId = workbook.id;
    this.workbookType = workbook.type;
  }

  get firstVisibleRow () {
    const sizes =  this.sheet.row_heights;
    return this.getFirstVisibleCellOnAxis(sizes);
  }

  get firstVisibleColumn () {
    const sizes = this.sheet.columns.length > 0 ? this.sheet.columns : this.sheet.col_widths;
    return this.getFirstVisibleCellOnAxis(sizes);
  }

  /**
   * @param {import("../../utils/CellAxis").Sizes} sizes
   */
  getFirstVisibleCellOnAxis (sizes) {
    const colAxis = new CellAxis(sizes, { maxSize: 100 });
    if (!colAxis.items.length) {
      return 0;
    }
    const firstCol = colAxis.items.find(i => i.size);
    if (!firstCol) {
      return colAxis.items.length;
    }
    return firstCol.index;
  }

  /**
   * Returns the ProxiedCell matching the col/row index provided
   *
   * @param {number} colIndex: the col index (zero based)
   * @param {number} rowIndex: the row index (zero based)
   * @returns {ProxiedCell | null}
   * @memberof CellsProxy
   */
  getByIndex (colIndex, rowIndex) {
    if (this.sheet.isStructuredSheet()) {
      const [ width, height ] = this.sheet.getSize();
      if (colIndex >= width || rowIndex >= height) {
        return null;
      }
    }
    const cell = this.sheet?.getDataCellByCoords(rowIndex, colIndex);
    if (cell) {
      return ProxiedCell.from(cell, this.workbookType);
    }
    return null;
  }

  getColumnStyles = x => {
    const adjustedIndex = x + 1;
    if (this.sheet.columns) {
      const columnInfo = this.sheet.columns.find(col => col.begin <= adjustedIndex && adjustedIndex <= col.end);
      if (columnInfo?.si != null) {
        // @ts-expect-error
        return ProxiedCell.from({ s: this.workbookStyles[columnInfo.si] }, this.workbookType);
      }
    }
  };

  getVerticalTextAlignment (height, fontSize) { // move to cellLayer?
    // minus one for the border width
    return height - this.getBaseLineDist(fontSize) - 1;
  }

  getBaseLineDist (fontSize) {
    return Math.round(fontSize * 0.31);
  }

  getHorizontalTextAlignment (width, textWidth, hAlign) { // move to cellLayer?
    let x = 0;
    if (hAlign === 'center') {
      x = width / 2 - textWidth / 2;
    }
    else if (hAlign === 'right') {
      x = width - textWidth - hPadding;
    }
    else {
      x = hPadding;
    }
    return x;
  }

  measureText (text, font) { // move to cell layer ?
    if (!font) {
      const ff = defaultFonts[this.workbookType] || defaultFonts.unknown;
      font = `14px ${ff} sans-serif`;
    }
    return Textbox.measureText(text, font, { trim: false });
  }
}
