import Link from 'next/link';

import { IconButton } from '@/grid-ui/IconButton';
import { useDeps } from '@/bootstrapping/dependencies';

import { TutorialExitPaths } from '../DocumentTutorial';

import styles from './postTutorialOptions.module.scss';

type PostTutorialOptionsProps = {
  variant: 'abort' | 'success',
  onOptionSelected: (exitPath: TutorialExitPaths) => void,
  onAbort?: () => void,
}

export const PostTutorialOptions = ({ variant, onOptionSelected, onAbort = () => {} }: PostTutorialOptionsProps) => {
  const { userEvents } = useDeps();

  function onSelection (option: TutorialExitPaths) {
    userEvents.postTutorialOptionSelected(variant, option);
    onOptionSelected(option);
  }

  return (
    <div className={styles.postTutorialOptions}>
      <div className={styles.backdrop} />
      <div className={styles.content}>
        {variant === 'abort' &&
          <div className={styles.abortButton} data-obid="ob-button">
            <IconButton ariaLabel="close" buttonSize="medium" buttonType="grayScale" iconName="window-close" onClick={onAbort} />
          </div>}
        <h1>
          {variant === 'abort' && 'What do you want to do?'}
          {variant === 'success' && 'What next?'}
        </h1>
        <div className={styles.mainOptions} data-obid="ob-button">
          <Link href="/new">
            <a className={styles.option} onClick={() => onSelection('New Document')}>
              <figure>➕</figure>
              <h2>Start from scratch</h2>
              <p>Create a new project and connect your own data.</p>
            </a>
          </Link>
          {variant === 'success' &&
            <Link href="/me?tutorials=all&accessed_from=Tutorial">
              <a className={styles.option} onClick={() => onSelection('Tutorial Gallery')}>
                <figure>🙋</figure>
                <h2>Do another tutorial</h2>
                <p>Like to keep learning? We have more things for you to try out!</p>
              </a>
            </Link>}
          {variant === 'abort' &&
            <Link href="/me?examples=featured&accessed_from=Tutorial">
              <a className={styles.option} onClick={() => onSelection('Examples')}>
                <figure>👩‍💻</figure>
                <h2>See examples</h2>
                <p>Calculators, forms and other types of docs created in GRID.</p>
              </a>
            </Link>}
          <Link href="/me">
            <a className={styles.option} onClick={() => onSelection('Home Page')}>
              <figure>🏡</figure>
              <h2>Go to Home</h2>
              <p>Your starting point for everything in Calculator Studio.</p>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};
