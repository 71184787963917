import { ChangeEvent, ClipboardEvent, KeyboardEvent, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import csx from 'classnames';
import { useRouter } from 'next/router';

import { tracking } from '@grid-is/tracking';

import { acquireUpdateLock, returnUpdateLock } from '@/api/document';
import { EventStream } from '@/editor/EditorEventStream';
import { Button } from '@/grid-ui/Button';
import { Modal } from '@/grid-ui/Modal';
import { LOCK_ERROR } from '@/constants';

import styles from './documentTitle.module.scss';

type DocumentTitleProps = {
  title: string,
  docId: string,
  documentOwnerId: string,
  isOwner?: boolean,
  editModeEnabled?: boolean,
  canEdit?: boolean,
}

export const DocumentTitle = ({ title, canEdit, docId, documentOwnerId, isOwner, editModeEnabled }: DocumentTitleProps) => {
  const router = useRouter();
  const ref = useRef<HTMLInputElement>(null);
  const refTitle = useRef<HTMLDivElement>(null);

  const [ documentTitle, setDocumentTitle ] = useState(title === 'Untitled' ? '' : title);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isEditing, setIsEditing ] = useState(false);
  const [ error, setError ] = useState<ReactNode | undefined>();
  const [ errorHeader, setErrorHeader ] = useState<string | undefined>();

  const startEditing = useCallback(async () => {
    if (!canEdit) {
      return;
    }
    setIsLoading(true);
    try {
      const lockAcquired = await acquireUpdateLock(docId);
      if (lockAcquired) {
        setIsEditing(true);
        setIsLoading(false);
      }
      else {
        throw new Error('Could not acquire lock');
      }
    }
    catch (error: any) {
      setIsEditing(false);
      setIsLoading(false);

      if (error.error_code === 'document_edit_lock_held_by_another_user') {
        setError(LOCK_ERROR.body);
        setErrorHeader(LOCK_ERROR.title);

        tracking.logEvent('Edit Lock Popup Displayed', {
          document_id: docId,
          document_owner_id:  documentOwnerId,
          is_document_owner:  isOwner,
          action: 'Update document title',
        });
      }
      else {
        setError(error.error_message);
      }
    }
  }, [ canEdit, docId, documentOwnerId, isOwner ]);

  const stopEditing = useCallback(async () => {
    try {
      setIsEditing(false);
      setIsLoading(false);
      if (!editModeEnabled && canEdit) {
        await returnUpdateLock(docId);
      }
    }
    catch (error: any) {
      setError('Could not return update lock: ' + error.error_message);
    }
  }, [ canEdit, docId, editModeEnabled ]);

  useLayoutEffect(() => {
    if (isEditing && ref.current) {
      ref.current.focus();
    }
    else if (!isEditing && ref.current) {
      ref.current.blur();
    }
  }, [ isEditing ]);

  // When the title changes from the share modal we want to trigger update of the title here
  useEffect(() => {
    // So we are not updating the title when user is updating it from this component
    if (document.activeElement !== ref.current) {
      setDocumentTitle(title === 'Untitled' ? '' : title);
    }
  }, [ title, setDocumentTitle ]);

  function onKeyDown (e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      ref.current?.blur();
      EventStream.emit(EventStream.FOCUS_EDITOR);
    }
  }

  function onChange (e: ChangeEvent<HTMLInputElement>) {
    const title = e.target.value;
    setDocumentTitle(title);

    setTimeout(() => {
      EventStream.emit(EventStream.DOCUMENT_TITLE_UPDATE, { title });
    }, 200);
  }

  function onPaste (e: ClipboardEvent<HTMLInputElement>) {
    e.preventDefault();
    // Only allow plain-text to be pasted into the document title.
    const text = e.clipboardData.getData('text/plain').trim();
    document.execCommand('insertText', false, text);
  }

  function onBlur () {
    stopEditing();
    if (ref.current) {
      ref.current.scrollLeft = 0;
    }
  }

  const isDisabled = !canEdit || !!error;

  const onClearError = useCallback(() => {
    setError(undefined);
    setErrorHeader(undefined);

    if (editModeEnabled) {
      const { user, documentId } = router.query;
      if (user && documentId) {
        window.location.href = `/${user}/${documentId}`;
      }
    }
  }, [ editModeEnabled, router ]);

  return (
    <>
      <div className={csx(styles.inputWrapper, isDisabled && styles.isDisabled)}>
        <input
          ref={ref}
          autoComplete="off"
          name="documentTitle"
          placeholder="Untitled"
          className={csx(isLoading && styles.loading)}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={startEditing}
          onBlur={onBlur}
          onPaste={onPaste}
          value={documentTitle}
          disabled={isDisabled}
          data-testid="doc-title"
          data-obid="doc-title"
          />
        <div ref={refTitle} className={styles.hiddenTitle} aria-hidden>
          {documentTitle}
        </div>
      </div>

      <Modal
        id="error-spreadsheet-modal"
        open={!!error}
        closeButton={false}
        header={errorHeader ?? 'Uh oh'}
        size="small"
        footer={
          <div className={styles.modalButton}>
            <Button
              buttonType="primary"
              onClick={onClearError}
              >I understand
            </Button>
          </div>}
        >
        <div data-testid="edit-lock-error">
          {error ?? ''}
        </div>
      </Modal>
    </>
  );
};
