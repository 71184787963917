import csx from 'classnames';

import styles from './documentActions.module.scss';

export const DOCUMENT_ACTIONS_PORTAL_ID = 'document-actions-portal';

type DocumentActionsPortalProps = {
  panelPriority?: 'top' | 'middle' | 'bottom',
  children: React.ReactNode,
};

export const DocumentActionsPortal = ({ panelPriority, children }: DocumentActionsPortalProps) => {
  return (
    <div
      id={DOCUMENT_ACTIONS_PORTAL_ID}
      className={csx(styles.documentActionsPortal, styles[panelPriority ?? 'bottom'])}
      >
      {children}
    </div>
  );
};
