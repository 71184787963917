import { format } from 'numfmt';

import { Cell, colFromOffs, Model } from '@grid-is/apiary';

type EmbedCellValue = null | string | number | boolean;

type EmbedCell = {
  id: string,
  v?: EmbedCellValue,
  f?: string,
  w?: string,
  z?: string,
};

export type EmbedCellData = {
  query: string,
  data: (null | EmbedCell[][]),
};

function prepareCellData (cell: Cell | null, colIndex: number, rowIndex: number, locale: string = 'en-us'): EmbedCell {
  if (!cell) {
    return {
      id: colFromOffs(colIndex) + (rowIndex + 1),
      v: null,
    };
  }
  let w = '';
  let v: EmbedCellValue = null;
  if (cell.v instanceof Error) {
    v = String(cell.v);
    w = v;
  }
  else if (cell.v == null || typeof cell.v === 'number' || typeof cell.v === 'string' || typeof cell.v === 'boolean') {
    v = cell.v ?? null;
    w = format(cell.z || 'General', v, { locale, throws: false, nbsp: false });
  }
  const cellData: EmbedCell = { id: cell.id, v: v, w: w };
  if (cell.f) {
    cellData.f = cell.f;
  }
  if (cell.z) {
    cellData.z = cell.z;
  }
  return cellData;
}

function okayRef (r) {
  // Because of character limits, the longest plain refs that can be constructed in Excel are
  // somewhere just over 300 chars: '[218]31:31!10:10' (+quoted chars). With scoped names we can
  // reach around 500 chars: '[218]31':255. Apiary will take care of validating the reference,
  // so we're just trying to prevent load on the engine parsing things that are obviously not
  // references.
  return typeof r === 'string' && r.trim().length > 0 && r.length < 512;
}

function okayValue (v): v is EmbedCellValue {
  if (
    v == null ||
    (typeof v === 'number' && isFinite(v)) ||
    (typeof v === 'boolean') ||
    (typeof v === 'string' && v.length < 32767) // 32767 = Excel limit
  ) {
    return true;
  }
  return false;
}

export class EmbedAPIManager {
  pendingEmbedResponses: string[] = [];

  // handle external messages
  handleRequest (data: { read?: string[], write?: [string, unknown][] }, model: Model) {
    if (!model || !data) {
      // what are we doing here?
      return;
    }

    // enqueue reads that should be emitted next model recalc
    let wantRecalc = 0;
    if (Array.isArray(data.read)) {
      for (const ref of data.read) {
        if (okayRef(ref)) {
          this.pendingEmbedResponses.push(ref);
          wantRecalc++;
        }
      }
    }

    // if there are write requests, perform them
    if (Array.isArray(data.write)) {
      const okWrites: [string, EmbedCellValue][] = [];
      for (let i = 0; i < data.write.length; i++) {
        if (Array.isArray(data.write[i])) {
          // Warning: Changing this to a destructing assignment breaks tests
          const ref = data.write[i][0];
          const value = data.write[i][1] ?? null;
          if (okayRef(ref) && okayValue(value)) {
            // XXX: support error strings to FormulaError conversion?
            okWrites.push([ ref, value ]);
          }
        }
      }
      if (okWrites.length) {
        model.writeMultiple(okWrites, { skipRecalc: true });
        wantRecalc++;
      }
    }
    // then request recalculation
    if (wantRecalc) {
      model.recalculate();
    }
  }

  getAndClearReads (model: Model): EmbedCellData[] {
    const reads: EmbedCellData[] = [];
    if (this.pendingEmbedResponses.length) {
      const refs = new Set(this.pendingEmbedResponses);
      for (const ref of refs) {
        const r = ref.startsWith('=') ? ref : '=' + ref;
        const cells = model.readCells(r, { cropTo: 'cells-with-non-blank-values' });
        if (!cells || !cells.length || !Array.isArray(cells)) {
          reads.push({ query: ref, data: null });
        }
        else {
          reads.push({
            query: ref,
            data: cells.map((row, ri) => {
              return row.map((cell, ci) => {
                return prepareCellData(cell, cells.left + ci, cells.top + ri);
              });
            }),
          });
        }
      }
      this.pendingEmbedResponses.length = 0;
    }
    return reads;
  }
}
