import { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useMeasure } from 'react-use';
import csx from 'classnames';

import styles from './animateHeight.module.scss';

type AnimateHeightProps = {
  children: ReactNode,
  className?: string,
  maxHeight: number,
}

export const AnimateHeight = forwardRef<HTMLDivElement, AnimateHeightProps>(
  ({ children, className, maxHeight }: AnimateHeightProps, ref) => {
  // Lock transition on mount to prevent animation from zero height.
    const [ animationLock, setAnimationLock ] = useState(true);

    const [ outerRef, { height: outerHeight } ] = useMeasure<HTMLDivElement>();
    const [ innerRef, { height: innerHeight } ] = useMeasure<HTMLDivElement>();
    const scrollRef = useRef<HTMLDivElement>(null);
    const prevInnerHeight = useRef(0);

    useImperativeHandle(ref, () => scrollRef.current || document.createElement('div'));

    useEffect(() => {
      if (scrollRef.current) {
      // Don't animate height from zero.
        if (prevInnerHeight.current === 0 || animationLock) {
          scrollRef.current.classList.add(styles.noAnimation);
        }
        else {
          scrollRef.current.classList.remove(styles.noAnimation);
        }

        // Update height, if exceeding max height then clamp and enable scrolling.
        if (maxHeight - outerHeight < 0) {
          scrollRef.current.style.overflow = 'auto';
          scrollRef.current.style.height = `${maxHeight}px`;
          if (animationLock) {
            scrollRef.current.scrollTop = 0;
          }
        }
        else {
          scrollRef.current.style.overflow = 'hidden';
          scrollRef.current.style.height = `${outerHeight}px`;
        }
      }
      prevInnerHeight.current = innerHeight;
    }, [ innerHeight, outerHeight, maxHeight, animationLock ]);

    // Unlock animation after mount.
    useEffect(() => {
      const timeout = setTimeout(() => {
        setAnimationLock(false);
      }, 200);

      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <div className={styles.animateHeight} ref={scrollRef} id="animate-height">
        <div className={styles.outer} ref={outerRef}>
          <div className={csx(styles.inner, className)} ref={innerRef}>
            {children}
          </div>
        </div>
      </div>
    );
  },
);
