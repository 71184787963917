import { useContext } from 'react';
import csx from 'classnames';

import { ThemesContext } from '@/grid/ThemesContext';

import styles from './embedWatermark.module.scss';

type EmbedWatermarkProps = {
  docCreatorId: string,
  isNotionEmbed?: boolean,
}

function getColorTheme (backgroundColor, color): 'dark' | 'light' | 'none' {
  if (backgroundColor) {
    return color === '#fff' ? 'dark' : 'light';
  }
  else {
    return 'none';
  }
}

function getWatermarkImgUrl (colorTheme) {
  switch (colorTheme) {
    case 'none': return '/img/grid-watermark.png';
    case 'dark': return '/img/grid-watermark-on-dark-bg.png';
    case 'light': return '/img/grid-watermark-on-light-bg.png';
  }
}

export const EmbedWatermark = ({ docCreatorId, isNotionEmbed }: EmbedWatermarkProps) => {
  const { theme } = useContext(ThemesContext);
  const colorTheme = getColorTheme(theme.backgroundColor, theme.color);
  const watermarkImgUrl = getWatermarkImgUrl(colorTheme);
  return (
    <section className={csx(styles.embedWatermark, styles[colorTheme], isNotionEmbed && styles.inNotion)} data-testid="embed-watermark">
      <a href={`/embed-watermark?docCreatorId=${encodeURIComponent(docCreatorId)}`} target="_blank" rel="noreferrer">
        <img src={watermarkImgUrl} width="64" />
      </a>
    </section>
  );
};
