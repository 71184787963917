import { assign, createMachine } from 'xstate';

type AccordionContext = {
  hasAnimated?: boolean,
  mountChildrenWhileCollapsed?: boolean,
  overflow: 'hidden' | 'visible',
}

type Events = { type: 'TOGGLE' } | { type: 'START_EXPANDED' };

const accordionMachine =
/** @xstate-layout N4IgpgJg5mDOIC5QEMDGqD2AnCBLDAdgMQDKAKgIIBKZA+gKIAaAChQHIAi9HA2gAwBdRKAAOGWLgAu+AsJAAPRABYATABoQAT0QBWHQE4AdPpMmlfFQGZLSgBy2Avg41pMOGYcwAbL8hGxIIjIAeQBxUIAZen4hJBAxCWlCOUUEFR0VQwA2fR1LPn0lawBGAHY7Sw1tBEsVYsMlU1NLfVKVJScXdGw8Qk8MHz8JAiggsMjowTkEqRkUxEL64try4p1SvhzLLKrEWvrGpv0Wto7nEFcej29ff1wRonlYSWRJMENkADM3rAAKHT4fAAlERLu4+jchvcoDFpuJZsk4ql9CojMVzDosllynxioDbLsELZ6gDARY1odiujOhduuCCIYwPIRMgCHgHiFwlFYXEZklZEjdPZDO1THx8u0lKVioSVIDDOUmsV9FlbOL1jSwb0GUyWWzoY9nq93l8fv9ASCtR5daz2TCprz4fz5ggdMLRSZxRYlFKZVplKoRaTAaqVFkfT7NXTtYzmbbApyJjzRE65oKECi0RisTi8XwCf7XT7jMUsarVaUslSsk5zgQMBA4HIrYiU4k06BUgBaHaFrs6YxHIcmSxRtwxyH+SBw9uthTKdSFlS2TJu6yWNo2ZebUfnFsMyfDKAzhECzuIPG2JQNFF4458TG2SqFxpZBVlyy2SsGVo1vfR60431EYT2ddMNzfB8lGxaVVVLIpZX0WwbxMYkdDKSw8TOLpx0AvVGwgUCO3nBBN2yXE8mXVULEKQkMnqfRSy2VRrDlXcnCAA */
createMachine<AccordionContext, Events>({
  predictableActionArguments: true,
  id: 'accordion',
  initial: 'collapsed',
  schema: {
    context: {} as AccordionContext,
    events: {} as Events,
  },
  context: {
    hasAnimated: false,
    overflow: 'visible',
    mountChildrenWhileCollapsed: false,
  },
  states: {
    collapsed: {
      entry: 'setCollapsed',
      on: {
        TOGGLE: 'expanding',
      },
    },
    collapsing: {
      entry: 'hideOverflow',
      after: {
        500: 'collapsed',
      },
      on: {
        TOGGLE: 'expanding',
      },
    },
    expanding: {
      entry: 'hideOverflow',
      after: {
        500: 'expanded',
      },
      on: {
        TOGGLE: 'collapsing',
      },
    },
    expanded: {
      entry: 'setExpanded',
      on: {
        TOGGLE: 'collapsing',
      },
    },
  },
  on: {
    START_EXPANDED: 'expanded',
  },
}, {
  actions: {
    hideOverflow: assign(context => {
      return { ...context, overflow: 'hidden', hasAnimated: true };
    }),
    setCollapsed: assign(context => {
      return { ...context, overflow: context.mountChildrenWhileCollapsed ? 'hidden' : 'visible' };
    }),
    setExpanded: assign(context => {
      return { ...context, overflow: 'visible' };
    }),
  },
});

export { accordionMachine };
