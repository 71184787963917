import { Icon } from '@grid-is/icon';

import { Tooltip } from '@/grid-ui/Tooltip';

import styles from './PreviewChromeless.module.scss';

export const PreviewChromeless = () => {
  return (
    <Tooltip
      label="Open a preview without Calculator Studio UI in new tab."
      gap={4}
      >
      <a
        href="./?chrome=off"
        target="_blank"
        className={styles.button}
        >
        <Icon
          name="pop-out"
          size={15}
          className={styles.icon}
          />
      </a>
    </Tooltip>
  );
};
