import { paths } from './generatedTypes';
import { getJSON, postJSON } from './request';

type OAUthRequest = paths['/oauth']['post']['requestBody']['content']['application/json'];
export type OAUthResponse = paths['/oauth']['post']['responses']['200']['content']['application/json'];
type GetAccessTokenResponse = paths['/cloud_drive/{cloud_drive_id}/access_token']['get']['responses']['200']['content']['application/json'];
type GetOauthSecurityParamsResponse = paths['/oauth/request-security-params']['get']['responses']['201']['content']['application/json'];

export function getOAuthAccessToken (cloud_drive_id: string) {
  return getJSON<GetAccessTokenResponse>('/cloud_drive/' + cloud_drive_id + '/access_token');
}

export async function exchangeCodeForToken (code: string, redirect_uri: string) {
  return postJSON<OAUthResponse, OAUthRequest>('/oauth', {
    provider: 'fusionauth',
    code: code,
    redirect_uri: redirect_uri,
  });
}

export function getExtraOauthParameters (provider: string) {
  if (provider === 'airtable') {
    return getJSON<GetOauthSecurityParamsResponse>('/oauth/request-security-params');
  }
  else {
    return Promise.resolve({});
  }
}
