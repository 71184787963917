import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useFullScreen } from '@grid-is/custom-hooks';

import { useOnKeyDown } from '@/editor/utils/events';
import GridDocument from '@/grid/GridDocument';
import { Resizeable } from '@/components/Resizeable';
import { scrollingDocument } from '@/utils/offset';

import styles from './fullscreen.module.scss';

const BKSP = 8; //  Backspace (win)
const DEL = 46; // Delete (mac)
const ENTER = 13; // Enter (PP Windows)
const HYPEN = 189; // Hyphen (-)
const LEFT = 37; // Left Arrow
const N = 78; // N
const P = 80; // P
const PERIOD = 190; // Period (.) (+ ⌘?)
const PGDN = 34; // Page Down
const PGUP = 33; // Page Up
const RIGHT = 39; // Right Arrow
const SPACE = 32; // Space

let fs;

function deepCopyNode (node) {
  return Object.assign({ children: [] }, JSON.parse(JSON.stringify(node)));
}

function shallowCopyNode (node) {
  return Object.assign({}, JSON.parse(JSON.stringify(node)), { children: [] });
}

function splitPages (doc) {
  const pages = [];
  let currPage = [];
  let currRow;
  let currCol;
  pages.push(currPage);

  doc.forEach(child => {
    if (child.type === 'row') {
      // is row multicolumn or has layout?
      const isLayoutRow = !!(child.children.length > 1 || child.data.layout);

      currRow = shallowCopyNode(child);
      currPage.push(currRow);

      child.children.forEach(col => {
        currCol = shallowCopyNode(col);
        currRow.children.push(currCol);

        col.children.forEach(block => {
          if (block.type === 'slidebreak' && !isLayoutRow) {
            currPage = [];
            pages.push(currPage);
            currRow = shallowCopyNode(child);
            currPage.push(currRow);
            currCol = shallowCopyNode(col);
            currRow.children.push(currCol);
          }
          else {
            currCol.children.push(deepCopyNode(block));
            currRow.hasBlocks = true;
          }
        });
      });
    }

    else if (child.type === 'slidebreak') {
      currPage = [];
      pages.push(currPage);
      currPage.push(child);
    }
    else {
      currPage.push(child);
    }
  });

  // clean out all empty rows
  return pages.map(page => {
    return page.filter(row => {
      delete row.data.size;
      return (row.type !== 'row' || row.hasBlocks);
    });
  });
}

function scaleFactor (root) {
  const vw = root.current.clientWidth * 0.85;
  const wide = 903;
  const scale = vw / wide;
  return scale;
}

/**
 * @param {{
 *   document: any;
 *   onClose: (duration: number) => void;
 *   globalProps: Record<string, any>;
 *   model: Model;
 *   theme: import('@/grid/Theme').default;
 *   chartTheme: import('@/grid/ChartTheme').default;
 * }} props
 */
export function Fullscreen (props) {
  /** @type {React.MutableRefObject<HTMLDivElement | null>} */
  const root = useRef(null);
  /** @type {React.MutableRefObject<Resizeable | null>} */
  const doc = useRef(null);
  const { theme, chartTheme } = props;
  const [ page, setPage ] = useState(0);
  const [ pages, setPages ] = useState(splitPages(props.document));
  const [ prevDocument, setPrevDocument ] = useState(props.document);

  useEffect(() => {
    // On Safari and Firefox the active element after entering
    // fullscreen mode is the fullscreen button. We need the active element to be
    // the body so that key events will not be ignored because of the target being
    // a input (button)
    const focusElm = document.activeElement;
    if (focusElm && 'blur' in focusElm && typeof focusElm.blur === 'function') {
      focusElm.blur();
    }
  }, []);

  const setZoom = () => {
    const docElm = doc.current && doc.current.element;
    const scrollElm = scrollingDocument();

    docElm.style.removeProperty('margin');

    const z = scaleFactor(root);
    const docHeight = docElm.clientHeight * z;
    const scrollHeight = scrollElm.clientHeight;

    const hDiff = scrollHeight - docHeight;
    docElm.style.transform = `scale(${z})`;

    if (hDiff > 0) {
      docElm.style.marginTop = (hDiff / 2) + 'px';
    }
  };

  fs = useFullScreen({
    element: root,
    onOpen: setZoom,
    onClose: props.onClose,
  });

  if (props.document !== prevDocument) {
    const newPages = splitPages(props.document);
    setPages(newPages);
    setPrevDocument(props.document);
  }

  const getCurrPage = () => {
    return pages[page];
  };

  const onKey = useCallback(e => {
    // if the target element for the key event is an input, we have to disable the key
    const tag = e.target.getAttribute('role') || e.target.nodeName.toLowerCase();
    if (tag === 'input') {
      // don't allow keys when an input is focused
      return;
    }
    const inputs = [ 'slider', 'checkbox', 'radio', 'input', 'select', 'button' ];
    if (inputs.includes(tag) && [ ENTER, LEFT, RIGHT, SPACE ].includes(e.keyCode)) {
      // disable keys that clash with input elements
      return;
    }
    // many slides software use down/up arrows for next/prev but we want scroll to work
    // Next slide:
    if ([ N, ENTER, PGDN, RIGHT, SPACE ].includes(e.keyCode)) {
      // Prevents error sound when navigating slides on safari
      e.preventDefault();
      setPage(Math.min(page + 1, pages.length - 1));
    }
    // Prev slide:
    else if ([ P, PGUP, LEFT, DEL, BKSP ].includes(e.keyCode)) {
      // Prevents error sound when navigating slides on safari
      e.preventDefault();
      setPage(Math.max(page - 1, 0));
    }
    // End:
    else if (e.keyCode === HYPEN || e.keyCode === PERIOD) {
      // Prevents error sound when exiting fullscreen mode on safari
      e.preventDefault();
      fs.close();
    }
  }, [ pages, page ]);
  useOnKeyDown(onKey);

  useEffect(() => {
    if (root.current) {
      root.current.scrollTop = 0;
    }
  }, [ page ]);

  return (
    <div tabIndex={0} ref={root} className={styles.root} style={theme.styles} data-testid="fullscreen">
      <Resizeable onResize={setZoom} ref={doc} className={styles.doc}>
        <GridDocument
          theme={theme}
          chartTheme={chartTheme}
          document={getCurrPage()}
          globalProps={props.globalProps}
          model={props.model}
          />
      </Resizeable>
    </div>
  );
}

Fullscreen.open = () => {
  fs && fs.open();
};

Fullscreen.propTypes = {
  onClose: PropTypes.func.isRequired,
  document: PropTypes.array.isRequired,
  globalProps: PropTypes.object,
  model: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  chartTheme: PropTypes.object.isRequired,
};
