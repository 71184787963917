import { MouseEvent, useEffect } from 'react';

type Callback = (params: unknown) => void;
const useWindowEvent = (event: string, callback: Callback) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [ event, callback ]);
};

export const useOnMouseClick = (callback: Callback) => {
  return useWindowEvent('click', callback);
};

export const useOnKeyDown = (callback: Callback) => {
  return useWindowEvent('keydown', callback);
};

export function isClickingOnColumnHeader (e?: MouseEvent): boolean {
  if (!e) {
    return false;
  }
  const { target } = e.nativeEvent;
  if (!target || !(target as Element).closest) {
    return false;
  }
  return (target as Element).closest('[data-layout-header]') !== null;
}
