import { useCallback, useEffect } from 'react';

import { splitAnchor } from '@/utils/comment';

import { EventStream } from '../../editor/EditorEventStream';

export const ElementFocusManager = () => {
  const onCommentActivation = useCallback((anchorId?: string) => {
    const { elementId } = splitAnchor(anchorId);
    if (elementId) {
      document.getElementById(elementId)?.classList.add('isFocusTarget');
    }
    else {
      document.querySelectorAll('.isFocusTarget').forEach(el => el.classList.remove('isFocusTarget'));
    }
  }, []);

  const onCommentFocus = useCallback((anchorId?: string) => {
    if (anchorId) {
      document.getElementById(anchorId)?.classList.add('isFocusTarget');
    }
    else {
      document.querySelectorAll('.isFocusTarget').forEach(el => el.classList.remove('isFocusTarget'));
    }
  }, []);

  useEffect(() => {
    EventStream.on(EventStream.ON_COMMENT_ACTIVATION, onCommentActivation);
    EventStream.on(EventStream.ON_COMMENT_FOCUS, onCommentFocus);
    return () => {
      EventStream.off(EventStream.ON_COMMENT_ACTIVATION, onCommentActivation);
      EventStream.off(EventStream.ON_COMMENT_FOCUS, onCommentFocus);
    };
  });

  return null;
};
