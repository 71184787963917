import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { parseDateTime } from '@grid-is/apiary';

import { statistics as getDocumentStatistic } from '@/api/document';
import { Doc } from '@/grid/Doc';
import GridDocument from '@/grid/GridDocument';
import { Button } from '@/grid-ui/Button';
import { Modal } from '@/grid-ui/Modal';
import { TextLink } from '@/grid-ui/TextLink';
import { useDeps } from '@/bootstrapping/dependencies';
import { isMobile } from '@/utils';

import styles from './documentStatistics.module.scss';

const gridDocument = {
  format: 'v2',
  body: JSON.stringify({ name: 'body',
    children: [
      { name: 'row',
        attr: { size: 'full', layout: 'true' },
        children: [
          { name: 'col',
            attr: { size: '1/1' },
            children: [
              { name: 'grid:block',
                attr: {
                  expr: '=ViewsByDate!B:C',
                  labels: '=ViewsByDate!A:A',
                  legend: '={"Regular views", "Embed views"}',
                  stacked: true,
                  axisDim: {
                    format: 'mmm d',
                    title: 'Last 30 days',
                  },
                  axisValue: {
                    title: 'Number of views',
                  },
                  type: 'column',
                  visible: 'true',
                  sortable: 'false',
                  searchable: 'true',
                } },
            ] },
        ] },
      { name: 'row',
        attr: { size: 'full', layout: 'true' },
        children: [
          { name: 'col',
            attr: { size: '1/2' },
            children: [
              { name: 'grid:block',
                attr: {
                  expr: '=ViewsByUser!A:B',
                  legend: '={"Viewers", "Number of views"}',
                  title: 'Views',
                  type: 'table',
                  visible: 'true',
                  sortable: 'true',
                  searchable: 'false',
                  sortBy: '2',
                  sortOrder: 'descending',
                } },
            ] },
          { name: 'col',
            attr: { size: '1/2' },
            children: [
              { name: 'grid:block',
                attr: {
                  expr: '=DocumentViews!A:B',
                  legend: '={"Who", "When"}',
                  title: 'View log',
                  type: 'table',
                  visible: 'true',
                  sortable: 'false',
                  searchable: 'false',
                } },
            ] },
        ] },
    ] },
  ),
};

/**
 * @returns {import('@grid-is/apiary/lib/csf').WorkbookBody}
 */
const generateSpreadsheet = data => {
  const { views, viewsByDate, viewsByUser } = data;

  // Create spreadsheet
  const spreadsheet = {
    filename: 'document-statistics.xlsx',
    id: 'document-statistics',
    state: 'ready',
    creation_time: new Date().toISOString(),
    update_time: new Date().toISOString(),
    names: [],
    metadata: {},
    schema_version: '2.0',
    /** @type {SheetCSF[]} */
    sheets: [],
  };

  // Create DocumentViews sheet
  /** @type {SheetCSF} */
  const viewsSheet = {
    cells: { },
    hidden: false,
    row_heights: [],
    merged_cells: [],
    name: 'DocumentViews',
  };
  views.forEach((row, index) => {
    const location = row.city && row.country ? `Someone in ${row.city}, ${row.country}` : 'Someone in unknown location';
    viewsSheet.cells[`A${index + 1}`] = { v: row.full_name ? row.full_name : location };
    viewsSheet.cells[`B${index + 1}`] = { v: parseDateTime(row.when.replace(/\..+$/, '').replace(/T/, ' ')), z: 'd. mmm yyyy hh:mm:ss UTC' };
  });
  spreadsheet.sheets.push(viewsSheet);

  // Create ViewsByDate sheet
  /** @type {SheetCSF} */
  const viewsByDateSheet = {
    cells: { },
    hidden: false,
    row_heights: [],
    merged_cells: [],
    name: 'ViewsByDate',
  };
  viewsByDate.forEach((row, index) => {
    viewsByDateSheet.cells[`A${index + 1}`] = { v: parseDateTime(row.date) };
    viewsByDateSheet.cells[`B${index + 1}`] = { v: row.regular };
    viewsByDateSheet.cells[`C${index + 1}`] = { v: row.embed };
  });
  spreadsheet.sheets.push(viewsByDateSheet);

  // Create ViewsByUser sheet
  /** @type {SheetCSF} */
  const viewsByUserSheet = {
    cells: { },
    hidden: false,
    row_heights: [],
    merged_cells: [],
    name: 'ViewsByUser',
  };
  viewsByUser.forEach((row, index) => {
    const location = row.city && row.country ? `Views from ${row.city}, ${row.country}` : 'Views from unknown location';
    viewsByUserSheet.cells[`A${index + 1}`] = { v: row.full_name ? row.full_name : location };
    viewsByUserSheet.cells[`B${index + 1}`] = { v: row.count };
  });
  spreadsheet.sheets.push(viewsByUserSheet);

  return spreadsheet;
};

const sumViews = dates => {
  let viewCount = 0;
  dates.forEach(date => {
    viewCount += date.count;
  });
  return viewCount;
};

const STATS_QUERY = 'stats=1';

/**
 * @typedef {{
 *   documentId: string;
 *   documentTitle: string;
 *   shouldOpen?: boolean;
 *   onClose: () => void;
 *   canViewDocumentStatistics?: boolean;
 * }} Props
 */

/** @type {import('react').FunctionComponent<Props>} */
export function DocumentStatistics ({ documentId, documentTitle, shouldOpen, onClose, canViewDocumentStatistics }) {
  const [ doc, setDoc ] = useState(/** @type {Doc | null} */ (null));
  const [ totalViews, setTotalViews ] = useState(/** @type {number | null} */ (null));
  const router = useRouter();
  const showOnMobile = isMobile();
  const { userEvents } = useDeps();

  useEffect(() => {
    if (shouldOpen && documentId) {
      if (canViewDocumentStatistics) {
        getDocumentStatistic(documentId).then(data => {
          const spreadsheet = generateSpreadsheet(data);
          const doc = new Doc(gridDocument);
          // TBD: this might require `await Model.preconditions` for the case of documents that have no workbooks.
          // We will have already awaited that when the actual document's workbooks were loaded. But only if it has any.
          doc.model.addWorkbook(spreadsheet);
          setDoc(doc);
          setTotalViews(sumViews(data.viewsByDate));
        });
      }
      if (router.asPath.includes(STATS_QUERY)) {
        // make sure to clean up the query parameter
        router.replace(`${router.asPath.replace(STATS_QUERY, '')}`);
      }
    }
    // dont add router to deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ shouldOpen, documentId, canViewDocumentStatistics ]);

  const PromoButton = () => (
    <Link href="/settings/billing" passHref>
      <Button
        onClick={() => userEvents.upgradeInterestIndicated('document statistics', 'product')}
        buttonType="primary"
        buttonSize="large"
        >Upgrade now
      </Button>
    </Link>
  );

  const Promo = () => (
    <div className={styles.promo}>
      <div className={styles.promoBanner}>
        <span>
          <h1>Get project statistics with one of our subscriptions 🕵️‍♀️</h1>
          <p>Statistics give insight into project views on a day-by-day basis. <TextLink href="https://grid.is/@grid/document-statistics-lV_nBnk1RXOgH4A34ruR0g" target="_blank" rel="noreferrer">Read more.</TextLink></p>
        </span>
        {!showOnMobile && <PromoButton />}
      </div>
      {showOnMobile ? <img className={styles.promoImage} src="/img/statistics-promo-mobile.png" /> : <img className={styles.promoImage} src="/img/statistics-promo.png" />}
    </div>
  );

  const Statistics = () => {
    if (doc) {
      const hasViews = !!doc.model.readValue('=A1');
      return (
        <div className={styles.documentStatistics}>
          {hasViews &&
            <>
              <p className={styles.totalViews}><strong>{documentTitle}</strong> was viewed <strong>{totalViews} times</strong> in the last <strong>30 days</strong>.</p>
              <GridDocument
                document={doc.toSlateDom()}
                globalProps={doc.getGlobalProps()}
                model={doc.model}
                />
              <div className={styles.geoBanner}>
                This product includes GeoLite2 data created by MaxMind, available from <TextLink href="https://www.maxmind.com" target="_blank" rel="noreferrer" linkSize="x-small">https://www.maxmind.com</TextLink>.
              </div>
            </>
            }
          {!hasViews && <div className={styles.noData}>Your document has not been viewed in the last 30 days.</div>}
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      header="Statistics"
      size="x-large"
      open={shouldOpen}
      onClose={onClose}
      footer={(!canViewDocumentStatistics && showOnMobile) && <div className={styles.promoMobileFooter}><PromoButton /></div>}
      >
      {canViewDocumentStatistics && <Statistics />}
      {!canViewDocumentStatistics && <Promo />}
    </Modal>
  );
}

DocumentStatistics.propTypes = {
  documentId: PropTypes.string.isRequired,
  documentTitle: PropTypes.string.isRequired,
  shouldOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  canViewDocumentStatistics: PropTypes.bool,
};
