import { isBrowser, prefersReducedMotion } from '@grid-is/browser-utils';
import { easing } from '@grid-is/styles';

import { scrollingEditor } from '@/utils/offset';

export function resizeEditor (currSize: number, newSize: number, shouldScroll = true, _shouldTransition = true) {
  if (!isBrowser()) {
    return;
  }
  const editor = scrollingEditor();
  if (!editor) {
    return;
  }

  const shouldTransition = !prefersReducedMotion() && (_shouldTransition || currSize === 0 || newSize === 0);
  const transition = shouldTransition && `height 500ms ${easing.easeOutExpo}, padding 500ms ${easing.easeOutExpo}`;
  editor.style.transition = transition || '';
  editor.style.height = `calc(100% - ${newSize}px)`;
  editor.style.padding = '';

  if (shouldScroll) {
    const scrollTop = editor.scrollTop;
    const scrollBy = currSize - newSize;
    if (currSize !== 0 && newSize !== 0) {
      editor.scrollTop = scrollTop - scrollBy;
    }
  }
}

export function hideEditor () {
  if (!isBrowser()) {
    return;
  }
  const editor = scrollingEditor();
  if (!editor) {
    return;
  }
  editor.style.height = '0px';
  editor.style.padding = '0px';
}
