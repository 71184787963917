/**
 * Module for managing margin on the right side of the document. We use a fixed width to handle both the comments and the element popover. if there is space
 * then the document is pushed to the side and the space is added to the right side of the document. If there is not enough space then the document is
 * then the request to add space is ignored.
 */

import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import csx from 'classnames';

import { getViewport } from '@grid-is/browser-utils';
import { chain } from '@grid-is/iterators';

import { EventStream } from '@/editor/EditorEventStream';
import { DOCUMENT_SIZE, EDITOR_PANEL_WIDTH } from '@/constants';

import { MARGIN_MANAGER_ID } from './dom';
import { MarginManagerContext } from './MarginManagerContext';

import styles from './MarginManager.module.scss';

type MarginManagerProps = {
  className?: string,
  width?: string,
  isEditor?: boolean,
  isEditorPanelOpen?: boolean,
  children: React.ReactNode,
};

function calculateMarginToAllocate (marginManager: Element | null, neededSpace: number) {
  // read how much space the document has
  const docSpace = marginManager?.clientWidth || 0;
  const marginSpace = (docSpace - DOCUMENT_SIZE) / 2;

  const spacing = Math.max(
    0,
    Math.min(
      Math.ceil(neededSpace - marginSpace) * 2,
      neededSpace,
    ),
  );
  return spacing;
}

const ANIMATION_DURATION = 500;

// This is a tiny bit of an extra delay before running callbacks, to reduce chances of them being called before the animation completes
const ANIMATION_BUFFER = 10;

const useController = (marginManagerElementRef: RefObject<HTMLDivElement>) => {
  const [ derivedPadding, setDerivedPadding ] = useState(0);
  const requestedByRef = useRef(new Map<string, number>());

  const requestMargin = useCallback((id: string, requestedMargin: number, onDone = () => {}) => {
    const requestedBy = requestedByRef.current;
    const maxRequestedBefore = chain(requestedBy.values()).max() || 0;
    requestedBy.set(id, requestedMargin);
    const maxRequestedAfter = chain(requestedBy.values()).max() || 0;
    const newMargin = calculateMarginToAllocate(marginManagerElementRef.current, maxRequestedAfter);
    setDerivedPadding(newMargin);
    if (maxRequestedAfter === maxRequestedBefore) {
      onDone();
    }
    else {
      setTimeout(() => {
        onDone();
      }, ANIMATION_DURATION + ANIMATION_BUFFER);
    }
  }, [ marginManagerElementRef ]);

  const onResize = useCallback(() => {
    if (requestedByRef.current.size === 0) {
      // clean up any space if there is no request for spacing
      if (derivedPadding > 0) {
        setDerivedPadding(0);
      }
      return;
    }
    const maxRequestedMargin = chain(requestedByRef.current.values()).max() || 0;
    const updatedMargin = calculateMarginToAllocate(marginManagerElementRef.current, maxRequestedMargin);
    if (derivedPadding !== updatedMargin) {
      setDerivedPadding(updatedMargin);
    }
  }, [ derivedPadding, marginManagerElementRef ]);

  useEffect(() => {
    onResize();
  }, [ onResize ]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [ onResize ]);

  return { requestMargin, derivedPadding };
};

export const MarginManager = ({ children, className, width, isEditor, isEditorPanelOpen }: MarginManagerProps) => {
  const marginManagerRef = useRef<HTMLDivElement>(null);
  const documentWrapperRef = useRef<HTMLDivElement>(null);
  const { requestMargin } = useController(marginManagerRef);

  let widthClass = styles.widthNormal;
  if (width === 'full') {
    widthClass = styles.widthFull;
  }
  else if (width === 'wide') {
    widthClass = styles.widthWide;
  }

  const rightSpacerStyle = useMemo(() => {
    if (isEditorPanelOpen) {
      const rect = documentWrapperRef.current?.getBoundingClientRect()!;
      const viewportWidth = getViewport().width;
      // 16px is the padding of the document.
      // 32px is the minimum distance between the document canvas and the editor panel.
      const diff = viewportWidth - rect.right - EDITOR_PANEL_WIDTH - 16 - 32;
      if (diff > 0) {
        return {};
      }
      else {
        return {
          flexBasis: EDITOR_PANEL_WIDTH + 'px',
          flexGrow: 0,
        };
      }
    }
    else {
      return {};
    }
  }, [ isEditorPanelOpen ]);

  const emitResize = () => {
    const rect = documentWrapperRef.current?.getBoundingClientRect()!;
    EventStream.emit(EventStream.EDITOR_WIDTH_DID_RESIZE, { rect });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(emitResize);
    resizeObserver.observe(documentWrapperRef.current!);
    window.addEventListener('resize', emitResize);
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', emitResize);
    };
  }, [ ]);

  useEffect(() => {
    emitResize();
  }, [ rightSpacerStyle ]);

  return (
    <MarginManagerContext.Provider value={{ requestMargin }}>
      <div
        className={csx(styles.marginManager, isEditor && styles.isEditor)}
        id={MARGIN_MANAGER_ID}
        ref={marginManagerRef}
        >
        <div className={styles.leftSpacer} />
        <div ref={documentWrapperRef} className={csx(className, widthClass)}>
          {children}
        </div>
        <div className={styles.rightSpacer} style={rightSpacerStyle} />
      </div>
    </MarginManagerContext.Provider>
  );
};

